import { Router } from '@af-modules/router';
import { Component, createView, defaultViewFactory } from '../prototypes/Component.js';
import { NavigationManager } from '../managers/Navigation.js';

export const AppBarComponent = {
    name: 'AppBarComponent',
    host: null,
    title: null,
    visible: true,

    init({ host, title, visibile = true }) {
        super.init();

        this.host = host;
        this.title = title;
        this.visibile = visibile;

        this.scope.update();
    },

    __proto__: Component,
};

createView((component) => {
    return {
        strings: {
            get title() {
                return component.title ?? 'no_title_set';
            }
        },

        get canGoBack() {
            return !!component.host.parent;
        },

        get hasNaviagtion() {
            return !this.canGoBack;
        },

        get isVisible() {
            return component.visibile;
        },

        onNavigateBack() {
            const currentPath = location.hash.split('/');
            const currentPathLength = currentPath.length;
            const parentPathLength = component.host.parent.path.split('/').length;
            const targetPath = currentPath
                .slice(1, (currentPathLength - parentPathLength) * -1)
                .join('/');

            Router.switchTo(`/${targetPath}`);
        },

        onToggleDrawer() {
            NavigationManager.toggleDrawer();
        },

        __proto__: defaultViewFactory(component),
    };
}, AppBarComponent);

export default AppBarComponent;

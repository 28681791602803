import { createView } from '../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../prototypes/GenericEditPage.js';
import MedicalPracticeDetailPage from './MedicalPracticeDetail.js';
import { MedicalPractice } from 'zpp-mpr-lib/models.js';
import { AddressLocationFeature } from '../lib/AddressLocationFeature.js';

export const EditMedicalPracticeDetailPage = {
    name: 'EditMedicalPracticeDetailPage',
    path: '/medicalpractice/{entityId}/edit',
    template: 'page-edit-medical-practice-detail',

    fields: [
        'name',
        'street',
        'house_number',
        'zip',
        'city',
        'country',
        'phone_numbers',
        'director_name',
        'director_phone_numbers',
        'nursing_director_name',
        'nursing_director_phone_numbers'
    ],

    entityType: MedicalPractice,
    addressLocationFeature: null,

    get parent() {
        if (this.isNew) {
            return MedicalPracticeDetailPage;
        }

        return MedicalPracticeDetailPage;
    },

    init(template) {
        super.init(template, 'medical_practice_detail_page');

        this.addressLocationFeature = AddressLocationFeature(undefined, this);
    },

    saveChanges() {
        return this.addressLocationFeature.attachLatLong()
            .then(() => super.saveChanges());
    },

    __proto__: GenericEditPage,
};

createView(genericEditPageViewFactory, EditMedicalPracticeDetailPage);

export default EditMedicalPracticeDetailPage;

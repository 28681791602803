import {createView} from '../prototypes/Page.js';
import GenericDetail, {genericPageViewFactory} from '../prototypes/GenericDetail.js';
import MedicalPracticeManager from '../managers/MedicalPractice.js';
import MedicalPracticePage from './MedicalPractice.js';

export const MedicalPracticeDetailPage = {
    name: 'MedicalPracticeDetailPage',
    path: '/medicalpractice/{entityId}',
    template: 'page-medical-practice-detail',
    parent: MedicalPracticePage,

    fields: [
        'name',
        'street',
        'house_number',
        'zip',
        'city',
        'country',
        'phone_numbers',
        'director_name',
        'director_phone_numbers',
        'nursing_director_name',
        'nursing_director_phone_numbers',
        'lat',
        'longitude',
    ],

    manager: MedicalPracticeManager,

    __proto__: GenericDetail,
};

createView(genericPageViewFactory, MedicalPracticeDetailPage);

export default MedicalPracticeDetailPage;

import { Page, defaultViewFactory, createView } from '../prototypes/Page.js';

export const PermissionDeniedPage = {
    name: 'PermissionDeniedPage',
    template: 'page-permission-denied',

    __proto__: Page,
};

createView((page) => {
    return {
        __proto__: defaultViewFactory(page),
    };
}, PermissionDeniedPage);

import * as uuid from 'uuid';
import { User, UserGroup, Permission, EntityMap } from 'zpp-mpr-lib/models.js';
import {PermissionType, UserTrait} from 'zpp-mpr-lib/traits/models.js';
import { Trait } from 'zpp-mpr-lib/traits.js';
import Iterator from '@prograp/iterator';
import { Router } from '@af-modules/router';
import EventTarget from 'application-frame/core/EventTarget.js';
import ConfigManager from './Config.js';
import { PermissionDeniedPage } from '../pages/PermissionDenied.js';
import { AuthenticatingPage } from '../pages/Authenticating.js';
import { StorageManager } from './Storage.js';
import {Password} from '../lib/Password.js';


const Private = {
    storeGroup: Symbol('AccessControlManager.Private.storeGroup'),
};

// placeholder user group for the canVisit cache.
const NO_USER_GROUP = {};
const PERMISSION_ID_NAMESPACE = 'ac279006-2091-4e7d-b66e-fe5216e1ee8e';

const DeletePermissions = Iterator.new(EntityMap)
    .map(([entity_name]) =>  Permission.fromObject({
        id: uuid.v5(`${entity_name}_delete`, PERMISSION_ID_NAMESPACE),
        entity_name,
        permission_type: PermissionType.Delete,
    })).intoArray();

const CreatePermissions = Iterator.new(EntityMap)
    .map(([entity_name]) => Permission.fromObject({
        id: uuid.v5(`${entity_name}_create`, PERMISSION_ID_NAMESPACE),
        entity_name,
        permission_type: PermissionType.Create,
    })).intoArray();

const DefaultUserGroups = [
    UserGroup.fromObject({
        id: '9e158008-9275-47c0-b49c-5e37c364f2df',
        name: 'Admin',
        permissions: DeletePermissions,

        allowed_urls: [
            '/',
            '/setup',
            '/sync-test',
            '/preferences',
            '/preferences/users',
            '/preferences/users/{entityId}',
            '/preferences/users/{entityId}/edit',
            '/preferences/export',
            '/carefacility',
            '/carefacility/{entityId}',
            '/carefacility/{entityId}/details',
            '/carefacility/{entityId}/details/edit',
            '/carefacility/{entityId}/patients',
            '/carefacility/{careFacilityId}/patients/{entityId}',
            '/carefacility/{careFacilityId}/patients/{entityId}/details',
            '/carefacility/{careFacilityId}/patients/{entityId}/details/edit',
            '/carefacility/{careFacilityId}/patients/{entityId}/treatment',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}/edit',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}/edit-{editType}',
            '/carefacility/{careFacilityId}/patients/{entityId}/insurancescan',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}/edit',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}/edit-{editType}',
            '/accountingnumber',
            '/accountingnumber/{entityId}',
            '/accountingnumber/{entityId}/edit',
            '/chainaccountingnumber',
            '/chainaccountingnumber/{entityId}',
            '/chainaccountingnumber/{entityId}/details',
            '/chainaccountingnumber/{entityId}/details/edit',
            '/chainaccountingnumber/{entityId}/chainaccountingnumbertocccountingnumber',
            '/chainaccountingnumber/{chainAccountingNumberId}/chainaccountingnumbertocccountingnumber/{entityId}',
            '/chainaccountingnumber/{chainAccountingNumberId}/chainaccountingnumbertocccountingnumber/{entityId}/details',
            '/chainaccountingnumber/{chainAccountingNumberId}/chainaccountingnumbertocccountingnumber/{entityId}/edit',
            '/chainaccountingnumber/{entityId}/edit',
            '/medicalpractice',
            '/medicalpractice/{entityId}',
            '/medicalpractice/{entityId}/edit',
        ],
    }),

    UserGroup.fromObject({
        id: '6aed97c9-e817-4ac9-92fb-57b894e5ce54',
        name: 'Arzt',
        permissions: CreatePermissions,
        allowed_urls: [
            '/',
            '/setup',
            '/sync-test',
            '/patients',
            '/patients/{entityId}',
            '/patients/{entityId}/edit',
            '/carefacility',
            '/carefacility/{entityId}',
            '/carefacility/{entityId}/details',
            '/carefacility/{entityId}/details/edit',
            '/carefacility/{entityId}/patients',
            '/carefacility/{careFacilityId}/patients/{entityId}',
            '/carefacility/{careFacilityId}/patients/{entityId}/details',
            '/carefacility/{careFacilityId}/patients/{entityId}/details/edit',
            '/carefacility/{careFacilityId}/patients/{entityId}/treatment',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}/edit',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}/edit-{editType}',
            '/carefacility/{careFacilityId}/patients/{entityId}/insurancescan',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}/edit',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}/edit-{editType}',
            '/medicalpractice',
            '/medicalpractice/{entityId}',
            '/medicalpractice/{entityId}/edit',
        ]
    }),

    UserGroup.fromObject({
        id: 'c089de25-e16b-4879-aa27-80404ef09304',
        name: 'Praxismitarbeiter',
        permissions: CreatePermissions,
        allowed_urls: [
            '/',
            '/setup',
            '/sync-test',
            '/patients',
            '/patients/{entityId}',
            '/patients/{entityId}/edit',
            '/carefacility',
            '/carefacility/{entityId}',
            '/carefacility/{entityId}/details',
            '/carefacility/{entityId}/details/edit',
            '/carefacility/{entityId}/patients',
            '/carefacility/{careFacilityId}/patients/{entityId}',
            '/carefacility/{careFacilityId}/patients/{entityId}/details',
            '/carefacility/{careFacilityId}/patients/{entityId}/details/edit',
            '/carefacility/{careFacilityId}/patients/{entityId}/treatment',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}/edit',
            '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}/edit-{editType}',
            '/carefacility/{careFacilityId}/patients/{entityId}/insurancescan',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}/edit',
            '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}/edit-{editType}',
            '/medicalpractice',
            '/medicalpractice/{entityId}',
            '/medicalpractice/{entityId}/edit',
        ]
    }),
];

export const Events = {
    PermissionsChanged: Symbol('AccessControlManager.Events.PermissionsChanged'),
};

/**
 * @mixes EventTarget
 */
export const AccessControlManager = {
    Events,

    whitelist: [
        '/',
        '/setup',
        '/login'
    ],

    routes: null,
    userGroup: null,
    user: null,

    /**
     * @type {WeakMap.<UserGroup, Map.<string, RegExp>>}
     */
    visitCache: null,

    /**
     * @type {Map.<string, RegExp>}
     */
    regexCache: null,

    init(config) {
        this.constructor();
        this.routes = config.application.routes;
        this.visitCache = new WeakMap();
        this.regexCache = new Map();

        const groupCreation = Iterator.new(DefaultUserGroups)
            .map(group => {
                return StorageManager.queryEntityById(UserGroup, group.id)
                    .catch(() => this[Private.storeGroup](group));
            });

        return Promise.all(groupCreation)
            .then(() => StorageManager.queryEntityById(UserGroup, '9e158008-9275-47c0-b49c-5e37c364f2df'))
            .then(group => {
                if (ConfigManager.environment === 'dev') {
                    this.userGroup = group;
                    this.emit(Events.PermissionsChanged);

                    return StorageManager.queryEntityById(User, 'adddd008-9275-47c0-b49c-5e37c364f2df')
                        .then((admin) => {
                            this.user = admin;
                        });
                }
            }).then(() => this.remountRoutes());
    },

    updateUser(user) {
        this.user = user;
        this.userGroup = user.usergroup;
        this.emit(Events.PermissionsChanged);
        this.remountRoutes();
    },

    mountRoutes() {
        const allowedRoutes = this.userGroup?.allowed_urls ?? [];
        const availableRoutes = allowedRoutes.concat(this.whitelist);

        const allRoutes = Iterator.new(this.routes).flatMap((route) => {
            const paths = Array.isArray(route.path) ? route.path : [route.path];

            return paths.map(path => {
                if (!availableRoutes.includes(path)) {
                    const replacementPage = this.userGroup ? PermissionDeniedPage : AuthenticatingPage;

                    return [path, replacementPage];
                }

                return [path, route];
            });
        });

        Router.state.actions.length = 0;

        allRoutes.forEach(([path, route]) => {
            Router.addRoutable(path, route);
        });
    },

    mountInitialRoutes() {
        this.mountRoutes();

        if (ConfigManager.environment !== 'dev') {
            window.location.hash = '#!/';
        }
    },

    remountRoutes() {
        const currentRoute = location.hash;

        location.replace('#!/remount');
        Router.trigger();
        this.mountRoutes();
        location.replace(currentRoute);
        Router.trigger();
    },

    /**
     * @param  {string} entityName
     *
     * @return {Permission}
     */
    getEntityPermission(entityName) {
        return this.userGroup?.permissions
            .find(permission => permission.entity_name === entityName) ?? null;
    },

    /**
     * @param  {string} path
     *
     * @return {boolean}
     */
    canVisitPath(path) {
        const visitCache = this.visitCache.get(this.userGroup) ?? new Map();

        if (visitCache.has(path)) {
            return visitCache.get(path);
        }

        const canVisit = Iterator.new(this.userGroup?.allowed_urls ?? this.whitelist)
            .some(existingPath => {
                const regex = this.regexCache.get(existingPath) ?? new RegExp(`^(#!)?${existingPath.replace(/\{[^{}]*\}/g, '[^/]+')}$`);

                this.regexCache.set(existingPath, regex);

                return regex.exec(path) !== null;
            });

        visitCache.set(path, canVisit);
        this.visitCache.set(this.userGroup ?? NO_USER_GROUP, visitCache);

        return canVisit;
    },

    createInitialUser() {
        return StorageManager.query(User, false).where('id').from('').get().then((entities) => {
            if (!entities.length) {
                const createUserPermission = Permission.fromObject({
                    id: uuid.v5('user_create', PERMISSION_ID_NAMESPACE),
                    entity_name: 'user',
                    permission_type: PermissionType.Create,
                });

                this.userGroup = UserGroup.fromObject({
                    id: 'g0a9de25-e16b-4879-aa27-80404ef09313',
                    name: 'Gast',
                    permissions: [createUserPermission],
                    allowed_urls: []
                });


                return StorageManager.queryOne(UserGroup, true).where('id').equals('9e158008-9275-47c0-b49c-5e37c364f2df').get().then((adminGroup) => {
                    return Password.asyncFromText('admin').then((pass) => {
                        this.user = {
                            id: 'adddd008-9275-47c0-b49c-5e37c364f2df',
                            first_name: 'admin',
                            last_name: 'admin',
                            username: 'admin',
                            password: JSON.stringify(pass),
                            usergroup: adminGroup
                        };

                        return StorageManager.storeChanges('adddd008-9275-47c0-b49c-5e37c364f2df',
                            {
                                first_name: 'admin',
                                last_name: 'admin',
                                username: 'admin',
                                password: JSON.stringify(pass),
                                usergroup: adminGroup
                            },
                            UserTrait)
                            .then(() => {
                                this.user = null;
                            });
                    });
                });
            }
        });
    },

    /**
     * @param {UserGroup} group
     *
     * @return {Promise}
     */
    [Private.storeGroup](group) {
        const permissions = group.permissions.map(permission => {
            return StorageManager.store(permission);
        });

        const groupFields = StorageManager.getReferenceFields(group, Trait.for(group));


        return Promise.all([StorageManager.store(groupFields, Trait.for(group)), ...permissions]);
    },

    __proto__: EventTarget,
};

export default AccessControlManager;

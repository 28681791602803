import { MedicalPractice } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';

/**
 * @mixes EntityManager
 */
export const MedicalPracticeManager = {
    Entity: MedicalPractice,
    Sorter: 'name',

    __proto__: EntityManager
};

export default MedicalPracticeManager;

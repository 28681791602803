import {createView} from '../prototypes/Page.js';
import { GenericTabsPage, genericTabsPageViewFactory } from '../prototypes/GenericTabsPage.js';
import { CareFacilityPatientsPage } from './CareFacilityPatients.js';
import PatientDetailPage from './PatientDetail.js';
import PatientTreatmentPage from './Treatment/PatientTreatment.js';
import {PatientInsuranceScanPage} from './InsuranceScan/PatientInsuranceScan.js';

export const PatientTabsPage = {
    name: 'PatientTabsPage',
    path: '/carefacility/{careFacilityId}/patients/{entityId}',
    template: 'page-patient-tabs',
    title: 'page.patient_detail_page.title',

    tabs: [
        { title: 'page.patient_detail_page.detailsTab', page: PatientDetailPage },
        { title: 'page.patient_detail_page.treatmentTab', page: PatientTreatmentPage },
        { title: 'page.patient_detail_page.insuranceScanTab', page: PatientInsuranceScanPage },
    ],

    parent: CareFacilityPatientsPage,

    __proto__: GenericTabsPage,
};

createView(genericTabsPageViewFactory, PatientTabsPage);

export default PatientTabsPage;

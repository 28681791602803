import EventTarget from 'application-frame/core/EventTarget.js';
import Iterator from '@prograp/iterator';

/**
 * @mixes EventTarget
 */
export const LanguageManager = {
    strings: null,

    init() {
        this.constructor();

        const lang = 'de-DE';

        import(

            /* webpackChunkName: "lang_[index]" */
            `../../assets/lang/${lang}.json`
        ).then(({ default: strings }) => {
            this.strings = strings;
            this.emit('change');
        });
    },

    translate(key, variables = {}) {
        if (!this.strings?.[key]) {
            return key;
        }

        const findVars = /%([^%]+)%/;
        let string = this.strings[key];

        Iterator.new(function*() {
            let result = null;

            while ((result = findVars.exec(string))) {
                yield result[1];
            }

            return result;
        }()).forEach(name => {
            if (!variables[name]) {
                return;
            }

            string = string.replace(`%${name}%`, variables[name]);
        });

        return string;
    },

    __proto__: EventTarget,
};

export default LanguageManager;

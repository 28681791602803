import { createView } from '../../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../../prototypes/GenericEditPage.js';
import { User } from 'zpp-mpr-lib/models.js';
import { PasswordFieldComponent } from '../../components/PasswordField.js';
import { UserDetailPreferencesPage } from './UserDetailPerferences.js';
import { UserPreferencesPage } from './UserPreferences.js';
import {AddressLocationFeature} from '../../lib/AddressLocationFeature.js';

export const EditUserDetailPreferencesPage = {
    name: 'EditUserDetailPreferencesPage',
    path: '/preferences/users/{entityId}/edit',
    template: 'page-edit-user-detail-preferences',
    addressLocationFeature: null,

    fields: [
        'first_name',
        'last_name',
        'username',
        'street',
        'house_number',
        'zip',
        'city',
        'country',
        'phone_number',
        'medical_practice',
        'password',
        'usergroup',
    ],

    init(template) {
        super.init(template, 'user_detail_preferences_page');

        this.addressLocationFeature = AddressLocationFeature(undefined, this);
    },

    saveChanges() {
        return this.addressLocationFeature.attachLatLong()
            .then(() => super.saveChanges());
    },

    fieldInputTypes: {
        password: PasswordFieldComponent,
    },

    entityType: User,

    get parent() {
        if (this.isNew) {
            return UserPreferencesPage;
        }

        return UserDetailPreferencesPage;
    },

    __proto__: GenericEditPage,
};

createView(genericEditPageViewFactory, EditUserDetailPreferencesPage);

export default EditUserDetailPreferencesPage;

/**
 * applies temporary placement and dimension styling to a target Element
 *
 * @param  {Element} target
 * @param  {number} width
 * @param  {number} height
 *
 * @return {undefined}
 */
const positionInHost = function(target, width, height) {
    target.style.setProperty('position', 'absolute');
    target.style.setProperty('border', '0');
    target.style.setProperty('width', `${width}px`);
    target.style.setProperty('height', `${height}px`);
    target.style.setProperty('top', '0');
    target.style.setProperty('left', '0');
    target.style.setProperty('transform', 'translate3d(0, 0, 0)');
};

/**
 * Removes all temporary style modifications from a target Element
 *
 * @param  {Element} target
 *
 * @return {undefined}
 */
const clearHostPosition = function(target) {
    target.style.removeProperty('position');
    target.style.removeProperty('border');
    target.style.removeProperty('width');
    target.style.removeProperty('height');
    target.style.removeProperty('top');
    target.style.removeProperty('left');
    target.style.removeProperty('transform');
    target.style.removeProperty('opacity');
    target.classList.remove('container-transform-force-show');
};

const placeholderMap = new WeakMap();

/**
 * extracts a DOM element from it's current position in the DOM
 * and replaces it with a placeholder element.
 *
 * @param  {Element} target
 *
 * @return {Element}
 */
const extractWithPlaceholder = function(target) {
    const placeholder = document.createElement('div');
    const targetStyle = window.getComputedStyle(target);
    const duplicateProperties = [
        'position', 'top', 'left', 'bottom', 'right', 'margin-top',
        'margin-right', 'margin-bottom', 'margin-left'
    ];

    placeholder.style.setProperty('width', `${target.offsetWidth}px`);
    placeholder.style.setProperty('height', `${target.offsetHeight}px`);

    duplicateProperties.forEach(property => {
        let value = targetStyle.getPropertyValue(property);

        if (value === 'static') {
            value = 'relative';
        }

        placeholder.style.setProperty(property, value);
    });

    target.parentElement.replaceChild(placeholder, target);
    placeholderMap.set(target, placeholder);

    return target;
};

/**
 * creates a host element for the container transformation
 *
 * @param  {string} name
 * @param  {number} x
 * @param  {number} y
 * @param  {number} width
 * @param  {number} height
 *
 * @return {Element}
 */
const createTransformHost = function(name, x, y, width, height) {
    const host = document.createElement('div');

    host.classList.add('container-transform', name);
    host.style.setProperty('left', `${x}px`);
    host.style.setProperty('top', `${y}px`);
    host.style.setProperty('width', `${width}px`);
    host.style.setProperty('height', `${height}px`);
    host.style.setProperty('overflow', 'hidden');
    host.style.setProperty('position', 'fixed');
    host.style.setProperty('z-index', CssVariables.ZIndex);
    host.style.setProperty('background-color', CssVariables.Background);
    host.style.setProperty('box-shadow', CssVariables.Shadow);

    return host;
};

const CssVariableNames = {
    Prefix: '--app-container-transform',
    get ZIndex() { return `${this.Prefix}-zindex`; },
    get Background() { return `${this.Prefix}-bg`; },
    get Shadow() { return `${this.Prefix}-shadow`; },
    get ShadowEnd() { return `${this.Prefix}-shadow-end`; },
};

const CssVariableDefaults = {
    ZIndex: '10000',
    Background: '#fff',
    Shadow: 'none',
    ShadowEnd: 'none',
};

const CssVariables = {
    ZIndex: `var(${CssVariableNames.ZIndex}, ${CssVariableDefaults.ZIndex})`,
    Background: `var(${CssVariableNames.Background}, ${CssVariableDefaults.Background})`,
    Shadow: `var(${CssVariableNames.Shadow}, ${CssVariableDefaults.Shadow})`,
    ShadowEnd: `var(${CssVariableNames.ShadowEnd}, ${CssVariableDefaults.ShadowEnd})`,
};

/**
 * extracts the current value of a css variable for a given DOM element
 *
 * @param  {Element} target
 * @param  {string} variable
 *
 * @return {string}
 */
const getComputedCssVariable = function(target, variable) {
    const variablName = CssVariableNames[variable];
    const variableDefault = CssVariableDefaults[variable];

    return window.getComputedStyle(target).getPropertyValue(variablName) || variableDefault;
};

export const ContainerTransform = {

    /**
     * @type {string}
     */
    name: null,

    /**
     * @type {Element}
     */
    source: null,

    /**
     * @type {Element}
     */
    target: null,

    state: null,


    /**
     * prepares the transition animation. Source and target elements are beeing
     * replaced by two placeholders and are moved to a temporary animation host element.
     *
     * @return {object}
     */
    setup() {
        const sourceElement = this.source;
        const targetElement = this.target;

        const { left: sourceX, top: sourceY, width: sourceW, height: sourceH } = sourceElement.getBoundingClientRect();
        const sourceParent = sourceElement.parentElement;
        const host = createTransformHost(this.name, sourceX, sourceY, sourceW, sourceH);

        targetElement.classList.add('container-transform-force-show');

        const { left: targetX, top: targetY, width: targetW, height: targetH } = targetElement.getBoundingClientRect();
        const targetParent = targetElement.parentElement;

        host.appendChild(extractWithPlaceholder(sourceElement));
        host.appendChild(extractWithPlaceholder(targetElement));

        positionInHost(sourceElement, sourceW, sourceH);
        positionInHost(targetElement, targetW, targetH);

        targetElement.style.setProperty('opacity', 0);

        document.body.appendChild(host);

        this.state = {
            parents: { source: sourceParent, target: targetParent },
            elements: { source: sourceElement, target: targetElement, host },
            sourceBox: { x: sourceX, y: sourceY, width: sourceW, height: sourceH },
            targetBox: { x: targetX, y: targetY, width: targetW, height: targetH },
        };

        return this.state;
    },

    /**
     * destroys the animation host and reinserts the source and target elements
     * back into their original position.
     *
     * @return {undefined}
     */
    destroy() {
        const { host, source, target } = this.state.elements;

        const sourcePlaceholder = placeholderMap.get(source);
        const targetPlaceholder = placeholderMap.get(target);

        clearHostPosition(source);
        clearHostPosition(target);

        sourcePlaceholder.parentElement.replaceChild(source, sourcePlaceholder);
        targetPlaceholder.parentElement.replaceChild(target, targetPlaceholder);

        host.parentElement.removeChild(host);
    },

    /**
     * executes the transition with setup and destruction
     *
     * @return {Promise}
     */
    run() {
        this.setup();

        return this.execute().then(() => this.destroy());
    },

    /**
     * executes the already setup transition
     *
     * @return {Promise}
     */
    execute() {
        const ANIMATION_DURATION = 300;
        const SOURCE_FADE_OUT = 90;
        const TARGET_FADE_IN = 210;
        const { targetBox, elements, sourceBox, } = this.state;

        const sourceKeyframeEffect = new KeyframeEffect(
            elements.source,
            [
                { opacity: 1 },
                { opacity: 0 },
            ],
            { duration: SOURCE_FADE_OUT, easing: 'ease-out' },
        );

        const targetKeyframeEffect = new KeyframeEffect(
            elements.target,
            [
                { opacity: 0 },
                { opacity: 1 },
            ],
            { delay: SOURCE_FADE_OUT, duration: TARGET_FADE_IN, easing: 'ease-in' },
        );

        const hostKeyframeEffect = new KeyframeEffect(
            elements.host,
            [
                {
                    transform: 'translate3d(0, 0, 0)',
                    width: `${sourceBox.width}px`,
                    height: `${sourceBox.height}px`,
                    boxShadow: getComputedCssVariable(elements.host, 'Shadow'),
                },
                {
                    transform: `translate3d(${targetBox.x - sourceBox.x}px, ${targetBox.y - sourceBox.y}px, 0)`,
                    width: `${targetBox.width}px`,
                    height: `${targetBox.height}px`,
                    boxShadow: getComputedCssVariable(elements.host, 'ShadowEnd'),
                },
            ],
            { duration: ANIMATION_DURATION, easing: 'ease-in-out' }
        );

        const animations = [
            new Animation(sourceKeyframeEffect, document.timeline),
            new Animation(targetKeyframeEffect, document.timeline),
            new Animation(hostKeyframeEffect, document.timeline)
        ].map(animation => {
            animation.play();

            return animation.finished;
        });

        return Promise.all(animations);
    },

    /**
     * creates a new instance of the ContainerTransform prototype
     *
     * @param {PointerEvent} source
     * @param {Element} target
     * @param {string} name
     *
     * @return {ContainerTransform}
     */
    new(source, target, name=`ct-${Date.now()}`) {
        return { name, source, target, __proto__: this };
    }
};

window.ContainerTransform = ContainerTransform;

export default ContainerTransform;

import { Page, defaultViewFactory, createView } from '../prototypes/Page.js';
import EventSubscriberFeature from '../lib/EventSubscriberFeature.js';
import { SyncManager } from '../managers/Sync.js';
import {getPreciseDistance} from 'geolib';
import searchNominatim from '../lib/Nomatim.js';

export const GeoCoderPage = {
    name: 'GeoCoderPage',
    path: '/geocoder',
    template: 'page-geocoder',

    transactionList: [],

    strings: {},

    init(template) {
        super.init(template);

        EventSubscriberFeature(SyncManager, this);
    },

    __proto__: Page,
};

createView((page) => {
    return {
        start: '',
        goal: '',

        get active() {
            return page.active;
        },

        get transactions() { return page.transactionList; },

        calculate() {

            Promise.all([
                this.search(this.start),
                this.search(this.goal)
            ]).then((res) => {
                console.log(getPreciseDistance({ latitude: res[0][0].lat, longitude: res[0][0].lon }, { latitude: res[1][0].lat, longitude: res[1][0].lon }));
            });
        },

        search(query) {
            return searchNominatim({
                q: query,
                countrycodes: 'de',
                acceptLanguage: 'de'
            }).then(results => {
                console.log(results);

                return results;
            }).catch(error => {
                console.error(error);
            });
        },

        __proto__: defaultViewFactory(page),
    };
}, GeoCoderPage);

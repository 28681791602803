import DataBinding from '@af-modules/databinding';

import LanguageManager from '../managers/Language.js';
import MagicTranslationKeyResolver from '../lib/MagicTranslationKeyResolver.js';

const viewFactories = new Map();

export const createView = function(viewFactory, component) {
    viewFactories.set(component, viewFactory);

    return viewFactory;
};

export const defaultViewFactory = function(component) {
    return {
        get strings() {
            return component.strings;
        },

        l10n(key) {
            if (Array.isArray(key)) {
                return LanguageManager.translate(...key);
            }

            return LanguageManager.translate(key);
        },
    };
};

const Callbacks = {
    onL10nChanged: Symbol('Component.Callbacks.onL10nChanged'),
};

export const Component = {
    inflated: false,
    strings: null,
    scope: null,
    nodes: null,
    name: 'UnnamedComponent',
    id: null,

    [Callbacks.onL10nChanged]() {
        if (!this.scope) {
            return;
        }

        this.scope.update();
    },

    init() {
        this.scope.not = value => !value;
        this.inflated = true;

        if (!this.strings) {
            this.strings = MagicTranslationKeyResolver.new('component', this);
        }

        LanguageManager.on('change', this[Callbacks.onL10nChanged].bind(this));
    },

    newView() {
        const prototype = Object.getPrototypeOf(this);
        const viewFactory = viewFactories.get(this) ?? viewFactories.get(prototype);

        if (!viewFactory) {
            throw new Error(`no view factory defined for component ${prototype.name}`);
        }

        return viewFactory(this);
    },

    instantiateTemplate(template) {
        const { scope } = DataBinding.createTemplateInstance({ template, scope: this.newView() });

        this.scope = scope;
    },

    new() {
        const id = Math.round(performance.now() * Math.random());

        return { id: `cmp-${id}`, __proto__: this };
    },

    fromTemplate(template) {
        const instance = this.new();

        instance.instantiateTemplate(`#${template}`);

        return instance;
    }
};

export default Component;
export { Callbacks as ComponentCallbacks };

import { Patient } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';

/**
 * @mixes EntityManager
 */
export const PatientManager = {
    Entity: Patient,
    Sorter: 'last_name',

    __proto__: EntityManager
};

export default PatientManager;

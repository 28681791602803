import { DataStorage } from './DataStorage.js';

const Private = {
    current: Symbol('Pagination.Private.current'),
    host: Symbol('Pagination.Private.host'),
};

export const Callbacks = {
    onQueryEntities: Symbol('Pagination.Callbacks.onQueryEntities'),
    onQueryEntityCount: Symbol('Pagination.Callbacks.onQueryEntityCount'),
};

export const Pagination = {
    [Private.current]: 0,
    [Private.host]: null,

    busy: null,
    entites: null,

    totalCount: 0,
    pageSize: 10,
    sortColumn: null,
    sortDirection: null,

    get current() {
        return this[Private.current];
    },

    set current(value) {
        this[Private.current] = value;
        this.update();
    },

    sort(column, direction) {
        this.sortColumn = column;
        this.sortDirection = direction;
        this.update();
    },

    queryEnitities(start, amount, sortColumn, sortDirection) {
        return this[Private.host][Callbacks.onQueryEntities](start, amount, sortColumn, sortDirection);
    },

    queryEntityCount() {
        return this[Private.host][Callbacks.onQueryEntityCount](this.entityManager);
    },

    update() {
        const start = this[Private.current] * this.pageSize;
        const sortColumn = this.sortColumn ?? undefined;
        const sortDirection = this.sortDirection ?? undefined;

        this.busy = this.busy
            .then(() => this.queryEnitities(start, this.pageSize, sortColumn, sortDirection))
            .then(list => {
                this.entities.fill(list);
            });
    },

    new(host) {
        const entities = DataStorage.new();
        const busy = Promise.resolve();
        const instance = { [Private.host]: host, entities, busy, __proto__: this };

        instance.queryEntityCount().then(count => {
            instance.totalCount = count;
            instance.entites?.fill(instance.entites?.value);
        });

        instance.current = 0;

        return instance;
    }
};

export default Pagination;

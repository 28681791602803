import { Iterator } from '@prograp/iterator';
import { Trait } from 'zpp-mpr-lib/traits.js';
import { SubscriberFeature } from 'application-frame/core/features/SubscriberFeature.js';

import { defaultViewFactory, Page, getPageElement } from './Page.js';
import {ComponentHost} from './ComponentHost.js';
import {AppBarComponent} from '../components/AppBar.js';
import {Events as FloatingActionEvents} from '../managers/FloatingAction.js';
import {FloatingActionFeature} from '../lib/FloatingActionFeature.js';
import {Router} from '@af-modules/router';
import {TransitionTypes, ViewTransitionManager} from '../managers/ViewTransition.js';
import { Events as StorageEvents, StorageManager } from '../managers/Storage.js';
import { EntityManager } from '../managers/Entity.js';
import { displayAsText } from '../lib/GenericValuePresentation.js';
import { Pagination, Callbacks as PaginationCallbacks } from '../lib/Pagination.js';

export const GenericListingPage = {
    pagination: null,
    generatedColumns: null,
    floatingActionFeature: null,
    useAppBar: true,
    columnDisplay: {},

    /**
     * @type {EntityManager}
     */
    entityManager: null,

    get floatingActionConfig() {
        return { label: this.strings.addNewEntity, icon: 'add' };
    },

    init(template, pageTranslationKey) {
        super.init(template, pageTranslationKey);

        this.floatingActionFeature = FloatingActionFeature(this.floatingActionConfig, this);
        this.generatedColumns = this.columns.map(id => ({ label: `${this.strings[id]}_label`, id, display: this.columnDisplay[id] }));

        SubscriberFeature(StorageManager, this);

        this.components = ComponentHost.new({
            appBar: {
                prototype: AppBarComponent,
                config: {
                    title: this.title,
                    host: this,
                    visibile: this.useAppBar,
                }
            }
        });
    },

    [FloatingActionEvents.Action](event) {
        const currentPath = Router.getCurrentPath().slice(1).join('/');

        Router.switchTo(`/${currentPath}/new/edit`);

        ViewTransitionManager.enter(event.currentTarget, TransitionTypes.FabToPage);
    },


    [StorageEvents.EntityChanged](event) {
        if (!this.active) {
            return;
        }

        if (event.trait !== Trait.for(this.entityManager.Entity)) {
            return;
        }

        this.createPagination();
    },

    [PaginationCallbacks.onQueryEntities](start, amount, sortColumn, sortDirection) {
        return this.entityManager.getEntities(start, amount, sortColumn, sortDirection);
    },

    [PaginationCallbacks.onQueryEntityCount]() {
        return this.entityManager.getTotalEntityCount();
    },

    createPagination() {
        this.pagination = Pagination.new(this);
        this.pagination.entities.when(this.scope.update);
    },

    onRouteEnter() {
        super.onRouteEnter();
        this.createPagination();
        this.floatingActionFeature.claim();
    },

    onRouteLeave() {
        super.onRouteLeave();
        this.floatingActionFeature.release();
    },

    __proto__: Page,
};

export const genericListingPageViewFactory = (page) => {
    return {
        get components() {
            return page.components.viewAdapter;
        },

        get page() {
            return page.pagination.current + 1;
        },

        set page(value) {
            page.pagination.current = (value - 1);
        },

        get total() {
            return page.pagination.totalCount;
        },

        get columns() {
            return page.generatedColumns;
        },

        get rows() {
            const rows = page.pagination?.entities.value ?? [];

            return rows.map(row => {
                const cells = Iterator.new(page.columns)
                    .map((key) => ({ value: page.columnDisplay[key] ? page.columnDisplay[key](row[key] ?? row) : displayAsText(row[key]), isNumeric: false }))
                    .intoArray();

                return { id: row.id, cells };
            });
        },

        onSortTable(event) {
            const { columnId, sortValue } = event.detail;
            const direction = (sortValue === 'ascending') ? 'ASC' : 'DESC';

            page.pagination.sort(columnId, direction);
        },

        onRowDetails(event, scope) {
            ViewTransitionManager.enter(getPageElement(page), TransitionTypes.PageZPush);
            Router.down(scope.row.id);
        },

        __proto__: defaultViewFactory(page),
    };
};

export default GenericListingPage;

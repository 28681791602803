const pSource = Symbol('GenericTransition.source');
const pSourceClass = Symbol('GenericTransition.sourceClass');
const pTargetClass = Symbol('GenericTransition.targetClass');
const pTarget = Symbol('GenericTransition.target');

const createAnimationEndPromise = target => new Promise(resolve => {
    target.addEventListener('animationend', resolve, { once: true });
});

export const GenericTransition = {
    [pSource]: null,
    [pTarget]: null,
    [pSourceClass]: null,
    [pTargetClass]: null,

    run() {
        this[pSource].classList.add(this[pSourceClass]);
        this[pTarget].classList.add(this[pTargetClass]);

        return Promise.all([
            createAnimationEndPromise(this[pSource]),
            createAnimationEndPromise(this[pTarget])
        ]).then(() => {
            this[pSource].classList.remove(this[pSourceClass]);
            this[pTarget].classList.remove(this[pTargetClass]);

        });
    },

    new(source, target, [sourceClass, targetClass]) {
        return {
            [pSource]: source,
            [pTarget]: target,
            [pSourceClass]: sourceClass,
            [pTargetClass]: targetClass,
            __proto__: this
        };
    }
};

export default GenericTransition;

import { createView } from '../prototypes/Component.js';
import { SelectFieldComponent, selectFieldViewFactory } from './SelectField.js';
import { EntityManager } from '../managers/Entity.js';

export const RelationSelectFieldComponent = {
    name: 'RelationSelectFieldComponent',
    entityManager: null,
    optionEntities: null,

    init(config) {
        this.entityManager = EntityManager.forTrait(config.propertyType);

        super.init(config);
    },

    update() {
        super.update();
        this.updateOptions();
    },

    updateOptions() {
        this.entityManager.getEntities()
            .then(list => {
                const options = list.map(option => ({ label: option.label, value: option.id }));

                this.optionEntities = list;
                this.options.fill(options);
            });
    },

    __proto__: SelectFieldComponent,
};

createView((component) => {
    return {
        get value() {
            return component.config.data?.id;
        },

        set value(value) {
            const option = component.optionEntities?.find(item => item.id === value);

            if (!option) {
                component.scope.update();

                return;
            }

            component.config.data = option;
        },

        __proto__: selectFieldViewFactory(component)
    };
}, RelationSelectFieldComponent);

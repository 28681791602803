import { LanguageManager } from '../managers/Language.js';

export const displayAsText = function(value) {
    if (value === null || value === undefined) {
        return '';
    }

    if (typeof value === 'boolean') {
        return LanguageManager.translate(`general.generic_value_presentation.${value ? 'true' : 'false'}`);
    }

    if (value.toISOString) {
        const year = value.getUTCFullYear();
        const month = value.getUTCMonth();
        const day = value.getUTCDate();
        const viewDate = new Date(year, month, day);

        return viewDate.toLocaleString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric'});
    }

    if (value.label) {
        return value.label;
    }

    return value;
};

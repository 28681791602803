import { createView } from '../prototypes/Component.js';
import { FileFieldComponent, createFileFieldViewFactory } from './FileField.js';

export const ImageFileFieldComponent = {
    name: 'ImageFileFieldComponent',
    accept: 'image/*',

    __proto__: FileFieldComponent,
};

createView(createFileFieldViewFactory, ImageFileFieldComponent);

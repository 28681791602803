import { createView } from '../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../prototypes/GenericEditPage.js';
import { Patient } from 'zpp-mpr-lib/models.js';
import PatientDetailPage from './PatientDetail.js';
import CareFacilityPatientsPage from './CareFacilityPatients.js';
import CareFacilityManager from '../managers/CareFacility.js';

export const EditPatientDetailPage = {
    name: 'EditPatientDetailPage',
    path: '/carefacility/{careFacilityId}/patients/{entityId}/details/edit',
    template: 'page-edit-patients-detail',

    fields: [
        'first_name',
        'last_name',
        'street',
        'house_number',
        'zip',
        'city',
        'country',
        'birthday',
        'legal_guardian1',
        'legal_guardian2',
        'phone_number',
        'misc',
        'prosthesis',
        'insurance_type',
        'nursing_home',
    ],

    entityType: Patient,

    onGetDefaults(params) {
        return CareFacilityManager.getEntity(params.careFacilityId)
            .then(entity => {
                return {nursing_home: entity};
            });
    },

    get parent() {
        if (this.isNew) {
            return CareFacilityPatientsPage;
        }

        return PatientDetailPage;
    },

    init(template) {
        super.init(template, 'patient_detail_page');
    },

    __proto__: GenericEditPage,
};

createView(genericEditPageViewFactory, EditPatientDetailPage);

export default EditPatientDetailPage;

import { Router } from '@af-modules/router';
import NavigationManager from '../managers/Navigation.js';
import { Page, defaultViewFactory, createView } from '../prototypes/Page.js';
import RegisterManager from '../managers/Register.js';
import SyncManager from '../managers/Sync.js';
import ConfigManager from '../managers/Config.js';
import { SubscriberFeature } from 'application-frame/core/features/SubscriberFeature.js';
import AccessControlManager from '../managers/AccessControl.js';


export const SetupPage = {
    name: 'SetupPage',
    path: '/setup',
    template: 'page-setup',

    busy: false,

    strings: {
        continue: 'page.setup.action.continue',
        qrcode: 'page.setup.action.qrcode',
        tokenHelp: 'page.setup.help.token',
        setupDescription: 'page.setup.description',
        title: 'page.setup.title',
        subtitle: 'page.setup.subtitle',
        hostLabel: 'page.setup.label.host',
        password: 'page.setup.label.password',
        deviceName: 'page.setup.label.deviceName',
        secret: 'page.setup.label.secret',
    },

    init(template) {
        super.init(template);

        SubscriberFeature(SyncManager, this);
        SubscriberFeature(RegisterManager, this);
    },

    onRouteEnter() {
        super.onRouteEnter();
        NavigationManager.closeDrawer();
    },

    onRouteLeave() {
        super.onRouteLeave();
        NavigationManager.openDrawer();
    },

    setFailure(message) {
        this.busy = false;
        this.scope.view.snackbar.message = message;
        this.scope.view.snackbar.open = true;

        this.scope.update();
    },

    [SyncManager.Events.SyncFinished]() {
        AccessControlManager.createInitialUser().then(() => Router.switchTo('/'));
    },

    [RegisterManager.Events.RegisterFailure](message) {
        if(message.name == 'Error'){
            this.setFailure(message.message);

            return;
        }

        this.setFailure(message.error);
    },

    __proto__: Page,
};

createView((page) => {
    return {
        host: '',
        password: '',
        deviceName: '',
        secret: '',

        snackbar: {
            open: false,
            message: '',
        },

        validity: {
            host: false,
        },

        get active() {
            return page.active;
        },

        get isNotReady() {
            return !this.checkValidity() || page.busy;
        },

        get product() {
            return page.product;
        },

        checkValidity() {
            const valid = Object.values(this.validity)
                .reduce((result, input) => result && input, true);

            return valid;
        },

        onAttemptAuth() {
            if (!this.checkValidity()) {
                return;
            }

            ConfigManager.secret = this.secret;

            RegisterManager.register(this.deviceName, this.password, this.host);
            page.busy = true;
        },

        __proto__: defaultViewFactory(page),
    };
}, SetupPage);

import {createView} from '../prototypes/Page.js';
import { GenericTabsPage, genericTabsPageViewFactory } from '../prototypes/GenericTabsPage.js';
import ChainAccountingNumberDetailPage from './ChainAccountingNumberDetail.js';
import ChainAccountingNumberToAccountingNumberPage from './ChainAccountingNumberToAccountingNumber.js';
import ChainAccountingNumberPage from './ChainAccountingNumber.js';

export const ChainAccountingNumberTabsPage = {
    name: 'ChainAccountingNumberTabsPage',
    path: '/chainaccountingnumber/{entityId}',
    template: 'page-chain-accounting-number-tabs',
    title: 'page.chain_accounting_number_detail_page.title',

    tabs: [
        { title: 'page.chain_accounting_number_detail_page.detailsTab', page: ChainAccountingNumberDetailPage },
        { title: 'page.chain_accounting_number_detail_page.chainTab', page: ChainAccountingNumberToAccountingNumberPage },
    ],

    parent: ChainAccountingNumberPage,

    __proto__: GenericTabsPage,
};

createView(genericTabsPageViewFactory, ChainAccountingNumberTabsPage);

export default ChainAccountingNumberTabsPage;

import { Component, createView, defaultViewFactory } from '../prototypes/Component.js';

export const LightboxComponent = {
    name: 'FloatingActionComponent',
    template: 'component-lightbox',

    open: false,
    url: '',

    /**
     * @param  {Blob} blob
     */
    request(blob) {
        if (this.url) {
            URL.revokeObjectURL(this.url);
        }

        this.blob = blob;
        this.url = URL.createObjectURL(blob);
        this.open = true;
        this.scope.update();
    },

    __proto__: Component,
};

createView((component) => {
    return {
        get isOpen() {
            return component.open;
        },

        set isOpen(value) {
            component.open = value;

            if (!component.open) {
                component.blob = null;
                component.scope.update();
            }
        },

        get isImage() {
            return !!component.blob?.type.startsWith('image/');
        },

        get isVideo() {
            return !!component.blob?.type.startsWith('video/');
        },

        get isAudio() {
            return !!component.blob?.type.startsWith('audio/');
        },

        get imageUrl() {
            return this.isImage ? component.url : null;
        },

        get videoUrl() {
            return this.isVideo ? component.url : null;
        },

        get audioUrl() {
            return this.isAudio ? component.url : null;
        },

        __proto__: defaultViewFactory(component),
    };
}, LightboxComponent);

export default LightboxComponent;

import {createView} from '../prototypes/Page.js';
import PatientManager from '../managers/Patient.js';
import GenericDetailPage, {genericPageViewFactory} from '../prototypes/GenericDetail.js';
import CareFacilityPatientsPage from './CareFacilityPatients.js';

export const PatientDetailPage = {
    name: 'PatientDetailPage',
    path: '/carefacility/{careFacilityId}/patients/{entityId}/details',
    template: 'page-patient-detail',
    parent: CareFacilityPatientsPage,
    useAppBar: false,

    fields: [
        'first_name',
        'last_name',
        'street',
        'house_number',
        'zip',
        'city',
        'country',
        'legal_guardian1',
        'legal_guardian2',
        'phone_number',
        'misc',
        'prosthesis',
        'insurance_type',
        'nursing_home',
    ],

    manager: PatientManager,

    __proto__: GenericDetailPage,
};

createView(genericPageViewFactory, PatientDetailPage);

export default PatientDetailPage;

import { Treatment } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';
import {StorageManager} from './Storage.js';

/**
 * @mixes EntityManager
 */
export const TreatmentManager = {
    Entity: Treatment,
    Sorter: 'date_time',

    getEntiesByPatientIdAndMultiTreatment(patientid, multiTreamtentId) {
        return StorageManager.query(Treatment, false)
            .where('patient+multi_treatment')
            .equals([`patient/${patientid}`, `shared_treatment/${multiTreamtentId}`])
            .get();
    },

    __proto__: EntityManager
};

export default TreatmentManager;

import {Router} from '@af-modules/router';

import { CareFacilityTrait } from 'zpp-mpr-lib/traits/models.js';
import { createView } from '../prototypes/Page.js';
import { GenericRelationListingPage, genericRelationListingPageViewFactory } from '../prototypes/GenericRelationListingPage.js';
import { PatientManager } from '../managers/Patient.js';
import {Events as FloatingActionEvents} from '../managers/FloatingAction.js';
import {TransitionTypes, ViewTransitionManager} from '../managers/ViewTransition.js';
import {LanguageManager} from '../managers/Language.js';
import {add, isPast, isThisQuarter} from 'date-fns';

export const CareFacilityPatientsPage = {
    name: 'CareFacilityPatientsPage',
    path: '/carefacility/{entityId}/patients',
    template: 'page-care-facility-patients',

    entityManager: PatientManager,
    parentEntityTrait: CareFacilityTrait,

    columns: [
        'first_name',
        'last_name',
        'birthday',
        'insurance_type',
        'city',
        'last_treatment_date_time',
        'last_insurance_scan_date_time',
    ],

    columnDisplay: {
        last_treatment_date_time(date_time) {
            return LanguageManager.translate(`general.generic_value_presentation.${isPast(add(date_time, {
                months: 3,
                days: 1
            })) ? 'true' : 'false'}`);
        },
        last_insurance_scan_date_time(date_time)  {
            return LanguageManager.translate(`general.generic_value_presentation.${isThisQuarter(date_time) ? 'true' : 'false'}`);
        }
    },

    init(template) {
        super.init(template, 'patient_detail_page');
    },

    [FloatingActionEvents.Action](event) {
        const currentPath = Router.getCurrentPath().slice(1).join('/');

        Router.switchTo(`/${currentPath}/new/details/edit`);

        ViewTransitionManager.enter(event.currentTarget, TransitionTypes.FabToPage);
    },

    __proto__: GenericRelationListingPage,
};

createView(genericRelationListingPageViewFactory, CareFacilityPatientsPage);

export default CareFacilityPatientsPage;

import { CustomElement, CustomElementMeta, pMeta } from '../../prototypes/CustomElement.js';

import defineCustomElement from '../../lib/defineCustomElement.js';
import template from './template.js';

const AppPageMeta = {
    name: 'app-page',
    template,
    get object() { return AppPage; },

    __proto__: CustomElementMeta,
};

const meta = AppPageMeta;

const AppPage = {
    [pMeta]: meta,

    __proto__: CustomElement,
};

defineCustomElement(meta);

export { AppPage, AppPageMeta };

import { createView } from '../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../prototypes/GenericEditPage.js';
import { CareFacilityPage } from './CareFacility.js';
import CareFacilityDetailPage from './CareFacilityDetail.js';
import { CareFacility } from 'zpp-mpr-lib/models.js';
import { AddressLocationFeature } from '../lib/AddressLocationFeature.js';

export const EditCareFacilityDetailPage = {
    name: 'EditCareFacilityDetailPage',
    path: '/carefacility/{entityId}/details/edit',
    template: 'page-edit-care-facility-detail',

    fields: [
        'name',
        'street',
        'house_number',
        'zip',
        'city',
        'country',
        'phone_numbers',
        'director_name',
        'director_phone_numbers',
        'nursing_director_name',
        'nursing_director_phone_numbers'
    ],

    entityType: CareFacility,
    addressLocationFeature: null,

    get parent() {
        if (this.isNew) {
            return CareFacilityPage;
        }

        return CareFacilityDetailPage;
    },

    init(template) {
        super.init(template, 'care_facility_detail_page');

        this.addressLocationFeature = AddressLocationFeature(undefined, this);
    },

    saveChanges() {
        return this.addressLocationFeature.attachLatLong()
            .then(() => super.saveChanges());
    },

    __proto__: GenericEditPage,
};

createView(genericEditPageViewFactory, EditCareFacilityDetailPage);

export default EditCareFacilityDetailPage;

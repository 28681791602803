import {createView} from '../prototypes/Page.js';
import GenericDetail, {genericPageViewFactory} from '../prototypes/GenericDetail.js';
import AccountingNumberManager from '../managers/AccountingNumber.js';
import { AccountingNumberPage } from './AccountingNumber.js';

export const AccountingNumberDetailPage = {
    name: 'AccountingNumberDetailPage',
    path: '/accountingnumber/{entityId}',
    template: 'page-accounting-number-detail',

    fields: [
        'name',
        'BEMA',
        'BEMAextra',
        'GOZ',
        'GOZextra',
        'needs_followup',
        'needs_teeth',
    ],

    parent: AccountingNumberPage,
    manager: AccountingNumberManager,

    __proto__: GenericDetail,
};

createView(genericPageViewFactory, AccountingNumberDetailPage);

export default AccountingNumberDetailPage;

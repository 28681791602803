import {Page, defaultViewFactory, createView} from '../prototypes/Page.js';
import UserManager from '../managers/User.js';
import {Password} from '../lib/Password.js';
import AccessControl from '../managers/AccessControl.js';
import LanguageManager from '../managers/Language.js';
import {Router} from '@af-modules/router';
import NavigationManager from '../managers/Navigation.js';

/**
 * @mixes Page
 */
export const LoginPage = {
    name: 'LoginPage',
    path: ['/login', '/'],
    template: 'page-login',

    busy: false,

    onRouteEnter() {
        if (AccessControl.user) {
            Router.switchTo('/carefacility');

            return;
        }

        super.onRouteEnter();
        NavigationManager.closeDrawer();
    },

    onRouteLeave() {
        super.onRouteLeave();
        NavigationManager.openDrawer();
    },

    __proto__: Page,
};

createView((page) => {
    return {
        name: '',
        password: '',
        userErrorMessage: '',
        passwordErrorMessage: '',

        onLogin() {
            UserManager.queryEntityByUsername(this.name).then((user) => {
                const password = JSON.parse(user.password);

                if (!password) {
                    this.passwordErrorMessage = LanguageManager.translate('page.login_page.passwordError');
                    alert(LanguageManager.translate('page.login_page.passwordError'));

                    return;
                }

                Password.asyncFromText(this.password, password.salt).then((pass) => {
                    if (pass.hash !== password.hash) {
                        this.passwordErrorMessage = LanguageManager.translate('page.login_page.passwordError');
                        alert(LanguageManager.translate('page.login_page.passwordError'));

                        return;
                    }

                    AccessControl.updateUser(user);

                    return true;
                })
                    .then((success) => {
                        if (success) {
                            Router.switchTo('/carefacility');
                        }
                    });
            }).catch(() => {
                this.userErorMessage = LanguageManager.translate('page.login_page.userError');
                alert(LanguageManager.translate('page.login_page.userError'));
            });
        },

        __proto__: defaultViewFactory(page),
    };
}, LoginPage);

import { Page, defaultViewFactory, createView } from '../../prototypes/Page.js';
import { PreferencesPage } from '../Preferences.js';
import { ComponentHost } from '../../prototypes/ComponentHost.js';
import { AppBarComponent } from '../../components/AppBar.js';

export const PermissionPreferencesPage = {
    name: 'PermissionPreferencesPage',
    path: '/preferences/permissions',
    template: 'page-permission-preferences',
    get parent() { return PreferencesPage; },

    icon: 'admin_panel_settings',

    strings: {
        title: 'page.permission_preferences.title',
        description: 'page.permission_preferences.description',
    },

    init(template) {
        super.init(template);

        this.components = ComponentHost.new({
            appBar: {
                prototype: AppBarComponent,
                config: {
                    title: this.strings.title,
                    host: this,
                }
            }
        });
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get components() {
            return page.components.viewAdapter;
        },

        __proto__: defaultViewFactory(page),
    };
}, PermissionPreferencesPage);

export default PermissionPreferencesPage;

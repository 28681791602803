import Iterator from '@prograp/iterator';
import { Page, defaultViewFactory, createView } from '../prototypes/Page.js';
import { ViewTransitionManager, TransitionTypes } from '../managers/ViewTransition.js';
import { ComponentHost } from '../prototypes/ComponentHost.js';
import { AppBarComponent } from '../components/AppBar.js';
import { PermissionPreferencesPage } from './preferences/PermissionPreferences.js';
import { UserPreferencesPage } from './preferences/UserPreferences.js';
import { ExportPreferencesPage } from './preferences/ExportPreferences.js';

export const PreferencesPage = {
    name: 'PreferencesPage',
    icon: 'settings',
    path: '/preferences',
    template: 'page-preferences',
    title: 'page.preferences.title',

    /**
     * @type {ComponentHost}
     * */
    components: null,

    children: [
        PermissionPreferencesPage,
        UserPreferencesPage,
        ExportPreferencesPage
    ],

    init(template) {
        super.init(template);

        this.components = ComponentHost.new({
            appBar: {
                prototype: AppBarComponent,
                config: {
                    title: this.title,
                    host: this,
                }
            }
        });
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get groups() {
            return Iterator.new(page.children)
                .map(page => ({
                    name: page.strings.title,
                    description: page.strings.description,
                    icon: page.icon,
                    url: `#!${page.path}`,
                }))
                .intoArray();
        },

        get components() {
            return page.components.viewAdapter;
        },

        onCardTransition(event) {
            ViewTransitionManager.enter(event.currentTarget, TransitionTypes.CardToPage);
        },

        __proto__: defaultViewFactory(page),
    };
}, PreferencesPage);

export default PreferencesPage;

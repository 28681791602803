import Iterator from '@prograp/iterator';


export function searchNominatim(params) {
    params.format = 'json';

    if (params.acceptLanguage) {
        params['accept-language'] = params.acceptLanguage;
    }

    const url = new URL('https://nominatim.openstreetmap.org/search');

    Iterator.fromObject(params).filter(([, value]) => value).forEach(([key, value]) => url.searchParams.append(key, value));

    const res = timer.then(() => fetch(url)).then(res => res.json());

    timer = res.then(() => later(1000));

    return res;
}

let timer = Promise.resolve();

const later = (delay, value) =>
    new Promise(resolve => setTimeout(resolve, delay, value));

export default searchNominatim;

import { FloatingActionManager } from '../managers/FloatingAction.js';
import { EventSubscriberFeature } from './EventSubscriberFeature.js';

/**
 * @param  {object} config
 * @param  {object} host
 *
 * @return {{ claim: Function, release: Function }}
 */
export const FloatingActionFeature = function(config, host) {
    return {
        claim() {
            const eventTarget = FloatingActionManager.claim(host, config);

            EventSubscriberFeature(eventTarget, host);
        },

        release() {
            FloatingActionManager.release(host);
        }
    };
};

export default FloatingActionFeature;

import BaseDataStorage from 'application-frame/core/DataStorage.js';

export const DataStorage = {
    new(value) {
        const instance = ({ __proto__: this }).constructor();

        if (value) {
            instance.fill(value);
        }

        return instance;
    },

    __proto__: BaseDataStorage,
};

export default DataStorage;

import EventTarget from 'application-frame/core/EventTarget.js';

import { Events as MessageEvents, MessageRoutingManager } from './MessageRouting.js';
import ConnectionManager from './Connection.js';
import EventSubscriberFeature from '../lib/EventSubscriberFeature.js';
import ConfigManager from './Config.js';
import { RegisterMessage, RegisterAuthorizeMessage} from 'zpp-mpr-lib/messages.js';

const Events = {
    RegisterSuccess: Symbol('RegisterManager.Event.RegisterSuccess'),
    RegisterFailure: Symbol('RegisterManager.Event.RegisterFailure'),
};

export const Callbacks = {
    onRegisterResult: Symbol('RegisterManager.Callbacks.onRegisterResult'),
};

/**
 * @mixes EventTarget
 */
export const RegisterManager = {
    Events,
    host: '',
    deviceName: '',
    password: '',

    init() {
        this.constructor();

        EventSubscriberFeature([MessageRoutingManager, ConnectionManager], this);
    },

    register(deviceName, password, host) {
        host = new URL(host);
        this.password = password;
        this.host = host;
        this.deviceName = deviceName;

        host.protocol = ['https:', 'app:'].includes(location.protocol) ? 'wss' : 'ws';

        ConnectionManager.connect(host.toString());

        this.host = host;
    },

    get isRegistered() {
        return ConfigManager.host && ConfigManager.token;
    },

    [MessageEvents.RegisterResponse](message) {
        if (!message.authorized) {
            return this.emit(Events.RegisterFailure, message);
        }

        ConfigManager.token = message.accessToken;
        ConfigManager.host = this.host;

        return ConnectionManager.login().then(() => this.emit(Events.RegisterSuccess));
    },

    [MessageEvents.RegisterRequest](message) {
        const allowed = confirm(`Gerät ${message.deviceName} versucht sich zu registrieren. Erlauben?`);

        ConnectionManager.send(RegisterAuthorizeMessage.new(allowed, message.requestId));
    },

    [ConnectionManager.Events.ConnectionWithoutAuth]() {
        const message = RegisterMessage.new(this.deviceName, this.password);

        ConnectionManager.send(message);
    },
    [ConnectionManager.Events.Error](e) {
        return this.emit(Events.RegisterFailure, e);
    },

    __proto__: EventTarget,
};

export default RegisterManager;

import {defaultViewFactory, Page, getPageElement} from './Page.js';
import {ComponentHost} from './ComponentHost.js';
import {AppBarComponent} from '../components/AppBar.js';
import LanguageManager from '../managers/Language.js';
import {EntityManager} from '../managers/Entity.js';
import {Events as FloatingActionEvents} from '../managers/FloatingAction.js';
import {FloatingActionFeature} from '../lib/FloatingActionFeature.js';
import { displayAsText } from '../lib/GenericValuePresentation.js';
import {Router} from '@af-modules/router';
import { Trait } from 'zpp-mpr-lib/traits.js';
import { BlobTrait } from 'zpp-mpr-lib/traits/models.js';
import {TransitionTypes, ViewTransitionManager} from '../managers/ViewTransition.js';
import {StorageManager} from '../managers/Storage.js';
import {SubscriberFeature} from 'application-frame/core/features/SubscriberFeature.js';
import DataStorage from '../lib/DataStorage.js';
import { AccessControlManager } from '../managers/AccessControl.js';
import { LightboxManager } from '../managers/Lightbox.js';

export const GenericDetailPage = {
    name: '',
    path: '',
    template: '',

    floatingActionFeature: null,

    init(template) {
        super.init(template);

        const page = this;

        SubscriberFeature(StorageManager, this);

        this.entityStore = DataStorage.new();
        this.entityStore.when(this.scope.update);

        this.floatingActionFeature = FloatingActionFeature({
            label: this.strings.edit_action,
            icon: 'edit',
            actions: [{
                name: 'edit',
                icon: 'edit',
                label: this.strings.edit_action,
            }, {
                name: 'delete',
                icon: 'delete',
                label: this.strings.delete_action,
            }],

            get visible() {
                return AccessControlManager.canVisitPath(`${page.path}/edit`);
            }
        }, this);

        this.generatedFields = this.fields.map((field) => {
            return {
                label: this.strings[`${field}_label`],
                name: field,
                get value() { return displayAsText(page.entityStore?.value?.[field]); },
            };
        });

        this.components = ComponentHost.new({
            appBar: {
                prototype: AppBarComponent,
                config: {
                    title: this.strings.title,
                    host: this,
                    visibile: this.useAppBar,
                }
            }
        });
    },

    generatedFields: [],

    fields: [],

    /**
     * @type {EntityManager}
     */
    manager: EntityManager,

    entityStore: null,
    useAppBar: true,

    get delteReturnPath() {
        const currentPath = location.hash.split('/');
        const diff = this.path.split('/').length - this.parent.path.split('/').length;
        const targetPath = currentPath.slice(1, -diff).join('/');

        return `/${targetPath}`;
    },

    [FloatingActionEvents.SubAction](event) {
        if (event.actionName === 'edit') {
            Router.down('edit');
            ViewTransitionManager.enter(getPageElement(this), TransitionTypes.PageZPush);

            return;
        }

        if (event.actionName === 'delete') {
            this.delete();
        }

        console.warn('unknown action:', event);
    },

    onRouteEnter(path, params) {
        super.onRouteEnter();
        this.floatingActionFeature.claim();

        if (params.entityId !== 'new') {
            this.manager.getEntity(params.entityId).then((entity) => {
                this.entityStore.fill(entity);
                this.scope.update();
            });
        }
    },

    onRouteLeave() {
        super.onRouteLeave();
        this.floatingActionFeature.release();
    },

    [StorageManager.Events.EntityChanged](event) {
        if (!this.active) {
            return;
        }

        if (event.data.id !== this.entityStore?.value?.id) {
            return;
        }

        this.manager.getEntity(this.entityStore.value.id)
            .then(user => this.entityStore.fill(user));
    },

    delete() {
        this.manager.storeDelete(this.entityStore.value.id)
            .then(() => {
                location.replace(`#!${this.delteReturnPath}`);
            }).catch((error) => {
                if (error.relations) {
                    alert(LanguageManager.translate('page.generic_detail.relationError'));
                } else {
                    console.log(error);
                }
            });
    },

    __proto__: Page,
};

export const genericPageViewFactory = (page) => {
    return {
        get components() {
            return page.components.viewAdapter;
        },

        get fields() { return page.generatedFields; },

        isMediaField(fieldName) {
            const trait = Trait.for(page.manager.Entity);
            const fieldTrait = trait[fieldName];

            return fieldTrait === BlobTrait;
        },

        onViewMedia(event, scope) {
            const { field } = scope;
            const blobEntity = page.entityStore?.value?.[field.name];

            if (!blobEntity || !this.isMediaField(field.name)) {
                return;
            }

            const blob = new Blob([blobEntity.buffer], { type: blobEntity.type });

            LightboxManager.request(blob);
        },

        __proto__: defaultViewFactory(page),
    };
};

export default GenericDetailPage;

import { Page, defaultViewFactory, createView } from '../prototypes/Page.js';

export const AuthenticatingPage = {
    name: 'AuthenticatingPage',
    template: 'page-authenticating',

    __proto__: Page,
};

createView((page) => {
    return {
        __proto__: defaultViewFactory(page),
    };
}, AuthenticatingPage);

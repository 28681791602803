import {createView} from '../prototypes/Page.js';
import { ChainAccountingNumberToAccountingNumberManager } from '../managers/ChainAccountingNumberToAccountingNumber.js';
import GenericRelationListingPage, {genericRelationListingPageViewFactory} from '../prototypes/GenericRelationListingPage.js';
import { ChainAccountingNumberTrait } from 'zpp-mpr-lib/traits/models.js';

export const ChainAccountingNumberToAccountingNumberPage = {
    name: 'ChainAccountingNumberToAccountingNumberPage',
    path: '/chainaccountingnumber/{entityId}/chainaccountingnumbertocccountingnumber',
    template: 'page-chain-accounting-number-to-accounting-number',
    entityManager: ChainAccountingNumberToAccountingNumberManager,
    title: 'page.chain_accounting_number_to_accounting_number_page.title',
    useAppBar: false,
    parentEntityTrait: ChainAccountingNumberTrait,

    columns: [
        'changed',
        'accounting_number',
    ],

    init(template) {
        super.init(template, 'chain_accounting_number_to_accounting_number_detail_page');
    },

    __proto__: GenericRelationListingPage,
};

createView(genericRelationListingPageViewFactory, ChainAccountingNumberToAccountingNumberPage);

export default ChainAccountingNumberToAccountingNumberPage;

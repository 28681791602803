import {createView} from '../../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../../prototypes/GenericEditPage.js';
import {SharedTreatment, Treatment, Blob as BlobModel} from 'zpp-mpr-lib/models.js';
import { PatientTreatmentPage } from './PatientTreatment.js';
import TreatmentDetailPage from './TreatmentDetail.js';
import {StorageManager} from '../../managers/Storage.js';
import {Uuid} from 'zpp-mpr-lib/general.js';
import PatientManager from '../../managers/Patient.js';
import { PatientTrait, SharedTreatmentTrait, TreatmentTrait, ChainAccountingNumberTrait } from 'zpp-mpr-lib/traits/models.js';
import TreatmentManager from '../../managers/Treatment.js';
import { VideoFileFieldComponent } from '../../components/VideoFileField.js';
import { VoiceMemoFieldComponent } from '../../components/VoiceMemoField.js';
import { MedicalPracticeManager } from '../../managers/MedicalPractice.js';
import {getPreciseDistance} from 'geolib';
import {DataStorage} from '../../lib/DataStorage.js';
import CareFacilityManager from '../../managers/CareFacility.js';
import UserManager from '../../managers/User.js';
import LanguageManager from '../../managers/Language.js';
import { AudioRecorderManager } from '../../managers/AudioRecoder.js';
import ChainAccountingNumberManager from '../../managers/ChainAccountingNumber.js';
import ChainAccountingNumberToAccountingNumberManager from '../../managers/ChainAccountingNumberToAccountingNumber.js';
import AccessControlManager from '../../managers/AccessControl.js';
import {DateTimeFieldComponent} from '../../components/DateTimeField.js';
import Iterator from '@prograp/iterator';
import {compareAsc, startOfDay} from 'date-fns';
import {DialogManager} from '../../managers/Dialog.js';

export const EditTreatmentDetailPage = {
    name: 'EditTreatmentDetailPage',
    path: [
        '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}/edit',
        '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}/edit-{editType}',
    ],
    template: 'page-edit-treatment-detail',
    entityStore: null,
    options: null,
    chainOptions: null,
    activeSubset: null,

    get parent() {
        if (this.isNew) {
            return PatientTreatmentPage;
        }

        return TreatmentDetailPage;
    },

    subsets: {
        travelcost: [
            'accounting_number',
            'date_time',
            'start',
            'target',
        ],

        chained: [
            'chain',
            'date_time',
            'text',
        ],

        voice_memo: [
            'date',
            'text',
            'audio',
        ],

        custom: [
            'accounting_number',
            'text',
            'date_time',
            'image',
            'video',
        ],
    },

    fields: [
        'accounting_number',
        'text',
        'date_time',
        'image',
        'video',
        'audio',
        'amount',
    ],

    fieldInputTypes: {
        video: VideoFileFieldComponent,
        audio: VoiceMemoFieldComponent,
        date_time: DateTimeFieldComponent,
    },

    entityType: Treatment,

    get isShared() {
        return this.activeSubset === 'travelcost';
    },

    init(template) {
        super.init(template, 'treatment_detail_page');
    },

    onGetDefaults(params) {
        return PatientManager.getEntity(params.patientsId)
            .then(entity => {
                return {
                    patient: entity,
                    shared: this.isShared,
                    performer: AccessControlManager.user,
                    care_facility: entity.nursing_home,
                    notExported: true,
                    date_time: new Date(),
                };
            });
    },

    storeTreatment(combined, sharedTreatment) {
        const id = Uuid.new();

        return TreatmentManager.getEntiesByPatientIdAndMultiTreatment(combined.patient.id, sharedTreatment.id)
            .then((foundTreatments) => {
                if (foundTreatments.length) {
                    return;
                }

                const newTreatment = Object.assign({}, sharedTreatment, {
                    id,
                    multi_treatment: sharedTreatment,
                    patient: combined.patient,
                    performer: combined.performer,
                    care_facility: combined.patient.nursing_home,
                    text: null,
                    notExported: true
                });

                return StorageManager.storeChanges(id, newTreatment, TreatmentTrait);
            });
    },

    creatNewSharedTreatment(combined, changes) {
        const id = Uuid.new();

        const shared = {
            care_facility: combined.patient.nursing_home,
            id,
            text: combined.patient.nursing_home.id + combined.date_time + combined.accounting_number.id,
            amount: combined.amount,
            performer: combined.performer,
            accounting_number: combined.accounting_number,
            date_time: combined.date_time
        };

        changes.multi_treatment = shared;

        return StorageManager.storeChanges(id, shared, SharedTreatmentTrait);
    },

    storeChanges(baseVersion, changes) {
        const combined = this.entityStore.value;

        this.updateAmount(combined, changes);

        const groupBy = (list, key) => list.reduce((accumulator, value) => {
            (accumulator[value[key]] = accumulator[value[key]] || []).push(value);

            return accumulator;
        }, {});

        const start = startOfDay(combined.date_time);

        return StorageManager.query(SharedTreatment, true)
            .where('care_facility+date_time')
            .from([`care_facility/${combined.patient.nursing_home.id}`, start])
            .to([`care_facility/${combined.patient.nursing_home.id}`, combined.date_time])
            .sort('DESC')
            .get()
            .then((shared) => {
                if (!shared.length) {
                    return shared;
                }

                const grouped = groupBy(shared, 'accounting_number');

                combined.text = null;

                const treatments = Iterator.fromObject(grouped).map(([, value]) => {
                    const sharedTreatment = value.sort(compareAsc)[0];

                    return this.storeTreatment(combined, sharedTreatment);
                });

                return Promise.all(treatments).then(() => shared);
            })
            .then((shared) => {
                if (!combined.shared || !combined.accounting_number || this.chainData) {
                    return shared;
                }

                return this.creatNewSharedTreatment(combined, changes);
            })
            .then(() => {
                this.updatePatient(combined);

                if (this.chainData) {
                    return this.createChain(combined);
                }

                return super.storeChanges(baseVersion, changes);
            });
    },

    updatePatient(treatment) {
        treatment.patient.last_treatment_date_time = treatment.date_time;

        return StorageManager.storeChanges(treatment.patient.id, {last_treatment_date_time: treatment.date_time}, PatientTrait);
    },

    createChain(combined) {
        return ChainAccountingNumberToAccountingNumberManager.getEntitiesForRelatedEntity(ChainAccountingNumberTrait, this.chainData.id, 0, null, null)
            .then((chainAccountingNumberToAccountingNumbers) => {
                return Promise.all(chainAccountingNumberToAccountingNumbers.map((chainAccountingNumberToAccountingNumber) => {
                    const id = Uuid.new();

                    const shared = {
                        text: combined.text,
                        patient: combined.patient,
                        amount: combined.amount,
                        performer: combined.performer,
                        accounting_number: chainAccountingNumberToAccountingNumber.accounting_number,
                        care_facility: combined.patient.nursing_home,
                        notExported: true,
                        date_time: combined.date_time,
                        teeth: chainAccountingNumberToAccountingNumber.accounting_number.needs_teeth ? combined.teeth : ''
                    };

                    return StorageManager.storeChanges(id, shared, TreatmentTrait);
                }));
            });
    },

    get saveReturnPath() {
        if (this.chainData) {
            const currentPath = location.hash.split('/');

            const targetPath = currentPath.slice(1, -2).join('/');

            return `/${targetPath}`;
        }

        const currentPath = location.hash.split('/');
        let targetPath = currentPath.slice(1, -1).join('/');

        if (this.isNew) {
            targetPath = targetPath.replace(/\/new(\/|$)/, `/${this.entityStore.value.id}$1`);
        }

        return `/${targetPath}`;
    },

    updateAmount(combined, changes) {
        if (!this.startData || !this.targetData) {
            return;
        }

        if (!this.startData.lat || !this.startData.longitude
            || !this.targetData.lat || !this.targetData.longitude) {
            alert(LanguageManager.translate('page.treatment_detail_page.missing_lat_long'));

            return;
        }

        const distance = getPreciseDistance({ latitude: Number.parseFloat(this.startData.lat), longitude: Number.parseFloat(this.startData.longitude) },
            { latitude: Number.parseFloat(this.targetData.lat), longitude: Number.parseFloat(this.targetData.longitude) });

        combined.amount = distance;
        changes.amount = distance;
    },

    autoStartVoiceMemo() {
        AudioRecorderManager.requestRecording()
            .then(blob => Promise.all([blob.arrayBuffer(), `recording_${Date.now()}.mp3`, blob.type]))
            .then(([buffer, name, type]) => {
                const entity = this.entityStore.value;

                entity.audio = BlobModel.new(name, type, buffer);
                this.entityStore.fill(entity);
            });
    },

    onRouteEnter(path, params) {
        super.onRouteEnter(path, params);

        this.options = DataStorage.new();
        this.options.when(this.scope.update);
        this.chainOptions = DataStorage.new();
        this.chainOptions.when(this.scope.update);

        this.startData = null;
        this.targetData = null;
        this.chainData = null;
        this.activeSubset = params.editType ?? null;

        if (params.editType === 'voice_memo') {
            this.autoStartVoiceMemo();
        }

        this.updateOptions();
    },

    updateOptions() {
        CareFacilityManager.getEntities()
            .then((list) => {
                return MedicalPracticeManager.getEntities().then((second) => list.concat(second));
            })
            .then((list) => {
                return UserManager.getEntities().then((second) => list.concat(second));
            }).then(list => this.options.fill(list));

        ChainAccountingNumberManager.getEntities()
            .then(list => this.chainOptions.fill(list));
    },

    changed(fieldName, newValue) {
        if (fieldName !== 'accounting_number') {
            return Promise.resolve();
        }

        if (newValue.needs_teeth) {
            return DialogManager.show({
                template: 'teeth-dialog',
                title: LanguageManager.translate('page.treatment_detail_page.teeth_dialog'),
                scrimAction: '',
                actions: [
                    { label: this.l10n(this.strings.cancelDialog), name: 'continue' },
                ],
                data: {
                    l10n(key) {
                        if (Array.isArray(key)) {
                            return LanguageManager.translate(...key);
                        }

                        return LanguageManager.translate(key);
                    },

                    value: this.entityStore.value.teeth,

                    strings: { continue: 'page.patient_detail_page.continue', teeth: 'page.patient_detail_page.teeth'}
                }
            }).then((result) => {
                this.entityStore.value.teeth = result.data.value;
            });
        }

        return Promise.resolve();
    },

    __proto__: GenericEditPage,
};

createView((page) => {
    return {
        get options() {
            return page.options?.value ?? [];
        },

        get chainOptions() {
            return page.chainOptions?.value ?? [];
        },

        get startValue() {
            return page.startData?.id;
        },

        get targetValue() {
            return page.targetData?.id;
        },

        get chainValue() {
            return page.chainData?.id;
        },

        get showTeeth() {
            return this.entityStore?.value.accounting_number?.needs_teeth;
        },

        set startValue(value) {
            const option = this.options.find(item => item.id === value);

            if (!option) {
                page.scope.update();

                return;
            }

            page.startData = option;
        },

        set targetValue(value) {
            const option = this.options.find(item => item.id === value);

            if (!option) {
                page.scope.update();

                return;
            }

            page.targetData = option;
        },

        set chainValue(value) {
            const option = this.chainOptions.find(item => item.id === value);

            if (!option) {
                page.scope.update();

                return;
            }

            page.chainData = option;
        },

        isStartSelected(id) {
            return this.startValue === id;
        },

        isTargetSelected(id) {
            return this.targetValue === id;
        },

        isChainSelected(id) {
            return this.chainValue === id;
        },

        get hasStart() {
            return page.subsets[page.activeSubset]?.includes('start') ?? true;
        },

        get hasTarget() {
            return page.subsets[page.activeSubset]?.includes('target') ?? true;
        },

        get hasChain() {
            return page.subsets[page.activeSubset]?.includes('chain') ?? true;
        },

        get fields() {
            if (!page.activeSubset) {
                return page.fieldViewAdapter;
            }

            return page.fieldViewAdapter?.filter(field =>
                page.subsets[page.activeSubset].includes(`${field.name.replace('_field', '')}`)
            );
        },

        __proto__: genericEditPageViewFactory(page)
    };
}, EditTreatmentDetailPage);

export default EditTreatmentDetailPage;

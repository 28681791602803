import EntityManager from './Entity.js';
import { UserGroup } from 'zpp-mpr-lib/models.js';

/**
 * @mixes EntityManager
 */
export const UserGroupManager = {
    Entity: UserGroup,
    Sorter: 'name',

    __proto__: EntityManager,
};

export default UserGroupManager;

import { Router } from '@af-modules/router';
import Iterator from '@prograp/iterator';
import { Uuid } from 'zpp-mpr-lib/general.js';
import { Trait, EnumTrait } from 'zpp-mpr-lib/traits.js';
import { BlobTrait } from 'zpp-mpr-lib/traits/models.js';

import { Page, defaultViewFactory } from './Page.js';
import { ComponentHost } from './ComponentHost.js';
import { AppBarComponent } from '../components/AppBar.js';
import { Events as FloatingActionEvents } from '../managers/FloatingAction.js';
import { FloatingActionFeature } from '../lib/FloatingActionFeature.js';
import { DataStorage } from '../lib/DataStorage.js';
import { StorageManager } from '../managers/Storage.js';
import { RelationSelectFieldComponent } from '../components/RelationSelectField.js';
import { EnumSelectFieldComponent } from '../components/EnumSelectField.js';
import { NumberFieldComponent } from '../components/NumberField.js';
import { TextFieldComponent } from '../components/TextField.js';
import { SwitchFieldComponent } from '../components/SwitchField.js';
import { DateFieldComponent } from '../components/DateField.js';
import { ImageFileFieldComponent } from '../components/ImageFileField.js';

const typeToFieldMap = new Map([
    [String, TextFieldComponent],
    [Number, NumberFieldComponent],
    [Trait, RelationSelectFieldComponent],
    [EnumTrait, EnumSelectFieldComponent],
    [Array, TextFieldComponent],
    [Boolean, SwitchFieldComponent],
    [Date, DateFieldComponent],
    [BlobTrait, ImageFileFieldComponent],
]);

const getEntityFields = function(entityType, host) {
    const trait = Trait.for(entityType);

    const fieldsConfig = Iterator.new(host.fields).map(fieldName => {
        const propertyType = getPropertyType(fieldName, trait);
        const fieldType = host.fieldInputTypes[fieldName] ?? typeToFieldMap.get(propertyType);
        const storage = host.entityStore;

        return [`${fieldName}_field`, {
            prototype: fieldType,
            config: {
                get data() {
                    return storage.value?.[fieldName];
                },
                set data(value) {
                    if (value === storage.value[fieldName]) {
                        return;
                    }

                    host.changed?.(fieldName, value, storage.value[fieldName]);
                    storage.value[fieldName] = value;
                },

                error: null,
                label: host.strings[`${fieldName}_label`],
                propertyType: trait[fieldName] ?? String,
                storage
            }
        }];
    }).intoMap();

    const fieldViewAdapter = Iterator.new(fieldsConfig)
        .map(([name, value]) => {
            return {
                name,
                template: value.prototype.template,
            };
        }).intoArray();

    return { fieldComponentConfig: Object.fromEntries(fieldsConfig), fieldViewAdapter };
};

const getPropertyType = function(property, trait) {
    const propertyType = trait[property] ?? String;

    if (propertyType[EnumTrait]) {
        return EnumTrait;
    }

    if (!typeToFieldMap.has(propertyType) && typeof propertyType !== 'function') {
        return Trait;
    }

    return propertyType;
};

export const GenericEditPage = {
    fields: [],

    floatingActionFeature: null,
    entityStore: null,
    routeParams: null,
    fieldInputTypes: {},

    /**
     * @type {Array.<{ name: string, template: string }>}
     */
    fieldViewAdapter: null,

    get isNew() {
        return this.routeParams?.entityId === 'new';
    },

    get saveReturnPath() {
        const currentPath = location.hash.split('/');
        let targetPath = currentPath.slice(1, -1).join('/');

        if (this.isNew) {
            targetPath = targetPath.replace(/\/new(\/|$)/, `/${this.entityStore.value.id}$1`);
        }

        return `/${targetPath}`;
    },

    init(template, pageTranslationKey = null) {
        super.init(template, pageTranslationKey);

        this.entityStore = DataStorage.new();
        this.floatingActionFeature = FloatingActionFeature({ label: this.strings.save, icon: 'save', extended: true }, this);

        const { fieldViewAdapter, fieldComponentConfig } = getEntityFields(this.entityType, this);

        this.fieldViewAdapter = fieldViewAdapter;

        this.components = ComponentHost.new({
            appBar: {
                prototype: AppBarComponent,
                config: {
                    title: this.strings.title,
                    host: this,
                }
            },

            ...fieldComponentConfig,
        });
    },

    onRouteEnter(path, params) {
        if (!params.entityId) {
            throw new ReferenceError('url parameter entityId is missing or undefined');
        }

        super.onRouteEnter();
        this.routeParams = params;
        this.floatingActionFeature.claim();

        if (this.isNew) {
            return this.onCreateEmpty(params);
        }

        StorageManager.queryEntityById(this.entityType, params.entityId)
            .then(entity => {
                this.entityStore.fill(entity.createWorkingCopy());
            });
    },

    onCreateEmpty(params) {
        return this.onGetDefaults(params)
            .then(defaults => {
                this.defaults = defaults;
                const newEntity = this.entityType.fromObject(Object.assign({ id: Uuid.new()}, defaults));

                this.entityStore.fill(newEntity.createWorkingCopy());

                return this.entityStore;
            });
    },

    defaults: null,

    onRouteLeave() {
        super.onRouteLeave();
        this.floatingActionFeature.release();
    },

    isEmptyValue(value) {
        return value === null && value === undefined;
    },

    // eslint-disable-next-line no-unused-vars
    onGetDefaults(params) {
        return Promise.resolve({});
    },

    saveChanges() {
        const { value } = this.entityStore;
        const baseVersion = Object.getPrototypeOf(value);

        const pendingProperties = Iterator.fromObject(value)
            .map(([key, value]) => Promise.resolve(value).then(value => [key, value]));

        return Promise.all(pendingProperties)
            .then((properties) => {
                let changes = Iterator.new(properties)
                    .filter(([key, value]) => !this.isEmptyValue(value) && value !== baseVersion[key])
                    .intoObject();

                changes = Object.assign({}, this.defaults, changes);

                // bail if there are no changes
                if (Object.keys(changes).length === 0) {
                    return Promise.resolve();
                }

                return this.storeChanges(baseVersion, changes);
            });
    },

    storeChanges(baseVersion, changes) {
        const trait = Trait.for(this.entityType);

        const pendingBlobs = Iterator.fromObject(trait)
            .filter(([, propTrait]) => propTrait === BlobTrait)
            .map(([property]) => {
                const blob = changes[property];

                if (blob === undefined || blob === null) {
                    return ;
                }

                return StorageManager.storeChanges(blob.id, blob, BlobTrait);
            });

        return Promise.all([StorageManager.storeChanges(baseVersion.id, changes, trait), ...pendingBlobs]);
    },

    [FloatingActionEvents.Action]() {
        this.saveChanges()
            .then(() => {
                Router.switchTo(this.saveReturnPath);
            });
    },

    __proto__: Page,
};

export const genericEditPageViewFactory = (page) => {
    return {
        get components() {
            return page.components.viewAdapter;
        },

        localPassword: '',

        get fields() { return page.fieldViewAdapter; },
        get fieldsConfig() { return page.fieldComponentConfig; },

        get notReadyToSave() {
            return !!page.entityStore.value && Object.keys(page.entityStore.value).length === 0;
        },

        __proto__: defaultViewFactory(page),
    };
};

export default GenericEditPage;

import Iterator from '@prograp/iterator';

import { CustomElementDataBinding, CustomElementDataBindingMeta, pView, pMeta } from '../../prototypes/CustomElementDataBinding.js';
import defineCustomElement from '../../lib/defineCustomElement.js';
import template from './template.js';

const MwcCardMeta = {
    name: 'app-mwc-card',
    template,

    attributes: {
        outlined: {
            type: 'boolean',
            reflectChanges: true,
        },
    },

    get object() { return MwcCard; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = MwcCardMeta;

const createView = function(element) {
    return {
        slotIterator: Iterator.new(element.children),

        hasSlotted(slotName) {
            return this.slotIterator
                .filter(child => child.getAttribute('slot') === slotName)
                .some(child => child);
        },

        get isOutlined() {
            return element.outlined;
        },

        get hasActions() {
            return this.hasActionButtons || this.hasActionIcons;
        },

        get hasActionIcons() {
            return this.hasSlotted('action-icon');
        },

        get hasActionButtons() {
            return this.hasSlotted('action');
        },

        get hasAvatarLeft() {
            return this.hasSlotted('avatar-left');
        },

        get hasAvatarRight() {
            return this.hasSlotted('avatar-right');
        },

        get hasIcon() {
            return this.hasSlotted('icon');
        },

        get hasOverline() {
            return this.hasSlotted('overline');
        },

        get hasHeadline4() {
            return this.hasSlotted('headline-4');
        },

        get hasHeadline5() {
            return this.hasSlotted('headline-5');
        },

        get hasHeadline6() {
            return this.hasSlotted('headline-6');
        },

        get hasBody2() {
            return this.hasSlotted('body-2');
        },

        get hasCaption() {
            return this.hasSlotted('caption');
        },

        get hasHeader() {
            return this.hasAvatarLeft || this.hasAvatarRight ||
                this.hasIcon || this.hasHeaderText;
        },

        get hasHeaderText() {
            return this.hasHeadline4 || this.hasHeadline5 || this.hasHeadline6;
        },

        get hasContent() {
            return this.hasSlotted('') || this.hasSlotted(null);
        },

        get withPrimaryAction() {
            return element.primaryAction;
        },

        get withoutPrimaryAction() {
            return !this.withPrimaryAction;
        }
    };
};

const MwcCard = {
    [pMeta]: meta,

    get primaryAction() {
        return this.hasAttribute('primary-action');
    },

    set primaryAction(value) {
        value ? this.setAttribute('primary-action', '') : this.removeAttribute('primary-action');
    },

    [meta.symbols.create]() {
        this[pView] = createView(this);
        super[meta.symbols.create]();
    },

    attributeChangedCallback(attribute, oldValue, newValue) {
        if (oldValue === newValue) {
            return;
        }

        super.attributeChangedCallback(attribute, oldValue, newValue);
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(meta);

export { MwcCard, MwcCardMeta };

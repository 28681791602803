import {Router} from '@af-modules/router';

import {createView} from '../prototypes/Page.js';
import { GenericListingPage, genericListingPageViewFactory } from '../prototypes/GenericListingPage.js';
import { CareFacilityManager } from '../managers/CareFacility.js';
import {Events as FloatingActionEvents} from '../managers/FloatingAction.js';
import {TransitionTypes, ViewTransitionManager} from '../managers/ViewTransition.js';

export const CareFacilityPage = {
    name: 'CareFacilityPage',
    path: '/carefacility',
    template: 'page-care-facility',
    entityManager: CareFacilityManager,
    icon: 'night_shelter',
    title: 'page.care_facility_page.title',

    columns: [
        'name',
        'street',
        'house_number',
        'zip',
        'city',
    ],

    init(template) {
        super.init(template, 'care_facility_detail_page');
    },

    [FloatingActionEvents.Action](event) {
        const currentPath = Router.getCurrentPath().slice(1).join('/');

        Router.switchTo(`/${currentPath}/new/details/edit`);

        ViewTransitionManager.enter(event.currentTarget, TransitionTypes.FabToPage);
    },

    __proto__: GenericListingPage,
};

createView(genericListingPageViewFactory, CareFacilityPage);

export default CareFacilityPage;

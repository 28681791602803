import {createView} from '../../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../../prototypes/GenericEditPage.js';
import {InsuranceScan} from 'zpp-mpr-lib/models.js';
import PatientManager from '../../managers/Patient.js';
import PatientInsuranceScanPage from './PatientInsuranceScan.js';
import InsuranceScanDetailPage from './InsuranceScanDetail.js';
import {StorageManager} from '../../managers/Storage.js';
import {PatientTrait} from 'zpp-mpr-lib/lib/traits/models/Patient.js';

export const EditInsuranceScanDetailPage = {
    name: 'EditInsuranceScanDetailPage',
    path: [
        '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}/edit',
    ],
    template: 'page-edit-insurancescan-detail',

    get parent() {
        if (this.isNew) {
            return PatientInsuranceScanPage;
        }

        return InsuranceScanDetailPage;
    },

    fields: [
        'date_time',
    ],

    entityType: InsuranceScan,

    init(template) {
        super.init(template, 'insurancescan_detail_page');
    },

    onGetDefaults(params) {
        return PatientManager.getEntity(params.patientsId)
            .then(entity => {
                return {
                    patient: entity,
                    date_time: new Date(),
                };
            });
    },

    storeChanges(baseVersion, changes) {
        return Promise.all([
            super.storeChanges(baseVersion, changes),
            this.updatePatient(this.entityStore.value)]
        ).then(([, stored]) => stored);
    },

    updatePatient(insuranceScan) {
        return StorageManager.storeChanges(insuranceScan.patient.id, {last_insurance_scan_date_time: insuranceScan.date_time}, PatientTrait);
    },

    __proto__: GenericEditPage,
};

createView(genericEditPageViewFactory, EditInsuranceScanDetailPage);

export default EditInsuranceScanDetailPage;

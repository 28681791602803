import { InsuranceScan } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';

/**
 * @mixes EntityManager
 */
export const InsuranceScanManager = {
    Entity: InsuranceScan,
    Sorter: 'date_time',

    __proto__: EntityManager
};

export default InsuranceScanManager;

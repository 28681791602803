import {createView} from '../prototypes/Page.js';
import { GenericListingPage, genericListingPageViewFactory } from '../prototypes/GenericListingPage.js';
import { ChainAccountingNumberManager } from '../managers/ChainAccountingNumber.js';
import {Events as FloatingActionEvents} from '../managers/FloatingAction.js';
import {Router} from '@af-modules/router';
import {TransitionTypes, ViewTransitionManager} from '../managers/ViewTransition.js';

export const ChainAccountingNumberPage = {
    name: 'ChainAccountingNumberPage',
    path: '/chainaccountingnumber',
    template: 'page-chain-accounting-number',
    entityManager: ChainAccountingNumberManager,
    icon: 'link',
    title: 'page.chain_accounting_number_page.title',

    columns: [
        'name',
    ],

    init(template) {
        super.init(template, 'chain_accounting_number_detail_page');
    },

    [FloatingActionEvents.Action](event) {
        const currentPath = Router.getCurrentPath().slice(1).join('/');

        Router.switchTo(`/${currentPath}/new/details/edit`);

        ViewTransitionManager.enter(event.currentTarget, TransitionTypes.FabToPage);
    },

    __proto__: GenericListingPage,
};

createView(genericListingPageViewFactory, ChainAccountingNumberPage);

export default ChainAccountingNumberPage;

import { DataBinding } from '@af-modules/databinding';
import { Iterator } from '@prograp/iterator';
import { CssContext } from '../../lib/CssContext.js';
import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView, pScope } from '../../prototypes/CustomElementDataBinding.js';

import defineCustomElement from '../../lib/defineCustomElement.js';
import template from './template.js';

const createView = function(element) {
    return {
        wasOpen: false,

        get isOpen() {
            return element.open;
        },

        get isClosed() {
            return !this.isOpen;
        },

        get shouldClose() {
            return this.isClosed && this.wasOpen;
        },

        get icon() {
            return this.isOpen ? 'close' : 'edit';
        },

        get actions() {
            return Iterator.new(element.children).filter(child => child.matches(':not([slot])')).intoArray();
        },

        onOpen() {
            element.open = !element.open;
            this.wasOpen = true;
        },

        onCloseViaAction(event) {
            if (event.target === event.currentTarget) {
                return;
            }

            this.onOpen();

            return;
        },

        animateActionEnter(element) {
            const cssContext = CssContext.new(element);
            const { cssVar } = cssContext;
            const keyframes = [
                { visibility: 'visible', opacity: cssVar('--action-exited-opacity'), transform: cssVar('--action-exited-scale') },
                { opacity: cssVar('--action-entered-opacity'), offset: cssVar('--action-animation-opacity-offset') },
                { visibility: 'visible', opacity: cssVar('--action-entered-opacity'), transform: cssVar('--action-entered-scale') },
            ];

            const elements = this.actions.map((element, index) => {
                const keyframesConfig = this.getEnterExitAnimationConfig(index, cssContext);

                return element.animate(keyframes, keyframesConfig).finished;
            });

            return Promise.all(elements);
        },

        animateActionLeave(element) {
            const cssContext = CssContext.new(element);
            const { cssVar } = cssContext;
            const keyframes = [
                { visibility: 'visible', opacity: cssVar('--action-entered-opacity'), transform: cssVar('--action-entered-scale') },
                { opacity: cssVar('--action-exited-opacity'), offset: cssVar('--action-animation-opacity-offset') },
                { visibility: 'visible', opacity: cssVar('--action-exited-opacity'), transform: cssVar('--action-exited-scale') },
            ];

            const elements = this.actions.map((element, index, { length }) => {
                const keyframesConfig = this.getEnterExitAnimationConfig(length - index - 1, cssContext);

                return element.animate(keyframes, keyframesConfig).finished;
            });

            return Promise.all(elements);
        },

        getEnterExitAnimationConfig(index, { cssVar }) {
            return {
                id: 'action-animation-opacity',
                easing: cssVar('--action-animation-easing'),
                duration: cssVar('--action-animation-duration', Number),
                delay: cssVar('--action-animation-delay', Number) * index,
                fill: 'both',
            };
        },
    };
};

const AppSpeedDialMeta = {
    name: 'app-speed-dial',
    template,

    attributes: {
        open: {
            type: 'boolean',
            reflectChanges: true,
        },
    },

    get object() { return AppSpeedDial; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = AppSpeedDialMeta;

const AppSpeedDial = {

    /**
     * @type {boolean}
     */
    open: null,

    [pMeta]: meta,

    [meta.symbols.create]() {
        this[pView] = createView(this);
        super[meta.symbols.create]();

        DataBinding.attachBindings(this[pScope], this, [
            { selector: 'root', name: 'bind-animation', value: '{ view.isOpen: view.animateActionEnter, view.shouldClose: view.animateActionLeave }' }
        ]);
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(meta);

export { AppSpeedDial, AppSpeedDialMeta };

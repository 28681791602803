import Application from 'application-frame/core/Application.js';
import { Router } from '@af-modules/router';
import { Iterator } from '@prograp/iterator';

import { SiteMetadataManager } from './managers/SiteMetadata.js';
import { DialogManager } from './managers/Dialog.js';
import { LanguageManager } from './managers/Language.js';
import { ConnectionManager } from './managers/Connection.js';
import { LightboxManager } from './managers/Lightbox.js';
import { SetupPage } from './pages/Setup.js';
import { SyncTestPage } from './pages/SyncTest.js';
import { PreferencesPage } from './pages/Preferences.js';
import { PermissionPreferencesPage } from './pages/preferences/PermissionPreferences.js';
import { UserPreferencesPage } from './pages/preferences/UserPreferences.js';
import { SyncManager } from './managers/Sync.js';
import { StorageManager } from './managers/Storage.js';
import { UserManager } from './managers/User.js';
import { AccountingNumberManager } from './managers/AccountingNumber.js';
import { ChainAccountingNumberManager } from './managers/ChainAccountingNumber.js';
import { ChainAccountingNumberToAccountingNumberManager } from './managers/ChainAccountingNumberToAccountingNumber.js';
import { UserGroupManager } from './managers/UserGroup.js';
import { InsuranceScanManager } from './managers/InsuranceScan.js';
import { MessageRoutingManager } from './managers/MessageRouting.js';
import { RegisterManager } from './managers/Register.js';
import { ViewTransitionManager } from './managers/ViewTransition.js';
import { NavigationManager } from './managers/Navigation.js';
import { ManagerConfig } from './prototypes/ManagerConfig.js';
import { FloatingActionManager } from './managers/FloatingAction.js';
import { AccessControlManager } from './managers/AccessControl.js';
import { UserDetailPreferencesPage } from './pages/preferences/UserDetailPerferences.js';
import EventSubscriberFeature from './lib/EventSubscriberFeature.js';
import { GeoCoderPage } from './pages/GeoCoder.js';
import { PatientDetailPage } from './pages/PatientDetail.js';
import { EditUserDetailPreferencesPage } from './pages/preferences/EditUserDetailPreferences.js';
import EditPatientDetailPage from './pages/EditPatientDetail.js';
import PatientManager from './managers/Patient.js';
import CareFacilityManager from './managers/CareFacility.js';
import ChainAccountingNumberPage from './pages/ChainAccountingNumber.js';
import ChainAccountingNumberDetailPage from './pages/ChainAccountingNumberDetail.js';
import ChainEditAccountingNumberDetailPage from './pages/EditChainAccountingNumberDetail.js';
import TreatmentManager from './managers/Treatment.js';
import SharedTreatmentManager from './managers/SharedTreatment.js';
import { CareFacilityPage } from './pages/CareFacility.js';
import { MedicalPracticePage } from './pages/MedicalPractice.js';
import { CareFacilityTabsPage } from './pages/CareFacilityTabs.js';
import CareFacilityDetailPage from './pages/CareFacilityDetail.js';
import { CareFacilityPatientsPage } from './pages/CareFacilityPatients.js';
import EditCareFacilityDetailPage from './pages/EditCareFacilityDetail.js';
import MedicalPracticeManager from './managers/MedicalPractice.js';
import AudioRecorderManager from './managers/AudioRecoder.js';
import MedicalPracticeDetailPage from './pages/MedicalPracticeDetail.js';
import EditMedicalPracticeDetailPage from './pages/EditMedicalPracticeDetail.js';
import {LoginPage} from './pages/Login.js';
import AccountingNumberPage from './pages/AccountingNumber.js';
import AccountingNumberDetailPage from './pages/AccountingNumberDetail.js';
import EditAccountingNumberDetailPage from './pages/EditAccountingNumberDetail.js';
import TreatmentDetailPage from './pages/Treatment/TreatmentDetail.js';
import EditTreatmentDetailPage from './pages/Treatment/EditTreatmentDetail.js';
import PatientTreatmentPage from './pages/Treatment/PatientTreatment.js';
import PatientInsuranceScanPage from './pages/InsuranceScan/PatientInsuranceScan.js';
import InsuranceScanDetailPage from './pages/InsuranceScan/InsuranceScanDetail.js';
import EditInsuranceScanDetailPage from './pages/InsuranceScan/EditInsuranceScanDetail.js';
import PatientTabsPage from './pages/PatientTabs.js';
import ChainAccountingNumberTabsPage from './pages/ChainAccountingNumberTabs.js';
import ChainAccountingNumberToAccountingNumberPage from './pages/ChainAccountingNumberToAccountingNumber.js';
import ChainAccountingNumberToAccountingNumberDetailPage from './pages/ChainAccountingNumberToAccountingNumberDetail.js';
import EditChainAccountingNumberToAccountingNumberDetailPage from './pages/EditChainAccountingNumberToAccountingNumberDetail.js';
import { ExportPreferencesPage } from './pages/preferences/ExportPreferences.js';

export const App = {
    managers: {
        SiteMetadataManager,
        LanguageManager,
        DialogManager,
        LightboxManager,
        ConnectionManager,
        MessageRoutingManager,
        StorageManager,
        SyncManager,
        RegisterManager,
        ViewTransitionManager,
        FloatingActionManager,
        AudioRecorderManager,
        UserManager,
        UserGroupManager,
        PatientManager,
        CareFacilityManager,
        TreatmentManager,
        MedicalPracticeManager,
        AccessControlManager,
        AccountingNumberManager,
        ChainAccountingNumberManager,
        ChainAccountingNumberToAccountingNumberManager,
        NavigationManager,
        InsuranceScanManager,
        SharedTreatmentManager,
    },

    routes: [
        SetupPage,
        SyncTestPage,
        PreferencesPage,
        PermissionPreferencesPage,
        UserPreferencesPage,
        UserDetailPreferencesPage,
        GeoCoderPage,
        EditUserDetailPreferencesPage,
        PatientDetailPage,
        TreatmentDetailPage,
        EditTreatmentDetailPage,
        PatientTreatmentPage,
        PatientInsuranceScanPage,
        InsuranceScanDetailPage,
        EditInsuranceScanDetailPage,
        PatientTabsPage,
        EditPatientDetailPage,
        CareFacilityPage,
        CareFacilityTabsPage,
        CareFacilityPatientsPage,
        CareFacilityDetailPage,
        AccountingNumberPage,
        AccountingNumberDetailPage,
        EditAccountingNumberDetailPage,
        ChainAccountingNumberPage,
        ChainAccountingNumberTabsPage,
        ChainAccountingNumberDetailPage,
        ChainAccountingNumberToAccountingNumberPage,
        ChainAccountingNumberToAccountingNumberDetailPage,
        EditChainAccountingNumberToAccountingNumberDetailPage,
        ChainEditAccountingNumberDetailPage,
        EditCareFacilityDetailPage,
        MedicalPracticeDetailPage,
        MedicalPracticePage,
        EditMedicalPracticeDetailPage,
        LoginPage,
        ExportPreferencesPage,
    ],

    navigation: [
        CareFacilityPage,
        MedicalPracticePage,
        AccountingNumberPage,
        ChainAccountingNumberPage,
        PreferencesPage,
    ],

    init() {
        this.constructor();

        const config = ManagerConfig.new(this, {});

        Iterator.fromObject(this.managers)
            .forEach(([, manager]) => manager.init(config.getConfig(manager.configName)));

        Router.init();
        AccessControlManager.mountInitialRoutes();

        EventSubscriberFeature([ConnectionManager, RegisterManager], this);

        if (RegisterManager.isRegistered) {
            ConnectionManager.connect();
        } else {
            Router.switchTo('/setup');
        }
    },

    __proto__: Application,
};

export default App;

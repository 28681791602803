import {createView} from '../prototypes/Page.js';
import GenericDetail, {genericPageViewFactory} from '../prototypes/GenericDetail.js';
import ChainAccountingNumberManager from '../managers/ChainAccountingNumber.js';
import { ChainAccountingNumberPage } from './ChainAccountingNumber.js';

export const ChainAccountingNumberDetailPage = {
    name: 'ChainAccountingNumberDetailPage',
    path: '/chainaccountingnumber/{entityId}/details',
    template: 'page-chain-accounting-number-detail',
    useAppBar: false,

    fields: [
        'name',
    ],

    parent: ChainAccountingNumberPage,
    manager: ChainAccountingNumberManager,

    __proto__: GenericDetail,
};

createView(genericPageViewFactory, ChainAccountingNumberDetailPage);

export default ChainAccountingNumberDetailPage;

import EventTarget from 'application-frame/core/EventTarget.js';
import Iterator from '@prograp/iterator';
import {EntityTraitMap, PatientTrait} from 'zpp-mpr-lib/traits/models.js';

import ConfigManager from './Config.js';
import { Events as MessageEvents, MessageRoutingManager } from './MessageRouting.js';
import { Events as ConnectionEvents, ConnectionManager} from './Connection.js';
import EventSubscriberFeature from '../lib/EventSubscriberFeature.js';
import { QueryChangesMessage, EntityUpdateMessage, EntityTransaction, EntityTransactionType } from 'zpp-mpr-lib/messages.js';
import { Uuid } from '../lib/uuid.js';
import {Events as StorageEvents, StorageManager} from './Storage.js';
import {EntityMap} from 'zpp-mpr-lib/models.js';


const pSyncTransactions = Symbol('SyncManager.syncTransactions');

export const SyncTransaction = {
    Direction: {
        Up: Symbol('SyncTransaction.Direction.Up'),
        Down: Symbol('SyncTransaction.Direction.Down'),
    },

    id: null,
    current: 0,
    total: 0,
    direction: null,

    new(id, direction) {
        return { id, direction, __proto__: this };
    }
};

export const Events = {
    TransactionChange: Symbol('SyncManager.Event.TransactionChange'),
    TransactionListChange: Symbol('SyncManager.Event.TransactionListChange'),
    SyncFinished: Symbol('SyncManager.Event.SyncFinished'),
};

export const Callbacks = {
    onDownloadDone: Symbol('SyncManager.Callbacks.onDownloadDone'),
    onAddTransaction: Symbol('SyncManager.Callbacks.onAddTransaction'),
};

const randomString = function() {
    const dict = 'abcdefghijklmnop2134567890-_$!';
    let string = '';

    while (string.length < 7) {
        string += dict[Math.round(Math.random() * (dict.length - 1))];
    }

    return string;
};

/**
 * @mixes EventTarget
 */
export const SyncManager = {
    Events,
    queryStart: null,

    [pSyncTransactions]: null,

    init() {
        this.constructor();
        this[pSyncTransactions] = new Map();

        EventSubscriberFeature([MessageRoutingManager, ConnectionManager, StorageManager], this);
    },

    queryChanges() {
        const date = ConfigManager.updated ?? new Date(0);
        const message = QueryChangesMessage.new(date);

        this.queryStart = new Date();

        ConnectionManager.send(message)
            .then(this[Callbacks.onDownloadDone].bind(this));
    },

    fullSync() {
        const fieldNames = Iterator.fromObject(PatientTrait).map(([key]) => key).intoArray();
        const user = Uuid.new();
        const uploadTransaction = SyncTransaction.new(Uuid.new(), SyncTransaction.Direction.Up);

        const updateMessages = Iterator.new(new Array(50))
            .map(() => {
                const entityId = Uuid.new();

                return Iterator.new(new Array(20))
                    .map(() => {

                        const date = new Date(Date.now() - Math.round(Math.random() * 40000));
                        const entityType = 'patient';
                        const fields = {
                            [fieldNames[Math.round(Math.random())]]: randomString(),
                        };

                        return EntityTransaction.new(entityId, entityType, user, fields, date);
                    }).intoArray();
            })
            .map(transactionList => EntityUpdateMessage.new(transactionList));

        const uploadProcess = updateMessages
            .map(message => {
                return ConnectionManager.send(message).then(() => {
                    uploadTransaction.current += 1;
                    this.emit(Events.TransactionChange, uploadTransaction);
                });
            }).intoArray();

        uploadTransaction.total = uploadProcess.length;
        this[Callbacks.onAddTransaction](uploadTransaction);

        return Promise.all(uploadProcess)
            .then(() => this.queryChanges());
    },

    upload(transaction) {
        const uploadTransaction = SyncTransaction.new(Uuid.new(), SyncTransaction.Direction.Up);

        const updateMessages =  EntityUpdateMessage.new([transaction]);

        const uploadProcess = ConnectionManager.send(updateMessages).then(() => {
            uploadTransaction.current += 1;
            this.emit(Events.TransactionChange, uploadTransaction);
        });

        uploadTransaction.total = 1;
        this[Callbacks.onAddTransaction](uploadTransaction);

        return uploadProcess;
    },

    storeEntity(transactions) {
        const message = EntityUpdateMessage.new(transactions);

        return ConnectionManager.send(message);
    },

    [MessageEvents.EntityChanged](message) {
        const { transactionId: id, transactionState: status } = message;
        const isNew = !this[pSyncTransactions].has(id);
        const transaction = this[pSyncTransactions].get(id) ?? SyncTransaction.new(id, SyncTransaction.Direction.Down);

        if (!status?.total && !status?.current) {
            throw new Error('invalid transaction status');
        }

        transaction.total = status.total;
        transaction.current = status.current;

        if (message.type === EntityTransactionType.Modify || message.type === EntityTransactionType.Restore) {
            StorageManager.store(message.entity, EntityTraitMap.get(message.entityType)).then(() => this.emit(Events.TransactionChange, transaction));
        }

        if (message.type === EntityTransactionType.Remove) {
            StorageManager.delete(message.entity, EntityTraitMap.get(message.entityType)).then(() => this.emit(Events.TransactionChange, transaction));
        }

        if (isNew) {
            return this[Callbacks.onAddTransaction](transaction);
        }
    },

    [StorageEvents.EntityChanged]() {
        if (!ConnectionManager.isConnected) {
            return ;
        }

        this.syncAll();
    },

    [ConnectionEvents.AuthSuccess]() {
        this.syncAll().then(() => this.queryChanges());
    },

    inProgress: Promise.resolve(),

    syncAll() {
        this.inProgress = this.inProgress.then(() => {
            return Promise.all(Iterator.new(EntityMap).map(([, Entity]) => {
                return StorageManager.queryHistory(Entity).get().then((history) => {
                    return Promise.all(history.map((transaction) => {
                        return this.upload(transaction).then(() => {
                            return StorageManager.deleteHistory(Entity, [transaction.entityId, transaction.date]);
                        });
                    }));
                });
            }));
        });

        return this.inProgress;
    },


    [Callbacks.onDownloadDone](status) {
        if (status.status === 200) {
            ConfigManager.updated = this.queryStart;
            this.emit(Events.SyncFinished);
        }
    },

    [Callbacks.onAddTransaction](transaction) {
        this[pSyncTransactions].set(transaction.id, transaction);

        const list = Iterator.new(this[pSyncTransactions])
            .map(([, value]) => value)
            .intoArray();

        this.emit(Events.TransactionListChange, list);
    },

    __proto__: EventTarget,
};

export default SyncManager;

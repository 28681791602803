import { createView } from '../prototypes/Component.js';
import { FieldComponent, fieldViewFactory } from './Field.js';
import { Password } from '../lib/Password.js';

export const PasswordFieldComponent = {
    name: 'PasswordFieldComponent',
    template: 'component-password-field',

    localPassword: '',

    __proto__: FieldComponent,
};

createView((component) => {
    return {

        get value() {
            return component.localPassword;
        },

        set value(value) {
            component.localPassword = value;
        },

        onCommitPassword() {
            component.config.data = Password
                .asyncFromText(component.localPassword)
                .then(password => password.toString());
        },

        __proto__: fieldViewFactory(component),
    };
}, PasswordFieldComponent);

import DataBinding from '@af-modules/databinding';
import { CustomElement, CustomElementMeta, pMeta } from './CustomElement.js';

export const CustomElementDataBindingMeta = {
    get object() { return CustomElementDataBinding; },

    __proto__: CustomElementMeta,
};

export const pView = Symbol('CustomElementDataBinding.view');
export const pScope = Symbol('CustomElementDataBinding.scope');

const meta = CustomElementDataBindingMeta;

export const CustomElementDataBinding = {
    [pMeta]: meta,
    [pScope]: null,
    [pView]: null,

    [CustomElementMeta.symbols.create]() {
        const { node, scope } = DataBinding.createTemplateInstance({
            scope: this[pView],
            template: this[pMeta].template,
        });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);
        this[pScope] = scope;
    },

    __proto__: CustomElement,
};

export { pMeta };
export default CustomElementDataBinding;

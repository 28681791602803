import {createView} from '../prototypes/Page.js';
import { GenericTabsPage, genericTabsPageViewFactory } from '../prototypes/GenericTabsPage.js';
import { CareFacilityPage } from './CareFacility.js';
import { CareFacilityDetailPage } from './CareFacilityDetail.js';
import { CareFacilityPatientsPage } from './CareFacilityPatients.js';

export const CareFacilityTabsPage = {
    name: 'CareFacilityTabsPage',
    path: '/carefacility/{entityId}',
    template: 'page-care-facility-tabs',
    title: 'page.care_facility_detail_page.title',

    tabs: [
        { title: 'page.care_facility_detail_page.detailsTab', page: CareFacilityDetailPage },
        { title: 'page.care_facility_detail_page.patientsTab', page: CareFacilityPatientsPage },
    ],

    parent: CareFacilityPage,

    __proto__: GenericTabsPage,
};

createView(genericTabsPageViewFactory, CareFacilityTabsPage);

export default CareFacilityTabsPage;

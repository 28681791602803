import EventTarget from 'application-frame/core/EventTarget.js';

import Uuid from '../lib/uuid.js';
import { MessageEnvelope, MessageType, AuthMessage, StatusType } from 'zpp-mpr-lib/messages.js';
import ConfigManager from './Config.js';
import CryptoManager from './Crypto.js';

const pSocket = Symbol('ConnectionManager.socket');
const pToken = Symbol('ConnectionManager.token');
const pSend = Symbol('ConnectionManager.send');
const pAuthenticated = Symbol('ConnectionManager.authenticated');
const pMessages = Symbol('ConnectionManager.messages');

const Callbacks = {
    onMessage: Symbol('ConnectionManager.Callbacks.onMessage'),
    onOpen: Symbol('ConnectionManager.Callbacks.onOpen'),
    onError: Symbol('ConnectionManager.Callbacks.onError'),
    onClose: Symbol('ConnectionManager.Callbacks.onClose'),
};

export const Events = {
    Message: Symbol('ConnectionManager.Events.Message'),
    AuthSuccess: Symbol('ConnectionManager.Events.AuthSuccess'),
    ConnectionWithoutAuth: Symbol('ConnectionManager.Events.ConnectionWithoutAuth'),
    AuthFailure: Symbol('ConnectionManager.Events.AuthFailure'),
    Error: Symbol('ConnectionManager.Events.Error'),
    Answer(id) { return Symbol.for(`ConnectionManager.Events.Answer:${id}`); },
};

/**
 * @mixes EventTarget
 */
export const ConnectionManager = {
    Events,

    /**
     * @type {WebSocket}
     */
    [pSocket]: null,

    [pAuthenticated]: false,

    /**
     * @type {WeakMap}
     */
    [pMessages]: new WeakMap(),

    clientId: null,

    get isSetup() {
        return !!this[pSocket];
    },

    get isConnected() {
        return this[pSocket]?.readyState === WebSocket.OPEN;
    },

    init() {
        if (!ConfigManager.clientId) {
            ConfigManager.clientId = Uuid.new();
        }

        this.clientId = ConfigManager.clientId;
        this.constructor();

        window.addEventListener('online', () => this.connect());
    },

    connect(url) {
        const host = url ? url : ConfigManager.host;
        
        if (this[pSocket]) {
            return;
        }

        this[pSocket] = new WebSocket(host);

        this[pSocket].addEventListener('message', this[Callbacks.onMessage].bind(this));
        this[pSocket].addEventListener('open', this[Callbacks.onOpen].bind(this));
        this[pSocket].addEventListener('error', this[Callbacks.onError].bind(this));
        this[pSocket].addEventListener('close', this[Callbacks.onError].bind(this));
    },

    once(event, listener) {
        const wrapper = (...args) => {
            this.removeListener(event, wrapper);

            return listener(...args);
        };

        return this.on(event, wrapper);
    },

    send(message) {
        return this[pSend](message, MessageType.Request);
    },

    answer(message, request) {
        return this[pSend](message, MessageType.Answer, this[pMessages].get(request).id);
    },

    [pSend](message, type, reference) {
        return CryptoManager.encryptMessage(message).then((message) => {
            const envelope = MessageEnvelope.new(type, message, reference);
            const data = JSON.stringify(envelope);

            return new Promise((resolve) => {
                this[pSocket].send(data);

                this.once(Events.Answer(envelope.id), resolve);
            });
        });
    },


    [Callbacks.onMessage](event) {
        try {
            const envelope = JSON.parse(event.data);

            CryptoManager.decrypt(envelope.message).then((message) => {
                if (message.name === 'entity_changed') {
                    message.entity.changed = new Date(message.entity.changed);
                }

                envelope.message = message;

                this[pMessages].set(envelope.message, envelope);

                if (envelope.type === MessageType.Answer) {
                    this.emit(Events.Answer(envelope.reference), envelope.message);

                    return this.emit(Events.Answer('all'), envelope.message);
                }

                if (envelope.type === MessageType.Request) {
                    return this.emit(Events.Message, envelope.message);
                }

                throw new Error(`unhandled message type: ${envelope.type}`);
            });
        } catch (e) {
            this.emit(Events.Error, e);
        }
    },

    [Callbacks.onOpen]() {
        if (ConfigManager.host) {
            return this.login();
        }

        this.emit(Events.ConnectionWithoutAuth);
    },

    login() {
        const auth = AuthMessage.new(this.clientId, ConfigManager.token);

        return this.send(auth)
            .then(message => {
                if (message.status !== StatusType.Success) {
                    this[pSocket] = null;
                    this[pToken] = null;

                    return this.emit(Events.AuthFailure, message);
                }

                this[pAuthenticated] = true;

                return this.emit(Events.AuthSuccess);
            });
    },

    [Callbacks.onError](event) {
        const error = event.message ? event : new Error(`unable to connect to ${this[pSocket].url}`);

        console.error(`WebSocket error: ${error}`);

        this.emit(Events.Error, error);

        this[pSocket].close();

        setTimeout(() => {
            if (navigator.onLine) {
                this.connect();
            }
        }, 10000);

        this[pSocket] = null;
    },

    [Callbacks.onClose]() {
        this[pSocket] = null;
        
        setTimeout(() => {
            if (navigator.onLine) {
                this.connect();
            }
        }, 10000);
    },

    __proto__: EventTarget,
};

export default ConnectionManager;

import '@material/mwc-button';
import '@material/mwc-icon';
import '@material/mwc-textfield';
import '@material/mwc-snackbar';
import '@material/mwc-tab-bar';
import '@material/mwc-tab';
import '@material/mwc-top-app-bar-fixed';
import '@material/mwc-icon-button';
import '@material/mwc-drawer';
import '@material/mwc-formfield';
import '@material/mwc-switch';
import '@material/mwc-list/mwc-list.js';
import '@material/mwc-list/mwc-list-item.js';
import '@material/mwc-fab';
import '@material/mwc-select';
import '@material/mwc-circular-progress';
import '@material/mwc-dialog';


import './bindings/RippleBinding.js';
import './bindings/ValidityBinding.js';
import './bindings/AccessBinding.js';

import './elements/app-mwc-card/index.js';
import './elements/app-page/index.js';
import './elements/app-auth-header/index.js';
import './elements/app-text/index.js';
import './elements/app-text-field/index.js';
import './elements/app-mwc-data-table/index.js';
import './elements/app-speed-dial/index.js';
import './elements/app-file-select/index.js';
import './elements/app-lightbox/index.js';
import './elements/app-audio-recorder/index.js';

import { DataBindingMeta } from '@af-modules/databinding';
import Application from './main.js';

DataBindingMeta.enableLogging();
Application.init();

const camelToSnake = function(string) {
    return string.replace(/([A-Z])/g, ' $1')
        .trim().split(' ').join('_').toLowerCase();
};

export const MagicTranslationKeyResolver = {

    /**
     * @type {string}
     */
    prefix: null,

    new(type, host, pageTranslationKey = null) {
        if (!host.name) {
            throw new TypeError('invalid host object! host has no name!');
        }

        const prefix = `${type}.${pageTranslationKey ?? camelToSnake(host.name)}` ;

        const instance = { prefix, __proto__: this };
        const traps = {
            get(target, propertyName) {
                return `${target.prefix}.${propertyName}`;
            }
        };

        return new Proxy(instance, traps);
    },
};

export default MagicTranslationKeyResolver;

import { SubscriberFeature } from 'application-frame/core/features/SubscriberFeature.js';
import { Router } from '@af-modules/router';
import { Trait } from 'zpp-mpr-lib/traits.js';

import { Page, defaultViewFactory, createView } from '../../prototypes/Page.js';
import { PreferencesPage } from '../Preferences.js';
import { ComponentHost } from '../../prototypes/ComponentHost.js';
import { AppBarComponent } from '../../components/AppBar.js';
import { Events as FloatingActionEvents } from '../../managers/FloatingAction.js';
import { FloatingActionFeature } from '../../lib/FloatingActionFeature.js';
import { AccessControlManager } from '../../managers/AccessControl.js';
import { ViewTransitionManager, TransitionTypes } from '../../managers/ViewTransition.js';
import { UserManager } from '../../managers/User.js';
import { DataStorage } from '../../lib/DataStorage.js';
import { StorageManager } from '../../managers/Storage.js';

export const UserPreferencesPage = {
    name: 'UserPreferencesPage',
    path: '/preferences/users',
    template: 'page-user-preferences',
    get parent() { return PreferencesPage; },

    icon: 'supervised_user_circle',

    floatingActionFeature: null,

    strings: {
        title: 'page.user_preferences.title',
        description: 'page.user_preferences.description',
    },

    userList: null,
    avatarCache: null,

    init(template) {
        super.init(template);

        const page = this;

        this.floatingActionFeature = FloatingActionFeature({
            label: 'Neuer Benutzer',
            icon: 'add',
            get visible() {
                return AccessControlManager.canVisitPath(`${page.path}/new/edit`);
            }
        }, this);
        this.userList = DataStorage.new();
        this.userList.when(this.scope.update);
        this.avatarCache = new WeakMap();

        SubscriberFeature(StorageManager, this);

        this.components = ComponentHost.new({
            appBar: {
                prototype: AppBarComponent,
                config: {
                    title: this.strings.title,
                    host: this,
                }
            }
        });
    },

    onRouteEnter() {
        super.onRouteEnter();
        this.floatingActionFeature.claim();

        UserManager.getEntities().then(list => this.userList.fill(list));
    },

    onRouteLeave() {
        super.onRouteLeave();
        this.floatingActionFeature.release();
    },

    [FloatingActionEvents.Action](event) {
        Router.switchTo(`/${Router.getCurrentPath().slice(1).join('/')}/new/edit`);

        ViewTransitionManager.enter(event.currentTarget, TransitionTypes.FabToPage);
    },


    [StorageManager.Events.EntityChanged](event) {
        if (!this.active) {
            return;
        }

        if (event.trait !== Trait.for(UserManager.Entity)) {
            return;
        }

        UserManager.getEntities()
            .then(users => this.userList.fill(users));
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get components() {
            return page.components.viewAdapter;
        },

        get entries() {
            return page.userList?.value ?? [];
        },

        itemLink(item) {
            return `#!/preferences/users/${item.id}`;
        },

        onStartTransition(event) {
            ViewTransitionManager.enter(event.currentTarget, TransitionTypes.CardToPage);
        },

        __proto__: defaultViewFactory(page),
    };
}, UserPreferencesPage);

export default UserPreferencesPage;

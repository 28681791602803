import EventTarget from 'application-frame/core/EventTarget.js';

import EventSubscriberFeature from '../lib/EventSubscriberFeature.js';
import { StatusMessage, EntityChangedMessage, RegisterRequestMessage, RegisterResponseMessage, Message } from 'zpp-mpr-lib/messages.js';
import ConnectionManager from './Connection.js';

export const Events = {
    EntityChanged: Symbol('MessageRoutingManager.Events.EntityChanged'),
    RegisterRequest: Symbol('MessageRoutingManager.Events.RegisterRequest'),
    RegisterResponse: Symbol('MessageRoutingManager.Events.RegisterResponse'),
    StatusMessage: Symbol('MessageRoutingManager.Events.StatusMessage'),
};

const Callbacks = {
    onProcessMessage: Symbol('MessageRoutingManager.Callbacks.onProcessMessage'),
};

const eventsMapping = new Map([
    [StatusMessage.name, Events.StatusMessage],
    [EntityChangedMessage.name, Events.EntityChanged],
    [RegisterRequestMessage.name, Events.RegisterRequest],
    [RegisterResponseMessage.name, Events.RegisterResponse]
]);

/**
 * @mixes EventTarget
 */
export const MessageRoutingManager = {
    Events,

    init() {
        this.constructor();

        EventSubscriberFeature(ConnectionManager, this);
    },

    [ConnectionManager.Events.Message](message) {
        return this[Callbacks.onProcessMessage](message);
    },

    [ConnectionManager.Events.Answer('all')](message) {
        return this[Callbacks.onProcessMessage](message);
    },

    /**
     * @param {Message} message
     *
     * @return {undefined}
     */
    [Callbacks.onProcessMessage](message) {
        if (!eventsMapping.has(message.name)) {
            throw new Error(`unsupported message type: ${message.name}`);
        }

        return this.emit(eventsMapping.get(message.name), message);
    },

    __proto__: EventTarget,
};

export default MessageRoutingManager;

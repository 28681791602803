import { createView } from '../prototypes/Component.js';
import { FileFieldComponent, createFileFieldViewFactory } from './FileField.js';

export const VideoFileFieldComponent = {
    name: 'VideoFileFieldComponent',
    accept: 'video/*',

    __proto__: FileFieldComponent,
};

createView(createFileFieldViewFactory, VideoFileFieldComponent);

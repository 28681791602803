import {createView} from '../prototypes/Page.js';
import GenericDetail, {genericPageViewFactory} from '../prototypes/GenericDetail.js';
import CareFacilityManager from '../managers/CareFacility.js';
import CareFacilityPage from './CareFacility.js';

export const CareFacilityDetailPage = {
    name: 'CareFacilityDetailPage',
    path: '/carefacility/{entityId}/details',
    template: 'page-care-facility-detail',
    parent: CareFacilityPage,

    fields: [
        'name',
        'street',
        'house_number',
        'zip',
        'city',
        'country',
        'phone_numbers',
        'director_name',
        'director_phone_numbers',
        'nursing_director_name',
        'nursing_director_phone_numbers',
        'lat',
        'longitude',
    ],

    manager: CareFacilityManager,
    useAppBar: false,

    __proto__: GenericDetail,
};

createView(genericPageViewFactory, CareFacilityDetailPage);

export default CareFacilityDetailPage;

import {createView} from '../../prototypes/Page.js';
import { GenericRelationListingPage, genericRelationListingPageViewFactory } from '../../prototypes/GenericRelationListingPage.js';
import { InsuranceScan } from 'zpp-mpr-lib/models.js';
import InsuranceScanManager from '../../managers/InsuranceScan.js';
import { PatientTrait } from 'zpp-mpr-lib/traits/models.js';

export const PatientInsuranceScanPage = {
    name: 'PatientInsuranceScanPage',
    path: '/carefacility/{careFacilityId}/patients/{entityId}/insurancescan',
    template: 'page-patient-insurancescan',
    entity: InsuranceScan,
    entityManager: InsuranceScanManager,
    parentEntityTrait: PatientTrait,
    useAppBar: false,
    entityId: null,

    columns: [
        'date_time',
    ],

    init(template) {
        super.init(template, 'insurancescan_detail_page');
    },

    __proto__: GenericRelationListingPage,
};

createView(genericRelationListingPageViewFactory, PatientInsuranceScanPage);

export default PatientInsuranceScanPage;

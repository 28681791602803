import { createView } from '../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../prototypes/GenericEditPage.js';
import { ChainAccountingNumberToAccountingNumber } from 'zpp-mpr-lib/models.js';
import ChainAccountingNumberToAccountingNumberPage from './ChainAccountingNumberToAccountingNumber.js';
import ChainAccountingNumberManager from '../managers/ChainAccountingNumber.js';

export const EditChainAccountingNumberToAccountingNumberDetailPage = {
    name: 'EditChainAccountingNumberToAccountingNumberDetailPage',
    path: '/chainaccountingnumber/{chainAccountingNumberId}/chainaccountingnumbertocccountingnumber/{entityId}/edit',
    template: 'page-edit-chain-accounting-number-to-accounting-number-detail',
    parent: ChainAccountingNumberToAccountingNumberPage,

    fields: [
        'chain_accounting_number',
        'accounting_number',
    ],

    onGetDefaults(params) {
        return ChainAccountingNumberManager.getEntity(params.chainAccountingNumberId)
            .then(entity => {
                return {chain_accounting_number: entity};
            });
    },

    entityType: ChainAccountingNumberToAccountingNumber,

    init(template) {
        super.init(template, 'chain_accounting_number_to_accounting_number_detail_page');
    },

    __proto__: GenericEditPage,
};

createView(genericEditPageViewFactory, EditChainAccountingNumberToAccountingNumberDetailPage);

export default EditChainAccountingNumberToAccountingNumberDetailPage;

import { Component, createView, defaultViewFactory } from '../prototypes/Component.js';
import { RenderEngine } from 'application-frame/rendering/index.js';
import EventTarget from 'application-frame/core/EventTarget.js';
import DataBinding from '@af-modules/databinding';

const Private = {
    open: Symbol('Dialog.Private.open'),
    title: Symbol('Dialog.Private.title'),
    actions: Symbol('Dialog.Private.actions'),
    innerScope: Symbol('Dialog.Private.innerScope'),
    scrimAction: Symbol('Dialog.Private.scrimAction'),
};

export const Events = {
    Close: Symbol('DialogComponent.Events.Close'),

    __proto__: EventTarget,
};

export const Dialog = {
    Events,

    [Private.open]: false,
    [Private.title]: null,
    [Private.scrimAction]: null,
    [Private.actions]: [],
    [Private.innerScope]: null,

    name: 'Dialog',
    template: 'component-dialog',

    get open() {
        return this[Private.open];
    },

    set open(value) {
        this[Private.open] = !!value;

        this.scope.update();
    },

    setContent({ template, title = null, data = {}, actions = [], scrimAction = 'scrim' } = {}) {
        const target = document.querySelector(`#${this.id} .content`);

        if (!target) {
            RenderEngine.schedulePostRenderTask(() => this.setContent({ template, data, actions }));

            return;
        }

        this[Private.innerScope]?.__destroy__();

        Array.from(target.children).forEach(child => {
            target.removeChild(child);
        });

        const dialog = this;
        const { node, scope } = DataBinding.createTemplateInstance({ template: `#${template}`, scope: data });

        scope.dialog = {
            close() {
                dialog.open = false;
            }
        };

        this[Private.title] = title;
        this[Private.actions] = actions;
        this[Private.innerScope] = scope;
        this[Private.scrimAction] = scrimAction;
        target.appendChild(node);
    },

    new() {
        const instance = super.new();

        instance.Events = Object.create(this.Events).constructor();

        return instance;
    },

    __proto__: Component,
};

createView((component) => {
    return {
        get title() {
            return component[Private.title];
        },

        get actions() {
            return component[Private.actions];
        },

        get hasNoActions() {
            return this.actions?.length === 0;
        },

        get id() {
            return component.id;
        },

        get open() {
            return component.open;
        },

        set open(value) {
            component.open = value;
        },

        get scrimAction() {
            return component[Private.scrimAction];
        },

        onClosed(event) {
            component.Events.emit(component.Events.Close, event);
        },

        __proto__: defaultViewFactory(component),
    };
}, Dialog);

export default Dialog;

const Private = {
    style: Symbol('CssContext.Private.style'),
};

export const CssContext = {
    [Private.style]: null,

    cssVar(property, type=String) {
        const value = this[Private.style].getPropertyValue(property).trim();

        if (type === Number) {
            return Number.parseFloat(value) || 0;
        }

        return value;
    },

    new(element) {
        const instance = { [Private.style]: window.getComputedStyle(element), __proto__: this };

        instance.cssVar = instance.cssVar.bind(instance);

        return instance;
    },
};

import Iterator from '@prograp/iterator';
import sodium from 'libsodium-wrappers';
import Config from './Config.js';
import { EntityTraitMap } from 'zpp-mpr-lib/traits/models.js';
import {EnumTrait} from 'zpp-mpr-lib/traits.js';

export const CryptoManager = {
    encryptMessage(message) {
        if (message.name === 'entity_update') {
            const encryptedEntity = Iterator.new(message.transactions).map((transaction) =>
            {
                const trait = EntityTraitMap.get(transaction.entityType);

                if (!trait) {
                    throw new Error(`${transaction.entityType} not found`);
                }

                return Promise.all(Iterator.fromObject(transaction.fields).map(([key, value]) =>
                {
                    if (value === null) {
                        return [key, value];
                    }

                    if (key in trait && typeof trait[key] !== 'function' && !trait[key][EnumTrait]) {
                        return [key, value];
                    }

                    if (typeof value === 'number') {
                        value = value.toString();
                    }

                    if (value instanceof Date) {
                        value = `ZPP_MPR_DATE_${value.getTime()}`;
                    }

                    if (value === true) {
                        value = 'ZPP_MPR_BOOLEAN_TRUE';
                    }

                    if (value === false) {
                        value = 'ZPP_MPR_BOOLEAN_FALSE';
                    }

                    if (typeof value === 'object' && 'byteLength' in value) {
                        value = new Uint8Array(value);
                    }

                    return encrypt(value, Config.secret).then((encryptedValue) => [key, encryptedValue]);
                })).then((fields) => {
                    transaction.fields = Object.fromEntries(fields);
                });
            });

            return Promise.all(encryptedEntity).then(() => message);
        }

        return Promise.resolve(message);
    },

    decrypt(message) {
        if (message.name === 'entity_changed') {
            const trait = EntityTraitMap.get(message.entityType);

            if (!trait) {
                throw new Error(`${message.entityType} not found`);
            }

            const decryptedEntity = Iterator.fromObject(message.entity).map(([key, value]) => {
                if (key in trait && typeof trait[key] !== 'function' && !trait[key][EnumTrait]) {
                    return [key, value];
                }

                if (key === 'id' || key === 'changed' || key === 'removed' || value === null) {
                    return Promise.resolve([key, value]);
                }

                return decrypt(value, Config.secret).then((decryptedValue) => {
                    if (decryptedValue === null) {
                        return [key, null];
                    }

                    if (typeof decryptedValue === 'object' && 'byteLength' in decryptedValue) {
                        return [key, decryptedValue];
                    }

                    if (decryptedValue.startsWith('ZPP_MPR_')) {
                        if (decryptedValue === 'ZPP_MPR_BOOLEAN_TRUE') {
                            return [key, true];
                        }

                        if (decryptedValue === 'ZPP_MPR_BOOLEAN_FALSE') {
                            return [key, false];
                        }

                        if (decryptedValue.startsWith('ZPP_MPR_DATE_')) {
                            decryptedValue = new Date(Number.parseInt(decryptedValue.substr(13)));

                            return [key, decryptedValue];
                        }
                    }

                    return [key, decryptedValue];
                });
            });

            return Promise.all(decryptedEntity).then((list) => {
                message.entity = Object.fromEntries(list);

                return message;
            });
        }

        return Promise.resolve(message);
    },
};

function encrypt(text, secretKey) {
    return sodium.ready.then(() => {
        const key = sodium.from_hex(secretKey);
        const nonce = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);

        const crypted = sodium.crypto_secretbox_easy(text, nonce, key);

        return sodium.to_hex(nonce) + sodium.to_hex(crypted);
    });
}

function decrypt(text, secretKey) {
    return sodium.ready.then(() => {
        const key = sodium.from_hex(secretKey);
        const nonce_and_ciphertext = sodium.from_hex(text);
        const nonce = nonce_and_ciphertext.slice(0, sodium.crypto_secretbox_NONCEBYTES),
            ciphertext = nonce_and_ciphertext.slice(sodium.crypto_secretbox_NONCEBYTES);

        const decryptedValue = sodium.crypto_secretbox_open_easy(ciphertext, nonce, key);

        try {
            return sodium.to_string(decryptedValue);
        } catch (e) {
            return decryptedValue.buffer;
        }
    });
}

export default CryptoManager;

import { LightboxComponent } from '../components/Lightbox.js';

export const LightboxManager = {
    component: null,

    init() {
        this.component = LightboxComponent.fromTemplate(LightboxComponent.template);
        this.component.init();
    },

    /**
     * @param {Blob} blob
     */
    request(blob) {
        this.component.request(blob);
    }
};

export default LightboxManager;

import { Component, ComponentCallbacks, defaultViewFactory as componentViewFactory } from './Component.js';
import { ViewTransitionManager, TransitionTypes } from '../managers/ViewTransition.js';
import { MagicTranslationKeyResolver } from '../lib/MagicTranslationKeyResolver.js';

/**
 * @param  {Page} page
 *
 * @return {Element|null}
 */
export const getPageElement = function(page) {
    if (!page.template) {
        throw new TypeError('argument 1 does not implement the Page interface');
    }

    return document.querySelector(`.${page.template}`) ?? null;
};

export const Page = {
    name: 'UnnamedPage',
    active: false,
    parent: null,

    [ComponentCallbacks.onL10nChanged]() {
        if (!this.active) {
            return;
        }

        super[ComponentCallbacks.onL10nChanged]();
    },

    onRouteEnter() {
        this.lazyInit();

        this.active = true;
        this.scope.update();

        const pageElement = getPageElement(this);

        if (pageElement === null) {
            throw new Error(`${this.name  } class not found`);
        }

        ViewTransitionManager.exit(getPageElement(this));
    },

    onRouteLeave() {
        this.active = false;
        this.scope.update();

        const isVacant = ViewTransitionManager
            .enterVacant(getPageElement(this), TransitionTypes.PageZPop);

        if (isVacant && this.parent?.active) {
            ViewTransitionManager.exit(getPageElement(this.parent));
        }
    },

    lazyInit() {
        if (!this.template || this.inflated) {
            return;
        }

        this.init(`#${this.template}`);
    },

    init(template, pageTranslationKey = null) {
        if (!this.strings) {
            this.strings = MagicTranslationKeyResolver.new('page', this, pageTranslationKey);
        }

        this.instantiateTemplate(template);

        return super.init();
    },

    __proto__: Component,
};

export const defaultViewFactory = function(page) {
    return {
        get active() {
            return page.active;
        },

        __proto__: componentViewFactory(page),
    };
};

export default Page;

export { createView } from './Component.js';

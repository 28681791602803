import { Iterator } from '@prograp/iterator';
import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView, pScope } from '../../prototypes/CustomElementDataBinding.js';

import defineCustomElement from '../../lib/defineCustomElement.js';
import template from './template.js';

const AppAudioRecorderMeta = {
    name: 'app-audio-recorder',
    template,
    attributes: {
        open: {
            type: 'boolean',
            reflectChanges: true,
        },
    },

    get object() { return AppAudioRecorder; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = AppAudioRecorderMeta;

const Private = {
    recorder: Symbol('AppAudioRecorder.Private.recorder'),
    chunks: Symbol('AppAudioRecorder.Private.chunk'),
    abort: Symbol('AppAudioRecorder.Private.abort'),
};

const Callbacks = {
    onDataAvailable: Symbol('AppAudioRecorder.Callbacks.onDataAvailable'),
    onRecordingComplete: Symbol('AppAudioRecorder.Callbacks.onRecordingComplete'),
};

const createView = function(element) {
    return {
        get isRecording() {
            return element[Private.recorder]?.state === 'recording';
        },

        get isOpen() {
            return element.open;
        },

        get duration() {
            if (!element[Private.chunks]) {
                return '00:00';
            }

            const duration = element[Private.chunks].length;
            const seconds = duration % 60;
            const minutes = Math.floor(duration / 60);

            return `${minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })}:${seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`;
        },

        get recordingIcon() {
            return this.isRecording ? 'pause' : 'mic';
        },

        onToggleRecording() {
            if (this.isRecording) {
                element.pauseRecording();

                return;
            }

            element.resumeRecording();
        },

        onFinishRecording() {
            element.stopRecording();
        },

        onRequestAbort() {
            element.dispatchEvent(new CustomEvent('abortrequest'));
        }
    };
};

const AppAudioRecorder = {
    [pMeta]: meta,
    [Private.recorder]: null,
    [Private.chunks]: null,
    [Private.abort]: false,

    /**
     * @type {File}
     */
    value: null,

    /**
     * @type {boolean}
     */
    open: false,

    startRecording() {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                this[Private.recorder] = new MediaRecorder(stream, { mimeType: getSupportedCodec() });
                this[Private.chunks] = [];
                this[Private.recorder].start(1000);
                this[Private.recorder].ondataavailable = this[Callbacks.onDataAvailable].bind(this);
                this[Private.recorder].onstop = this[Callbacks.onRecordingComplete].bind(this);
                this[pScope].update();
            });
    },

    pauseRecording() {
        this[Private.recorder]?.pause();
        this[pScope].update();
    },

    resumeRecording() {
        this[Private.recorder]?.resume();
        this[pScope].update();
    },

    stopRecording() {
        this[Private.recorder]?.stop();
        this[Private.recorder].stream.getTracks()
            .forEach((track) => {
                track.stop();
            });

        this[Private.recorder] = null;
    },

    abortRecording() {
        this[Private.abort] = true;
        this.stopRecording();
    },

    [Callbacks.onDataAvailable](event) {
        // safari continues to pass us empty blob data when the recording is paused...
        if (event.data.size === 0) {
            return;
        }

        this[Private.chunks].push(event.data);
        this[pScope].update();
    },

    [Callbacks.onRecordingComplete]() {
        if(this[Private.abort]) {
            this[Private.abort] = false;
            this[Private.chunks] = null;
            this.open = false;
            this.dispatchEvent(new CustomEvent('abort'));
            this.dispatchEvent(new CustomEvent('openclose'), { bubles: true });

            return;
        }

        const blob = new Blob(this[Private.chunks], { type: this[Private.chunks][0].type });

        this[Private.chunks] = null;
        this.value = blob;
        this.open = false;
        this.dispatchEvent(new CustomEvent('dataavailable'));
        this.dispatchEvent(new CustomEvent('openclose'), { bubles: true });
    },

    [meta.symbols.create]() {
        this[pView] = createView(this);
        super[meta.symbols.create]();
    },

    [meta.symbols.onPropertyChanged]() {
        this[pScope].update();
    },

    [meta.symbols.onOpenChanged](newValue) {
        if (newValue) {
            this.startRecording();
        }

        return newValue;
    },

    __proto__: CustomElementDataBinding,
};

const getSupportedCodec = function() {
    const mediaTypes = [
        'audio/webm',
        'audio/mp3',
        'audio/mp4',
        'audio/mpeg',
    ];

    const supportedCodecs = Iterator.new(mediaTypes)
        .filter((type) => MediaRecorder.isTypeSupported(type))
        .intoArray();

    return supportedCodecs[0];
};

defineCustomElement(meta);

export { AppAudioRecorder, AppAudioRecorderMeta };

import { AccountingNumber } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';

/**
 * @mixes EntityManager
 */
export const AccountingNumberManager = {
    Entity: AccountingNumber,
    Sorter: 'name',

    __proto__: EntityManager
};

export default AccountingNumberManager;

import { createView } from '../prototypes/Component.js';
import { FieldComponent, fieldViewFactory } from './Field.js';
import { Blob as BlobModel } from 'zpp-mpr-lib/models.js';
import { AudioRecorderManager } from '../managers/AudioRecoder.js';
import { ViewTransitionManager, TransitionTypes } from '../managers/ViewTransition.js';

export const VoiceMemoFieldComponent = {
    name: 'VoiceMemoFieldComponent',
    template: 'component-voice-memo-field',

    __proto__: FieldComponent,
};

createView((component) => {
    return {

        get hasValue() {
            return !!component.config.data;
        },

        get hasNoValue() {
            return !component.config.data;
        },

        get fileName() {
            return component.config.data?.name;
        },

        onDelete() {
            component.config.data = null;
        },

        onCreateNew(event) {
            AudioRecorderManager.requestRecording()
                .then(blob => Promise.all([`recording_${Date.now()}.mp3`, blob.type, blob.arrayBuffer()]))
                .then(([name, type, buffer]) => {
                    component.config.data = BlobModel.new(name, type, buffer);
                    component.scope.update();
                }).catch(() => console.warn('voice memo was aborted by user!'));

            ViewTransitionManager.enter(event.target, TransitionTypes.CardToPage);
            ViewTransitionManager.exit(document.querySelector('.component-audio-recorder'));
        },

        __proto__: fieldViewFactory(component),
    };
}, VoiceMemoFieldComponent);

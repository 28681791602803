import { App as Application } from '../main.js';

export const ManagerConfig = {

    /**
     * @type {Map}
     */
    userConfig: null,

    /**
     * @type {Application}
     */
    application: null,

    /**
     * @param  {string} name
     *
     * @return {{ application: Application, config: object }}
     */
    getConfig(name) {
        const config = {
            application: this.application,
            config: this.userConfig.get(name),
        };

        return config;
    },

    new(application, config) {
        const userConfig = new Map(Object.entries(config));

        return { application, userConfig, __proto__: this };
    },
};

export default ManagerConfig;

import { BindingApi } from '@af-modules/databinding';
import { AccessControlManager } from '../managers/AccessControl.js';

const AccessBinding = {

    name: 'bind-access',

    node: null,
    pathExpression: null,

    constructor({ text, node }) {
        this.pathExpression = text;
        this.node = node;

        BindingApi(this).attachBinding(this);
    },

    _make(...args) {
        this.constructor(...args);
    },

    update(scope) {
        const targetPath = BindingApi(this).parser.parseExpression(this.pathExpression, scope);
        const isAvailable = AccessControlManager.canVisitPath(targetPath);

        this.node.hidden = !isAvailable;
    },

    [AccessControlManager.Events.PermissionsChanged]() {

        // force the scope to update.
        // this is not very pretty
        BindingApi(this).scheduleScopeUpdate(() => {});
    },

    __proto__: BindingApi().Binding,
};

BindingApi().registerBinding(AccessBinding);

export default AccessBinding;

import { ChainAccountingNumber } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';

export const ChainAccountingNumberManager = {
    Entity: ChainAccountingNumber,
    Sorter: 'name',

    __proto__: EntityManager
};

export default ChainAccountingNumberManager;

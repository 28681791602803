import {
    CustomElement as CustomElementBase,
    CustomElementMeta as CustomElementBaseMeta
} from 'application-frame/web/index.js';

export const CustomElementMeta = {
    name: 'unamed-custom-element',
    template: null,
    get object() { return CustomElement; },

    __proto__: CustomElementBaseMeta,
};

const meta = CustomElementMeta;

export const pMeta = Symbol('CustomElement.meta');

export const CustomElement = {
    [pMeta]: meta,

    [CustomElementMeta.symbols.create]() {
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(document.importNode(this[pMeta].template.content, true));
    },

    __proto__: CustomElementBase,
};

export default CustomElement;

import { Component, createView, defaultViewFactory } from '../prototypes/Component.js';
import { Avatar } from '../lib/avatar.js';
import EventSubscriberFeature from '../lib/EventSubscriberFeature.js';
import { AccessControlManager, Events as AccessEvents} from '../managers/AccessControl.js';

const Callbacks = {
    onModeSwitch: Symbol('NavDrawerComponent.Callbacks.onModeSwitch'),
};

const pOpen = Symbol('NavDrawerComponent.open');

export const NavDrawerComponent = {
    name: 'NavDrawerComponent',
    template: 'component-nav-drawer',

    [pOpen]: false,
    mediaQuery: null,
    entries: null,
    user: null,

    avatar: null,

    get mode() {
        return this.mediaQuery.matches ? 'dismissible' : 'modal';
    },

    get open() {
        return this[pOpen];
    },

    set open(value) {
        this[pOpen] = !!value;
        this.scope.update();
    },

    init({ entries }) {
        super.init();

        this.mediaQuery = window.matchMedia('(min-width: 1024px)');
        this.entries = entries;

        this[Callbacks.onModeSwitch]();
        this.scope.update();

        window.addEventListener('hashchange', this.scope.update);
        this.mediaQuery.addEventListener('change', this[Callbacks.onModeSwitch].bind(this));

        EventSubscriberFeature(AccessControlManager, this);
    },

    [AccessEvents.PermissionsChanged]() {
        this.user = AccessControlManager.user;
        Avatar.asyncForId(this.user?.id ?? 'lala').then(avatar => {
            this.avatar = avatar;
            this.scope.update();
        });
        this.scope.update();
    },

    [Callbacks.onModeSwitch]() {
        this.open = (this.mode === 'dismissible');
    },

    __proto__: Component,
};

createView((component) => {
    return {
        strings: {},

        get isOpen() {
            return component.open;
        },

        set isOpen(value) {
            component.open = value;
        },

        get user() {
            return component.user;
        },

        get avatar() {
            return component.avatar;
        },

        get mode() {
            return component.mode;
        },

        get entries() {
            return (component.entries ?? [])
                .map(entry => ({ icon: entry.icon, title: entry.title, active: entry.active, path: `#!${entry.path}` }));
        },

        __proto__: defaultViewFactory(component),
    };
}, NavDrawerComponent);

export default NavDrawerComponent;

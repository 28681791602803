import DataBinding from '@af-modules/databinding';

const createView = function(host) {
    return {
        get pageTitle() { return host.title; },
        get styleSheets() { return host.styleSheets; },
    };
};

export const SiteMetadataManager = {
    template: '#dynamic-head',
    scope: null,

    title: 'Mobile Patient Records',
    styleSheets: [],

    init() {
        const { scope } = DataBinding.createTemplateInstance({ scope: createView(this), template: this.template });

        this.safariStylesheet();

        this.scope = scope;
        this.scope.update();
    },

    safariStylesheet() {
        if (!navigator.userAgent.includes('Safari/') || navigator.userAgent.includes('Chrome/')) {
            return;
        }

        const stylesheet = document.querySelector('meta[target="safari"]')?.content;

        if (!stylesheet) {
            return;
        }

        this.styleSheets.push(stylesheet);
    }
};

export default SiteMetadataManager;

import { createView } from '../prototypes/Component.js';
import { FieldComponent, fieldViewFactory } from './Field.js';

const toDateString = function(date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const DateFieldComponent = {
    name: 'DateFieldComponent',
    template: 'component-date-field',

    update() {
        if (this.config.storage.value && !this.config.data) {
            const defaultDate = new Date();

            defaultDate.setUTCFullYear(defaultDate.getFullYear());
            defaultDate.setUTCMonth(defaultDate.getMonth());
            defaultDate.setUTCDate(defaultDate.getDate());
            defaultDate.setUTCHours(0);
            defaultDate.setUTCMinutes(0);
            defaultDate.setUTCSeconds(0);
            defaultDate.setUTCMilliseconds(0);

            this.config.data = defaultDate;
        }

        super.update();
    },

    __proto__: FieldComponent,
};

createView((component) => {
    return {

        get value() {
            const date = component.config.data ?? new Date();

            return toDateString(date);
        },

        set value(value) {
            const [year, month, day] = value.split('-');
            const date = new Date(Date.UTC(year, month - 1, day));

            component.config.data = date;
        },

        __proto__: fieldViewFactory(component),
    };
}, DateFieldComponent);

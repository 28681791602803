import IndexedDB from 'application-frame/IndexedDB/index.js';
import EventTarget from 'application-frame/core/EventTarget.js';
import Iterator from '@prograp/iterator';
import {EnumTrait, Trait} from 'zpp-mpr-lib/traits.js';
import {EntityTraitMap} from 'zpp-mpr-lib/traits/models.js';
import {EntityMap} from 'zpp-mpr-lib/models.js';
import {EntityTransactionType, EntityTransaction} from 'zpp-mpr-lib/messages.js';
import { AccessControlManager } from './AccessControl.js';
import { EntityTraitToNameMap, EntityToNameMap } from '../lib/NameMaps.js';

const Private = {
    storage: Symbol('StorageManager.Private.storage'),
    withReadCache: Symbol('StorageManager.Private.withReadCache'),
    readCache: Symbol('StorageManager.Private.readCache'),
    getEntityTrait: Symbol('StorageManager.Private.getEntityTrait'),
    getEntityReference: Symbol('StorageManager.Private.getEntityReference'),
    writeChangesToDb: Symbol('StorageManager.Private.writeChangesToDb'),
};

const Callbacks = {
    onLoadRelations: Symbol('StorageManager.onLoadRelations'),
    onSetPrototypes: Symbol('StorageManager.onSetPrototypes'),
    onLoadRelatedEntity: Symbol('StorageManager.onLoadRelatedEntity'),
};

export const Events = {
    EntityChanged: Symbol('StorageManager.Events.EntityChanged'),
    AccessDeniedError: Symbol('StorageManager.Events.AccessDeniedError'),
};

const allPropertiesIterator = function*(object) {
    for (const key in object) {
        yield [key, object[key]];
    }
};

const createAccessError = function(message) {
    const error = new Error(`access denied: ${message}`);

    error.name = 'AccessError';

    return error;
};

/**
 * @mixes EventTarget
 */
export const StorageManager = {
    Events,

    /**
     * @type {?IndexedDB}
     */
    [Private.storage]: null,

    /**
     * @type {?Map}
     */
    [Private.readCache]: null,

    init() {
        const database = Object.create(IndexedDB)
            .constructor('mpr-storage');

        const definition = database.define(1);

        Iterator.new(EntityTraitMap)
            .forEach(([entityName, trait]) => {
                definition.store({
                    name: entityName,
                    keyPath: 'id',
                    autoincrement: false,
                    unique: true,
                }).index('id', 'id');

                Iterator.new(allPropertiesIterator(trait))
                    .forEach(([key]) => {
                        definition
                            .index(key, key);

                        if (entityName === 'treatment') {
                            definition.index(`patient+${key}`, ['patient', key]);
                        }

                        if (entityName === 'patient') {
                            definition.index(`nursing_home+${key}`, ['nursing_home', key]);
                        }

                    });

                if (entityName === 'treatment') {
                    definition.index('patient+changed', ['patient', 'changed']);
                    definition.index('patient+date_time+accounting_number', ['patient', 'date_time', 'accounting_number']);
                    definition.index('patient+date_time', ['patient', 'date_time']);
                    definition.index('care_facility+date_time+performer', ['care_facility', 'date_time', 'performer']);
                    definition.index('care_facility+date_time+performer+notExported', ['care_facility', 'date_time', 'performer', 'notExported']);
                }

                if (entityName === 'insurance_scan') {
                    definition.index('patient+date_time', ['patient', 'date_time']);
                }

                if (entityName === 'shared_treatment') {
                    definition.index('care_facility+date_time+accounting_number', ['care_facility', 'date_time', 'accounting_number']);
                    definition.index('care_facility+date_time', ['care_facility', 'date_time']);
                }

                definition.index('changed', 'changed');

                definition.store({
                    name: `${entityName}_history`,
                    keyPath: ['entityId', 'date'],
                    autoincrement: false,
                    unique: true,
                })
                    .index('entityId', 'entityId')
                    .index('date', 'date')
                    .index('keyPath', ['entityId', 'date']);
            });

        this.constructor();
        this[Private.storage] = database;
    },

    [Private.withReadCache]() {
        return this[Private.readCache] ? this : { [Private.readCache]: new Map(), __proto__: this };
    },

    [Callbacks.onLoadRelations](trait, entityList) {
        const pendingEntities = entityList.map(entityData => {
            const cacheKey = `${EntityTraitToNameMap.get(trait)}/${entityData.id}`;

            this[Private.readCache].set(cacheKey, Promise.resolve(entityData));

            const pendingRelations = Iterator.fromObject(entityData)
                .filter(([property]) => property in trait)
                .filter(([property]) => typeof trait[property] !== 'function')
                .filter(([property]) => !trait[property][EnumTrait])
                .filter(([, entityId]) => !!entityId)
                .map(([property, entityReference]) => {
                    if (Array.isArray(entityReference)) {
                        const refList = entityReference;
                        const [itemTrait] = trait[property] ?? [];

                        if (!itemTrait) {
                            throw new TypeError('invalid one-to-many relation!');
                        }

                        const promiseList = refList.map(ref => this[Callbacks.onLoadRelatedEntity](ref));

                        return Promise.all(promiseList)
                            .then(list => entityData[property] = list);
                    }

                    const entityPromise = this[Callbacks.onLoadRelatedEntity](entityReference);

                    return entityPromise
                        .then((object) => entityData[property] = object);
                });

            return Promise.all(pendingRelations);
        });

        return Promise.all(pendingEntities)
            .then(() => entityList);
    },

    [Callbacks.onLoadRelatedEntity](entityReference) {
        const [entityName, id] = entityReference.split('/');
        const Entity = EntityMap.get(entityName);
        const entityPromise = this.queryEntityById(Entity, id);

        this[Private.readCache].set(entityReference, entityPromise);

        return entityPromise;
    },

    /**
     * @param {object} entityType
     * @param {object[]} entityList
     *
     * @return {object[]}
     */
    [Callbacks.onSetPrototypes](entityType, entityList) {
        return entityList.map(entity => entityType.fromObject(entity));
    },

    /**
     * creates a new IndexedQueryCompiler
     *
     * @param {object} entity
     * @param {boolean} loadRelations
     *
     * @return {object}
     */
    query(entity, loadRelations=true) {
        const service = this[Private.withReadCache]();
        const storeName = EntityToNameMap.get(entity);

        if (!storeName) {
            throw new TypeError('unknown entity type');
        }

        const trait = Trait.for(entity);
        const relationResolver = service[Callbacks.onLoadRelations].bind(service, trait);
        const prototypeResolver = service[Callbacks.onSetPrototypes].bind(service, entity);
        const queryCompiler = service[Private.storage].read(storeName);

        return {
            get(...args) {
                let results = super.get(...args).then(prototypeResolver);

                if (loadRelations) {
                    results = results.then(relationResolver);
                }

                return results;
            },

            __proto__: queryCompiler,
        };
    },

    queryHistory(entity) {
        const storeName = `${EntityToNameMap.get(entity)}_history`;

        return this[Private.storage].read(storeName).where('entityId').from('');
    },

    deleteHistory(entity, id) {
        const storeName = `${EntityToNameMap.get(entity)}_history`;

        return this[Private.storage].delete(storeName).equals(id).commit();
    },

    queryOne(entity, loadRelations= true) {
        return {
            get() {
                return super.get(1).then(([result]) => {
                    if (result === null || result === undefined) {
                        throw new Error('no entity found for given query parameters');
                    }

                    return result;
                });
            },

            __proto__: this.query(entity, loadRelations),
        };
    },

    /**
     * query a single entity by its id
     *
     * @param {object} Entity
     * @param {string} id
     * @return {Promise}
     */
    queryEntityById(Entity, id) {
        const cacheKey = `${EntityToNameMap.get(Entity)}/${id}`;

        if (this[Private.readCache]?.has(cacheKey)) {
            return this[Private.readCache].get(cacheKey);
        }

        return this.queryOne(Entity, true)
            .where('id')
            .equals(id)
            .get();
    },

    queryEntityCount(Entity) {
        const queryCompiler = this[Private.storage]
            .read(EntityToNameMap.get(Entity));

        return {
            get() {
                return super.get().then(list => list.length);
            },

            __proto__: queryCompiler,
        };
    },

    [Private.getEntityTrait](entity, fallback) {
        const entityTrait = Trait.for(entity);

        if (fallback && entityTrait && entityTrait !== fallback) {
            throw new TypeError('specified trait does not match the trait associated with the entity');
        }

        return entityTrait ?? fallback;
    },

    [Private.getEntityReference](trait, entity) {
        const isOneToOne = entity?.id;
        const isOneToMany = Array.isArray(entity) && entity.some(item => item.id);

        if (isOneToMany) {
            return entity.map(item => this[Private.getEntityReference](trait[0], item));
        }

        if (!isOneToOne) {
            return entity;
        }

        const storeName = EntityTraitToNameMap.get(trait);

        return `${storeName}/${entity.id}`;
    },

    getReferenceFields: function (entityDelta, entityTrait) {
        return Iterator.fromObject(entityDelta)
            .map(([property, value]) => {
                const resolvedValue = this[Private.getEntityReference](entityTrait[property], value);

                return [property, resolvedValue];
            }).intoObject();
    },

    checkInvalid: function (entityDelta, entityTrait) {
        return Iterator.fromObject(entityDelta)
            .flatMap(([property]) => {
                if (!(property in entityTrait)) {
                    return [];
                }

                if (!Trait.validatePropertyType(property, entityDelta, entityTrait)) {
                    return [{property, entity: entityDelta, entityTrait}];
                }

                return [];
            }).intoArray();
    },

    storeChanges(entityId, entityDelta, trait = null) {
        const entityTrait = this.getTrait(entityDelta, trait);
        const storeName = this.getStoreName(entityTrait);

        const invalid = this.checkInvalid(entityDelta, entityTrait);

        if (invalid.length) {
            console.error(invalid);

            throw new TypeError('transaction data does not satisfy the trait.');
        }

        if (!entityId) {
            throw new TypeError('entityId can not be empty');
        }

        const fields = this.getReferenceFields(entityDelta, entityTrait);
        const transaction = EntityTransaction.new(entityId, storeName, AccessControlManager.user, fields, new Date());

        return this[Private.storage].read(storeName)
            .where('id')
            .equals(transaction.entityId)
            .get(1)
            .then(([entity]) => this[Private.writeChangesToDb](storeName, transaction, entity))
            .then((data) => {
                this.emit(Events.EntityChanged, {
                    data,
                    trait: entityTrait,
                });

                return data;
            }).catch(error => {
                if (error.name === 'AccessError') {
                    this.emit(Event.AccessDeniedError, { entityName: storeName });
                }

                throw error;
            });
    },

    storeDelete(entityId, trait ) {
        const entityTrait = trait;
        const storeName = this.getStoreName(entityTrait);

        if (!entityId) {
            throw new TypeError('entityId can not be empty');
        }

        const transaction = EntityTransaction.new(entityId, storeName, AccessControlManager.user, {}, new Date(), EntityTransactionType.Remove);

        return this[Private.storage].write(`${storeName}_history`, transaction)
            .then(() => this[Private.storage].delete(storeName).equals(entityId).commit())
            .then(() => {
                this.emit(Events.EntityChanged, {
                    data: {},
                    trait: entityTrait,
                });
            }).catch(error => {
                if (error.name === 'AccessError') {
                    this.emit(Event.AccessDeniedError, { entityName: storeName });
                }

                throw error;
            });
    },

    [Private.writeChangesToDb](storeName, transaction, entity) {
        const permission = AccessControlManager.getEntityPermission(storeName);

        if (!permission) {
            throw createAccessError(`user has no permission to write ${storeName}`);
        }

        if (!permission.canCreate && !entity) {
            throw createAccessError(`user has no permission to create new ${storeName}`);
        }

        entity = entity ?? { id: transaction.entityId, changed: transaction.date };

        return Promise.all([
            this[Private.storage].write(`${storeName}_history`, transaction),
            this[Private.storage].write(storeName, Object.assign(entity, transaction.fields)),
        ]).then(() => entity);
    },

    getTrait(entityDelta, trait) {
        const entityTrait = this[Private.getEntityTrait](entityDelta, trait);

        if (!entityTrait) {
            throw new Error('no entity trait provided!');
        }

        return entityTrait;
    },

    getStoreName(entityTrait) {
        const storeName = EntityTraitToNameMap.get(entityTrait);

        if (!storeName) {
            throw new TypeError('provided trait is unknown');
        }

        return storeName;
    },

    store(entityDelta, trait = null) {
        const entityTrait = this.getTrait(entityDelta, trait);
        const storeName = this.getStoreName(entityTrait);

        return this[Private.storage].write(storeName, entityDelta).then((data) => {
            this.emit(Events.EntityChanged, {
                data: entityDelta,
                trait: entityTrait,
            });

            return data;
        });
    },

    delete(entityDelta, trait = null) {
        const entityTrait = this.getTrait(entityDelta, trait);
        const storeName = this.getStoreName(entityTrait);

        return this[Private.storage].delete(storeName).equals(entityDelta.id).commit().then((data) => {
            this.emit(Events.EntityChanged, {
                data: entityDelta,
                trait: entityTrait,
            });

            return data;
        });
    },

    __proto__: EventTarget,
};

export default StorageManager;

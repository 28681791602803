import { Component, defaultViewFactory } from '../prototypes/Component.js';

export const FieldComponent = {

    /**
     * @type {{ label: string, data: object, error: string }}
     */
    config: null,

    init(config) {
        super.init();

        this.config = config;
        this.config.storage.when(this.update.bind(this));
    },

    update() {
        this.scope.update();
    },

    __proto__: Component,
};

export const fieldViewFactory = (component) => {
    return {
        get label() {
            return this.l10n(component.config.label);
        },

        get error() {
            return component.config.error;
        },

        get value() {
            return component.config.data;
        },

        set value(value) {
            component.config.data = value;
        },

        __proto__: defaultViewFactory(component),
    };
};

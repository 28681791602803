import { createView } from '../prototypes/Component.js';
import { FieldComponent, fieldViewFactory } from './Field.js';

export const TextFieldComponent = {
    name: 'TextFieldComponent',
    template: 'component-text-field',

    __proto__: FieldComponent,
};

createView(fieldViewFactory, TextFieldComponent);

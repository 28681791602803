import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView } from '../../../prototypes/CustomElementDataBinding.js';
import defineCustomElement from '../../../lib/defineCustomElement.js';
import cellTemplate  from './template.js';

export const AppMwcDataTableCellMeta = {
    name: 'app-mwc-data-table-cell',
    template: cellTemplate,
    attributes: {
        numeric: 'boolean',
    },

    get object() { return AppMwcDataTableCell; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = AppMwcDataTableCellMeta;

const createDataTableCellView = function(element) {
    return {
        get isNumeric() {
            return element.numeric;
        },
    };
};

export const AppMwcDataTableCell = {
    [pMeta]: AppMwcDataTableCellMeta,

    [meta.symbols.create]() {
        this[pView] = createDataTableCellView(this);

        super[meta.symbols.create]();
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(meta);

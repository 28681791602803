import { Page, defaultViewFactory, createView } from '../prototypes/Page.js';
import EventSubscriberFeature from '../lib/EventSubscriberFeature.js';
import { SyncManager, SyncTransaction } from '../managers/Sync.js';

export const SyncTestPage = {
    name: 'SyncTestPage',
    path: ['/sync-test'],
    template: 'page-sync-test',

    transactionList: [],

    strings: {},

    init(template) {
        super.init(template);

        EventSubscriberFeature(SyncManager, this);
    },

    onRouteEnter() {
        super.onRouteEnter();
    },

    [SyncManager.Events.TransactionListChange](list) {
        this.transactionList = list;

        return this.scope.update();
    },

    [SyncManager.Events.TransactionChange]() {
        return this.scope.update();
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get active() {
            return page.active;
        },

        get transactions() { return page.transactionList; },

        onManualSync() {
            SyncManager.fullSync();
        },

        symbolToString(symbol) {
            if (symbol === SyncTransaction.Direction.Down) {
                return 'Down';
            }

            if (symbol === SyncTransaction.Direction.Up) {
                return 'Up';
            }

            return symbol.toString();
        },

        __proto__: defaultViewFactory(page),
    };
}, SyncTestPage);

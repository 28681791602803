import EntityManager from './Entity.js';
import { User } from 'zpp-mpr-lib/models.js';
import {StorageManager} from './Storage.js';

/**
 * @mixes EntityManager
 */
export const UserManager = {
    Entity: User,
    Sorter: 'first_name',

    queryEntityByUsername(name) {
        return StorageManager.queryOne(this.Entity)
            .where('username')
            .equals(name)
            .get();
    },

    __proto__: EntityManager,
};

export default UserManager;

import {createView} from '../prototypes/Page.js';
import GenericDetail, {genericPageViewFactory} from '../prototypes/GenericDetail.js';
import ChainAccountingNumberToAccountingNumberPage from './ChainAccountingNumberToAccountingNumber.js';
import ChainAccountingNumberToAccountingNumberManager from '../managers/ChainAccountingNumberToAccountingNumber.js';

export const ChainAccountingNumberToAccountingNumberDetailPage = {
    name: 'ChainAccountingNumberToAccountingNumberDetailPage',
    path: '/chainaccountingnumber/{chainAccountingNumberId}/chainaccountingnumbertocccountingnumber/{entityId}',
    template: 'page-chain-accounting-number-to-accounting-number-detail',

    fields: [
        'chain_accounting_number',
        'accounting_number',
    ],

    parent: ChainAccountingNumberToAccountingNumberPage,
    manager: ChainAccountingNumberToAccountingNumberManager,

    __proto__: GenericDetail,
};

createView(genericPageViewFactory, ChainAccountingNumberToAccountingNumberDetailPage);

export default ChainAccountingNumberToAccountingNumberDetailPage;

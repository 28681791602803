import { createView } from '../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../prototypes/GenericEditPage.js';
import { ChainAccountingNumberPage } from './ChainAccountingNumber.js';
import { ChainAccountingNumberDetailPage } from './ChainAccountingNumberDetail.js';
import { ChainAccountingNumber } from 'zpp-mpr-lib/models.js';

export const ChainEditAccountingNumberDetailPage = {
    name: 'EditChainAccountingNumberDetailPage',
    path: '/chainaccountingnumber/{entityId}/details/edit',
    template: 'page-edit-chain-accounting-number-detail',

    fields: [
        'name',
    ],

    entityType: ChainAccountingNumber,

    get parent() {
        if (this.isNew) {
            return ChainAccountingNumberPage;
        }

        return ChainAccountingNumberDetailPage;
    },

    init(template) {
        super.init(template, 'chain_accounting_number_detail_page');
    },

    __proto__: GenericEditPage,
};

createView(genericEditPageViewFactory, ChainEditAccountingNumberDetailPage);

export default ChainEditAccountingNumberDetailPage;

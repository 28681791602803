import { CustomElement, CustomElementMeta, pMeta } from '../../prototypes/CustomElement.js';

import defineCustomElement from '../../lib/defineCustomElement.js';
import template from './template.js';

const AppAuthHeaderMeta = {
    name: 'app-auth-header',
    template,
    get object() { return AppAuthHeader; },

    __proto__: CustomElementMeta,
};

const meta = AppAuthHeaderMeta;

const AppAuthHeader = {
    [pMeta]: meta,

    __proto__: CustomElement,
};

defineCustomElement(meta);

export { AppAuthHeader, AppAuthHeaderMeta };

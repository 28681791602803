import { RenderEngine } from 'application-frame/rendering/index.js';
import { ContainerTransform } from '../lib/ContainerTransform.js';
import { GenericTransition } from '../lib/GenericTransition.js';

const Private = {
    source: Symbol('ViewTransitionManager.Private.source'),
    target: Symbol('ViewTransitionManager.Private.target'),
    type: Symbol('ViewTransitionManager.Private.type'),
    vacant: Symbol('ViewTransitionManager.Private.vacant'),
};

export const TransitionTypes = {
    PageZPush: Symbol('TransitionTypes.PageZPush'),
    PageZPop: Symbol('TransitionTypes.PageZPop'),
    CardToPage: Symbol('TransitionTypes.CardToPage'),
    FabToPage: Symbol('TransitionTypes.FabToPage'),
};

const Callbacks = {
    onPageZPushTransition: TransitionTypes.PageZPush,
    onPageZPopTransition: TransitionTypes.PageZPop,
    onCartToPageTransition: TransitionTypes.CardToPage,
    onFabToPageTransition: TransitionTypes.FabToPage,
};

export const ViewTransitionManager = {
    [Private.source]: null,
    [Private.target]: null,
    [Private.type]: null,
    [Private.vacant]: true,

    init() {},

    /**
     * @param  {Element} source
     * @param  {TransitionTypes} type
     *
     * @return {undefined}
     */
    enter(source, type) {
        this[Private.source] = source;
        this[Private.type] = type;
        this[Private.vacant] = false;
    },

    /**
     * @param  {Element} source
     * @param  {TransitionTypes} type
     *
     * @return {boolean}
     */
    enterVacant(source, type) {
        if (!this[Private.vacant]) {
            return false;
        }

        this.enter(source, type);
        this[Private.vacant] = true;

        return true;
    },

    /**
     * @param {Element} target
     *
     * @return {undefined}
     */
    exit(target) {
        if (!this[Private.source]) {
            console.log('received transition target without source');

            return;
        }

        this[Private.target] = target;

        // scheduleRenderTask automatically deduplicates tasks
        // with the same identifier (second argument)
        RenderEngine.scheduleRenderTask(() => {
            this[this[Private.type]]();

            this[Private.source] = null;
            this[Private.target] = null;
            this[Private.vacant] = true;
        }, this);
    },

    [Callbacks.onCartToPageTransition]() {
        return ContainerTransform
            .new(this[Private.source], this[Private.target], 'card-to-page')
            .run();
    },

    [Callbacks.onPageZPushTransition]() {
        return GenericTransition
            .new(this[Private.source], this[Private.target], [
                'transition-stack-lower-out',
                'transition-stack-upper-in'
            ]).run();
    },

    [Callbacks.onPageZPopTransition]() {
        return GenericTransition
            .new(this[Private.source], this[Private.target], [
                'transition-stack-upper-out',
                'transition-stack-lower-in'
            ]).run();
    },

    [Callbacks.onFabToPageTransition]() {
        return ContainerTransform
            .new(this[Private.source], this[Private.target], 'fab-to-page')
            .run();
    },
};

export default ViewTransitionManager;

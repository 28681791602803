import { CustomElement, CustomElementMeta } from 'application-frame/web/index.js';
import { DataBinding } from '@af-modules/databinding';

import template from './template.js';

const AppTextFieldMeta = {
    name: 'app-text-field',
    template,

    attributes: {
        label: 'string',
    },

    get object() { return AppTextField; },

    __proto__: CustomElementMeta,
};

const Private = {
    meta: Symbol('AppTextField.meta'),
    scope: Symbol('AppTextField.scope'),
    view: Symbol('AppTextField.view'),

    __proto__: AppTextFieldMeta.symbols,
};

const meta = AppTextFieldMeta;

const createView = function(element) {
    return {
        get label() { return element.label; },
    };
};

const AppTextField = {
    [Private.meta]: meta,
    [Private.scope]: null,
    [Private.view]: null,

    [Private.onPropertyChanged]() {
        this[Private.scope]?.update();
    },

    [Private.create]() {
        this[Private.view] = createView(this);

        const { node, scope } = DataBinding.createTemplateInstance({
            scope: this[Private.view],
            template: this[Private.meta].template,
        });

        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(node);
        this[Private.scope] = scope;
    },

    connectedCallback() {
        super.connectedCallback();

        this.setAttribute('resolved', '');
    },

    __proto__: CustomElement,
};

meta.prepare(meta.object);

customElements.define(meta.name, meta.object.constructor);

export { AppTextField, AppTextFieldMeta };

import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView, pScope } from '../../prototypes/CustomElementDataBinding.js';

import defineCustomElement from '../../lib/defineCustomElement.js';
import template from './template.js';

const AppFileSelectMeta = {
    name: 'app-file-select',
    template,
    attributes: {
        accept: 'string',
    },

    get object() { return AppFileSelect; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = AppFileSelectMeta;

const Private = {
    files: Symbol('AppFileSelect.Private.files'),
};

const createView = function(element) {
    return {
        get accept() {
            return element.accept;
        },

        get hasFile() {
            return element.files?.length > 0;
        },

        get fileName() {
            return element.files?.[0]?.name;
        },

        onSelectFile() {
            element.shadowRoot
                .querySelector('.input')
                .click();
        },

        onFileSelected(event) {
            element.files = event.target.files;
            element.dispatchEvent(new CustomEvent('change', { detail: element.files, bubbles: true }));
        },

        onRemoveFile() {
            element.files = null;
            element.dispatchEvent(new CustomEvent('change', { detail: null, bubbles: true }));
        }
    };
};

const AppFileSelect = {
    [pMeta]: meta,
    [Private.files]: null,

    get files() {
        return this[Private.files];
    },

    set files(value) {
        this[Private.files] = value;
        this[pScope].update();
    },

    [meta.symbols.create]() {
        this[pView] = createView(this);
        super[meta.symbols.create]();
    },

    [meta.symbols.onPropertyChanged]() {
        this[pScope].update();
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(meta);

export { AppFileSelect, AppFileSelectMeta };

import { FieldComponent, fieldViewFactory } from './Field.js';
import { Blob as BlobModel } from 'zpp-mpr-lib/models.js';

export const FileFieldComponent = {
    template: 'component-file-field',

    __proto__: FieldComponent,
};

export const createFileFieldViewFactory = function(component) {
    return {
        get accept() {
            return component.accept;
        },

        get value() {
            if (!component.config.data) {
                return null;
            }

            const { buffer, type, name, } = component.config.data ?? {};
            const files = [new File(new Uint8Array(buffer), name, { type })];

            return files;
        },

        set value(value) {
            if (!value) {
                component.config.data = null;

                return;
            }

            const [file] = value;
            const { name, type } = file;

            file.arrayBuffer()
                .then(buffer => {
                    component.config.data = BlobModel.new(name, type, buffer);
                    component.scope.update();
                });
        },

        __proto__: fieldViewFactory(component),
    };
};

import * as uuid from 'uuid';

export const Uuid = {
    clientNamespace: 'a6bb4376-e89d-5e8d-bc08-5ef3db50f96d',

    new() {
        return uuid.v5(this.clientNamespace, uuid.v4());
    }
};

export default Uuid;

import { CareFacility } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';

/**
 * @mixes EntityManager
 */
export const CareFacilityManager = {
    Entity: CareFacility,
    Sorter: 'name',

    __proto__: EntityManager
};

export default CareFacilityManager;

const pDefinition = Symbol('ComponentHost.definition');
const pComponents = Symbol('ComponentHost.components');

const Callbacks = {
    onCreateComponent: Symbol('ComponentHost.Callbacks.onCreateComponent'),
    onInitComponent: Symbol('ComponentHost.Callbacks.onInitComponent'),
};

export const ComponentHost = {
    [pDefinition]: null,
    [pComponents]: null,

    viewAdapter: null,

    get(name) {
        return this[pComponents].get(name);
    },

    getDynamicAccess(componentName) {
        const host = this;

        return {
            get value() {
                return host.viewAdapter[componentName];
            },

            set value(value) {
                host.viewAdapter[componentName] = value;
            }
        };
    },

    new(config) {
        const configMap = new Map(Object.entries(config));
        const instance = { [pDefinition]: configMap, [pComponents]: new Map(), __proto__: this };

        const viewAdapter = new Proxy(instance, {
            get(target, property) {
                if (property === 'get') {
                    return target.getDynamicAccess.bind(target);
                }

                return target[Callbacks.onCreateComponent](property);
            },

            set(target, property, value) {
                return target[Callbacks.onInitComponent](property, value);
            }
        });

        instance.viewAdapter = viewAdapter;

        return instance;
    },

    [Callbacks.onCreateComponent](name) {
        const componentExists = this[pDefinition].has(name);
        const componentCreated = this[pComponents].has(name);

        if (!componentExists) {
            throw new Error(`component ${name} does not exist!`);
        }

        if (componentCreated) {
            return this[pComponents].get(name).newView();
        }

        const definition = this[pDefinition].get(name);
        const component = definition.prototype.new();

        this[pComponents].set(name, component);

        return component.newView();
    },

    [Callbacks.onInitComponent](name, scope) {
        const componentExists = this[pDefinition].has(name);
        const componentCreated = this[pComponents].has(name);

        if (!componentExists) {
            throw new Error(`component ${name} does not exist!`);
        }

        if (!componentCreated) {
            throw new Error(`component ${name} can not be assigned before it's created!`);
        }

        const component = this[pComponents].get(name);
        const definition = this[pDefinition].get(name);

        component.scope = scope;
        component.init(definition.config ?? {});

        return true;
    }
};

export default ComponentHost;

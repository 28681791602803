import { FieldComponent, fieldViewFactory } from './Field.js';
import { DataStorage } from '../lib/DataStorage.js';

export const SelectFieldComponent = {
    template: 'component-select-field',

    options: null,
    entityManager: null,

    init(config) {
        this.options = DataStorage.new();
        this.options.when(this.scope.update);

        super.init(config);
    },

    __proto__: FieldComponent,
};

export const selectFieldViewFactory = function(component) {
    return {
        get options() {
            return component.options?.value ?? [];
        },

        isSelected(option) {
            return this.value === option;
        },

        __proto__: fieldViewFactory(component),
    };
};

import { Dialog, Events as DialogEvents } from '../components/Dialog.js';


const Private = {
    queue: Symbol('DialogManager.Private.queue'),
};

export const DialogManager = {
    [Private.queue]: null,

    component: null,

    init() {
        this[Private.queue] = Promise.resolve();
        this.component = Dialog.fromTemplate(Dialog.template);
        this.component.init();
    },

    /**
     * @param  {{ title: string, template: string, data: object, actions: Array.<{ label: string, name: string }> }} config
     *
     * @return {Promise}
     */
    show(config) {
        this[Private.queue] = this[Private.queue].then(() => new Promise((resolve) => {
            this.component.setContent(config);
            this.component.open = true;

            const callback = (event) => {
                resolve({ data: config.data, action: event.detail.action });

                this.component.Events.removeListener(DialogEvents.Close, callback);
            };

            this.component.Events.on(DialogEvents.Close, callback);
        }));

        return this[Private.queue];
    }
};

export default DialogManager;

import {createView} from '../../prototypes/Page.js';
import TreatmentManager from '../../managers/Treatment.js';
import GenericDetailPage, {genericPageViewFactory} from '../../prototypes/GenericDetail.js';
import PatientTreatmentPage from './PatientTreatment.js';
import {SharedTreatmentTrait} from 'zpp-mpr-lib/traits/models.js';

export const TreatmentDetailPage = {
    name: 'TreatmentDetailPage',
    path: '/carefacility/{careFacilityId}/patients/{patientsId}/treatment/{entityId}',
    template: 'page-treatment-detail',
    parent: PatientTreatmentPage,
    sharedAmount: null,

    fields: [
        'text',
        'patient',
        'shared',
        'date',
        'amount',
        'teeth',
        'accounting_number',
        'image',
        'video',
        'audio',
    ],

    manager: TreatmentManager,

    onRouteEnter(path, params) {
        super.onRouteEnter(path, params);
        this.entityStore.when(this.calculateSharedAmount.bind(this));
    },

    calculateSharedAmount() {
        if (!this.entityStore.value?.multi_treatment?.id) {
            return this.sharedAmount = '';
        }

        TreatmentManager.getEntityCountForRelatedEntity(SharedTreatmentTrait, this.entityStore.value?.multi_treatment?.id).then((count) => {
            this.sharedAmount = count;
            this.scope.update();
        });
    },

    __proto__: GenericDetailPage,
};

createView((page) => {
    return {
        get sharedAmount() {
            return page.sharedAmount;
        },

        __proto__: genericPageViewFactory(page),
    };

}, TreatmentDetailPage);

export default TreatmentDetailPage;

import { SharedTreatment } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';
import {StorageManager} from './Storage.js';
import {endOfDay, startOfDay} from 'date-fns';

/**
 * @mixes EntityManager
 */
export const SharedTreatmentManager = {
    Entity: SharedTreatment,
    Sorter: 'changed',

    getByCareFacilityIdAndDate(careFacilityId, date) {
        const start = startOfDay(date);
        const end = endOfDay(date);

        return StorageManager.query(SharedTreatment, true)
            .where('care_facility+date')
            .from([`care_facility/${careFacilityId}`, start])
            .to([`care_facility/${careFacilityId}`, end])
            .get();
    },

    getByCareFacilityIdToEndOfDay(careFacilityId, date) {
        const end = endOfDay(date);

        return StorageManager.query(SharedTreatment, true)
            .where('care_facility+date')
            .from([`care_facility/${careFacilityId}`, date])
            .to([`care_facility/${careFacilityId}`, end])
            .get();
    },

    __proto__: EntityManager
};

export default SharedTreatmentManager;

import EventTarget from 'application-frame/core/EventTarget.js';
import { FloatingActionComponent } from '../components/FloatingAction.js';

export const Events = {
    Action: Symbol('FloatingAction.Events.Action'),
    SubAction: Symbol('FloatingAction.Events.SubAction'),
};

/**
 * @mixes EventTarget
 */
export const FloatingActionManager = {
    component: null,
    currentOnwer: null,
    claimStack: [],

    init() {
        this.constructor();

        this.component = FloatingActionComponent.fromTemplate(FloatingActionComponent.template);
        this.component.init();
    },

    claim(owner, config) {
        if (this.currentOnwer !== owner) {
            const eventTarget = ({ Events, __proto__: EventTarget }).constructor();

            this.claimStack.push({ owner, config, eventTarget });
            this.component.eventTarget = eventTarget;
        }

        this.component.applyConfig(config);
        this.currentOnwer = owner;

        return this.component.eventTarget;
    },

    release(owner) {
        if (this.currentOnwer !== owner) {
            throw new Error('illegal release request, invalid owner');
        }

        this.currentOnwer = null;
        this.component.eventTarget = null;
        this.claimStack.pop();

        if (this.claimStack.length < 1) {
            return this.component.applyConfig({ visible: false });
        }

        const previous = this.claimStack[this.claimStack.length - 1];

        this.currentOnwer = previous.owner;
        this.component.eventTarget = previous.eventTarget;
        this.component.applyConfig(Object.assign(previous.config));
    },

    __proto__: EventTarget,
};

export default FloatingActionManager;

import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView, pScope } from '../../../prototypes/CustomElementDataBinding.js';

import defineCustomElement from '../../../lib/defineCustomElement.js';
import paginationTemplate  from './template.js';

export const AppMwcDataTablePaginationMeta = {
    name: 'app-mwc-data-table-pagination',
    template: paginationTemplate,
    attributes: {
        page: 'number',
        size: 'number',
        total: 'number',
    },

    get object() { return AppMwcDataTablePagination; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = AppMwcDataTablePaginationMeta;

const createView = function(element) {
    return {
        get currentPageStart() {
            return this.currentPageEnd - element.size + 1;
        },

        get currentPageEnd() {
            return element.page * element.size;
        },

        get pageSize() {
            return element.size;
        },

        get totalCount() {
            return element.total;
        },

        onGoNext() {
            element.page += 1;
            element.dispatchEvent(new CustomEvent('change'));
        },

        onGoPrev() {
            element.page -= 1;
            element.dispatchEvent(new CustomEvent('change'));

        },

        onGoFirst() {
            element.page = 1;
            element.dispatchEvent(new CustomEvent('change'));
        },

        onGoLast() {
            element.page = element.total / element.size;
            element.dispatchEvent(new CustomEvent('change'));
        },

        get hasNoPrev() {
            return element.page === 1;
        },

        get hasNoNext() {
            return element.page >= (element.total / element.size);
        }
    };
};

export const AppMwcDataTablePagination = {
    [pMeta]: meta,

    [meta.symbols.create]() {
        this[pView] = createView(this);
        super[meta.symbols.create]();
    },

    [meta.symbols.onPropertyChanged]() {
        this[pScope].update();
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(meta);

import { Component, createView, defaultViewFactory } from '../prototypes/Component.js';
import { Events as FloatingActionEvents } from '../managers/FloatingAction.js';

const Private = {
    activeConfig: Symbol('FloatingActionComponent.Private.activeConfig'),
};

export const FloatingActionComponent = {
    name: 'FloatingActionComponent',
    template: 'component-floating-action',
    config: null,
    eventTarget: null,

    [Private.activeConfig]: null,

    init() {
        super.init();

        this.config = {
            visible: false,
            mini: false,
            extended: false,
            label: '',
            icon: '',
        };
    },

    activateConfig() {
        this[Private.activeConfig] = Object.assign({}, this.config);
        this.scope.update();
    },

    applyConfig({ visible = true, extended = false, mini = false, label = '', icon = 'add', actions = [] }) {
        this.config.extended = !!extended;
        this.config.mini = !!mini;
        this.config.label = label.toString();
        this.config.icon = icon.toString();
        this.config.visible = !!visible;
        this.config.actions = actions;
        this.activateConfig();
    },

    __proto__: Component,
};

createView((component) => {
    return {
        strings: {
            get title() {
                return component.title ?? 'no_title_set';
            }
        },

        get isMini() {
            return component[Private.activeConfig]?.mini;
        },

        get isExtended() {
            return component[Private.activeConfig]?.extended;
        },

        get label() {
            return component[Private.activeConfig]?.label;
        },

        get icon() {
            return component[Private.activeConfig]?.icon;
        },

        get isHidden() {
            return !component[Private.activeConfig]?.visible;
        },

        get isSingleAction() {
            return !this.isMultiAction;
        },

        get isMultiAction() {
            return component[Private.activeConfig]?.actions?.length > 0;
        },

        get actions() {
            return component[Private.activeConfig]?.actions;
        },

        onEmitAction(event) {
            component.eventTarget?.emit(FloatingActionEvents.Action, event);
            event.preventRecycle();
        },

        onEmitSubAction(event, scope) {
            component.eventTarget?.emit(FloatingActionEvents.SubAction, Object.assign(event, {
                actionId: scope.$index,
                actionName: scope.action.name
            }));
            event.preventDefault();
        },

        hideAnimation(element) {
            return new Promise(resolve => {
                element.addEventListener('animationend', () => {
                    element.classList.remove('animate-hide');
                    resolve();
                }, { once: true });

                element.classList.add('animate-hide');
            }).then(() => {
                component.activateConfig();
            });
        },

        showAnimation(element) {
            return new Promise(resolve => {
                element.addEventListener('animationend', () => {
                    element.classList.remove('animate-show');
                    resolve();
                }, { once: true });

                element.classList.add('animate-show');
            });
        },

        get shouldAnimateHide() {
            return !component[Private.activeConfig]?.visible;
        },

        get shouldAnimateShow() {
            return component[Private.activeConfig]?.visible;
        },

        __proto__: defaultViewFactory(component),
    };
}, FloatingActionComponent);

export default FloatingActionComponent;

import Iterator from '@prograp/iterator';

const bufferToHex = function(buffer) {
    return Iterator.new(new Uint8Array(buffer))
        .map(byte => byte.toString(16).padStart(2, '0'))
        .intoArray()
        .join('');
};

const fromHexString = hexString =>
    new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));


/**
 * Parameters to derive a hash from a clear text password. We are using
 * PBKDF2 here as it is a recommended function for deriving keys from a password,
 * and the only standardized function in web browsers.
 * See: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/deriveKey#pbkdf2
 * and https://crypto.stackexchange.com/a/61060.
 *
 * For the actual PBKDF2 parameters we use the strongest hashing function and 100,000
 * iterations, which is 10x more than the recommended amount.
 * See: https://cryptosense.com/blog/parameter-choice-for-pbkdf2
 *
 * @type {object}
 */
const DERIVE_PARAMETER = {
    name: 'PBKDF2',
    salt: null,
    iterations: 100000,
    hash: 'SHA-512',
};

/**
 * These parameters define the function which is supposed to consume the derived key.
 * Since we are not going to consume the key in any way, this is not really relevant for us.
 *
 * @type {object}
 */
const KEY_TARGET_PARAMETER = {
    name: 'HMAC',
    hash: 'SHA-512'
};

export const Password = {
    hash: null,
    salt: null,

    new(hash, salt) {
        return { hash, salt, __proto__: this };
    },

    compare(password) {
        if (!password.hash || password.salt) {
            throw new TypeError('provided argument is not a vaild password object');
        }

        return password.hash === this.hash;
    },

    asyncFromText(text, salt = null) {
        salt = salt ? fromHexString(salt) : crypto.getRandomValues(new Uint32Array(100));
        const encoder = new TextEncoder();
        const keyMaterial = encoder.encode(text);
        const keyParameter = Object.assign({}, DERIVE_PARAMETER, { salt });

        // there is no subtle object in insecure contexts (no HTTPS)
        if (!crypto.subtle) {
            return Promise.resolve({ hash: 'DUMMY_VALUE_FOR_TESTING', salt: 'DUMMY_VALUE_FOR_TESTING', __proto__: this });
        }

        return crypto.subtle.importKey('raw', keyMaterial, 'PBKDF2', false, ['deriveKey'])
            .then(baseKey => crypto.subtle.deriveKey(keyParameter, baseKey, KEY_TARGET_PARAMETER, true, ['verify']))
            .then(key => crypto.subtle.exportKey('raw', key))
            .then(buffer => {
                const hashString = bufferToHex(buffer);
                const saltString = bufferToHex(salt.buffer);

                return { hash: hashString, salt: saltString, __proto__: this };
            });
    },

    toString() {
        return JSON.stringify(this);
    },

    fromString(value) {
        const data = JSON.parse(value);

        return Object.assign(this.new(), data);
    }
};

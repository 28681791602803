import { CustomElement, CustomElementMeta, pMeta } from '../../prototypes/CustomElement.js';

import defineCustomElement from '../../lib/defineCustomElement.js';
import template from './template.js';

const AppTextMeta = {
    name: 'app-text',
    template,
    get object() { return AppText; },

    __proto__: CustomElementMeta,
};

const meta = AppTextMeta;

const AppText = {
    [pMeta]: meta,

    __proto__: CustomElement,
};

defineCustomElement(meta);

export { AppText, AppTextMeta };

import { Iterator } from '@prograp/iterator';

export const MDCDataTableAdapter = {
    host: null,

    addClass(className) {
        throw new ReferenceError(`unknown data-table class ${className}`);
    },

    removeClass(className) {
        throw new ReferenceError(`unknown data-table class ${className}`);
    },

    addClassAtRowIndex(rowIndex, cssClasses) {
        const row = this.getRowElements()[rowIndex];

        row.classList.add(...cssClasses);
    },

    getRowCount() {
        return this.getRowElements().length;
    },

    getRowElements() {
        return Iterator.new(this.host.childNodes)
            .filter(node => !node.hasAttribute('slot'))
            .intoArray();
    },

    getRowIdAtIndex(rowIndex) {
        const row = this.getRowElements()[rowIndex];

        return row.rowId ?? null;
    },

    getRowIndexByChildElement(el) {
        return this.getRowElements()
            .findIndex(item => item.contains(el));
    },

    getSelectedRowCount() {
        throw new Error('selection is not implemented');
    },

    isCheckboxAtRowIndexChecked() {
        throw new Error('selection is not implemented');
    },

    isHeaderRowCheckboxChecked() {
        throw new Error('selection is not implemented');
    },

    isRowsSelectable() {
        return false;
    },

    notifyRowSelectionChanged(data) {
        const event = new CustomEvent('change', { detail: data });

        this.host.dispatchEvent(event);
    },

    notifySelectedAll() {
        const event = new CustomEvent('select-all');

        this.host.dispatchEvent(event);
    },

    notifyUnselectedAll() {
        const event = new CustomEvent('unselect-all');

        this.host.dispatchEvent(event);
    },

    registerHeaderRowCheckbox() {
        throw new Error('selection is not implemented');
    },

    registerRowCheckboxes() {
        throw new Error('selection is not implemented');
    },

    removeClassAtRowIndex(rowIndex, cssClasses) {
        const row = this.getRowElements()[rowIndex];

        row.classList.remove(...cssClasses);
    },

    setAttributeAtRowIndex(rowIndex, attr, value) {
        const row = this.getRowElements()[rowIndex];

        row.setAttribute(attr, value);
    },

    setHeaderRowCheckboxChecked() {
        throw new Error('selection is not implemented');
    },

    setHeaderRowCheckboxIndeterminate() {
        throw new Error('selection is not implemented');
    },

    setRowCheckboxCheckedAtIndex() {
        throw new Error('selection is not implemented');
    },

    getHeaderCellCount() {
        return this.getHeaderCellElements().length;
    },

    getHeaderCellElements() {
        return Iterator.new(this.host.children)
            .filter(node => node.hasAttribute('slot') && node.getAttribute('slot') === 'header')
            .intoArray();
    },

    getAttributeByHeaderCellIndex(columnIndex, attribute) {
        const cell = this.getHeaderCellElements()[columnIndex];

        return cell.getAttribute(attribute);
    },

    setAttributeByHeaderCellIndex(columnIndex, attribute, value) {
        const cell = this.getHeaderCellElements()[columnIndex];

        cell.setAttribute(attribute, value);
    },

    setClassNameByHeaderCellIndex(columnIndex, className) {
        const cell = this.getHeaderCellElements()[columnIndex];

        if (className === 'mdc-data-table__header-cell--sorted') {
            cell.sort = 'asc';

            return;
        }

        if (className === 'mdc-data-table__header-cell--sorted-descending') {
            cell.sort = 'desc';

            return;
        }


        throw new ReferenceError(`unknown header cell class ${className}`);
    },

    removeClassNameByHeaderCellIndex(columnIndex, className) {
        const cell = this.getHeaderCellElements()[columnIndex];

        if (className === 'mdc-data-table__header-cell--sorted') {
            cell.sort = '';

            return;
        }

        if (className === 'mdc-data-table__header-cell--sorted-descending') {
            if (cell.sort === '') {
                return;
            }

            cell.sort = 'asc';

            return;
        }


        throw new ReferenceError(`unknown header cell class ${className}`);
    },

    notifySortAction(data) {
        const event = new CustomEvent('sort', { detail: data });

        this.host.dispatchEvent(event);
    },

    getTableContainerHeight() {
        return this.host.querySelector('.mdc-data-table__table-container').offsetHeight;
    },

    getTableHeaderHeight() {
        return this.host.querySelector('.thead').offsetHeight;
    },

    setProgressIndicatorStyles(styles) {
        console.error('progress styles are not implemented', styles);
    },

    setSortStatusLabelByHeaderCellIndex(columnIndex, sortValue) {
        const cell = this.getHeaderCellElements()[columnIndex];

        cell.dataset.sortLabel = sortValue;
    },

    new(host) {
        return Object.assign({}, this, { host });
    }
};

import { Iterator } from '@prograp/iterator';

import defineCustomElement from '../../../lib/defineCustomElement.js';
import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView, pScope } from '../../../prototypes/CustomElementDataBinding.js';
import headerTemplate  from './template.js';

// table header cell element
export const AppMwcDataTableHeaderMeta = {
    name: 'app-mwc-data-table-header',
    template: headerTemplate,
    attributes: {
        sort: 'string',
        sortlabel: 'string',
        columnid: 'string',
    },

    get object() { return AppMwcDataTableHeader; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = AppMwcDataTableHeaderMeta;

const createDataTableHeaderView = function(element) {
    return {
        get hasSort() {
            return element.sort !== null;
        },

        get isSorted() {
            return element.sort !== null && element.sort !== '';
        },

        get isDesc() {
            return element.sort === 'desc';
        },

        get sortLabel() {
            return element.sortlabel;
        },

        get id() {
            return element.columnid;
        },

        onToggleSelect() {
            if (!this.hasSort) {
                return;
            }

            const detail = {
                columnId: this.id,
                columnIndex: Iterator.new(element.parentElement.children)
                    .filter(item => item.getAttribute('slot') === 'header')
                    .findIndex(item => item === element),
                headerCell: element,
            };

            const event = new CustomEvent('sort-toggle', { bubbles: true, detail });

            element.dispatchEvent(event);
        }
    };
};

export const AppMwcDataTableHeader = {
    [pMeta]: AppMwcDataTableHeaderMeta,

    [meta.symbols.create]() {
        this[pView] = createDataTableHeaderView(this);

        super[meta.symbols.create]();
    },

    [meta.symbols.onPropertyChanged]() {
        this[pScope].update();
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(AppMwcDataTableHeaderMeta);

export const defineCustomElement = function(elementMeta) {
    if (window.ShadyCSS) {
        window.ShadyCSS.prepareTemplate(elementMeta.template, elementMeta.name);
    }

    elementMeta.prepare(elementMeta.object);

    if (window.customElements) {
        window.customElements.define(elementMeta.name, elementMeta.object.constructor);
    }
};

export default defineCustomElement;

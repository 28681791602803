import { GenericListingPage, genericListingPageViewFactory } from './GenericListingPage.js';
import { Callbacks as PaginationCallbacks } from '../lib/Pagination.js';

export const GenericRelationListingPage = {
    parentEntityTrait: null,
    parentEntityId: null,

    useAppBar: false,

    onRouteEnter(path, params) {
        this.parentEntityId = params.entityId;

        super.onRouteEnter();
    },

    /**
     * @param {...any} args
     *
     * @return {Promise}
     */
    [PaginationCallbacks.onQueryEntities](...args) {
        return this.entityManager.getEntitiesForRelatedEntity(this.parentEntityTrait, this.parentEntityId, ...args);
    },

    /**
     * @return {Promise}
     */
    [PaginationCallbacks.onQueryEntityCount]() {
        return this.entityManager.getEntityCountForRelatedEntity(this.parentEntityTrait, this.parentEntityId);
    },

    __proto__: GenericListingPage,
};

export default GenericRelationListingPage;
export { genericListingPageViewFactory as genericRelationListingPageViewFactory };

import { createView } from '../prototypes/Page.js';
import { GenericEditPage, genericEditPageViewFactory } from '../prototypes/GenericEditPage.js';
import { AccountingNumberPage } from './AccountingNumber.js';
import { AccountingNumberDetailPage } from './AccountingNumberDetail.js';
import { AccountingNumber } from 'zpp-mpr-lib/models.js';

export const EditAccountingNumberDetailPage = {
    name: 'EditAccountingNumberDetailPage',
    path: '/accountingnumber/{entityId}/edit',
    template: 'page-edit-accounting-number-detail',

    fields: [
        'name',
        'BEMA',
        'BEMAextra',
        'GOZ',
        'GOZextra',
        'needs_followup',
        'needs_teeth',
    ],

    entityType: AccountingNumber,

    get parent() {
        if (this.isNew) {
            return AccountingNumberPage;
        }

        return AccountingNumberDetailPage;
    },

    init(template) {
        super.init(template, 'accounting_number_detail_page');
    },

    __proto__: GenericEditPage,
};

createView(genericEditPageViewFactory, EditAccountingNumberDetailPage);

export default EditAccountingNumberDetailPage;

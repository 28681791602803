import { Iterator } from '@prograp/iterator';
import { EnumTrait } from 'zpp-mpr-lib/traits.js';

import { createView } from '../prototypes/Component.js';
import { LanguageManager } from '../managers/Language.js';
import { SelectFieldComponent, selectFieldViewFactory } from './SelectField.js';

export const EnumSelectFieldComponent = {
    name: 'EnumSelectFieldComponent',
    rawOptions: null,

    init(config) {
        super.init(config);
        this.updateOptions(config);
    },

    updateOptions(config) {
        const options = Iterator.fromObject(config.propertyType)
            .map(([key, value]) => {
                const { [EnumTrait]: enumName } = config.propertyType;
                const translationKey = `enum.${enumName}.option_${key.toLowerCase()}_label`;

                return { get label() { return LanguageManager.translate(translationKey); }, value };
            }).intoArray();

        this.options.fill(options);
    },

    __proto__: SelectFieldComponent,
};

createView((component) => {
    return {
        get value() {
            return component.config.data;
        },

        set value(value) {
            component.config.data = value;
        },

        __proto__: selectFieldViewFactory(component)
    };
}, EnumSelectFieldComponent);

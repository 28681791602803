export const ConfigManager = {
    get host() {
        return localStorage.getItem('host');
    },
    get token() {
        return localStorage.getItem('token');
    },
    get clientId() {
        return localStorage.getItem('clientId');
    },
    get secret() {
        return localStorage.getItem('secret');
    },
    get updated() {
        return new Date(localStorage.getItem('updated'));
    },
    get environment() {
        return localStorage.getItem('environment');
    },
    set host(host) {
        localStorage.setItem('host', host);
    },
    set secret(secret) {
        localStorage.setItem('secret', secret);
    },
    set token(token) {
        localStorage.setItem('token', token);
    },
    set clientId(clientId) {
        localStorage.setItem('clientId', clientId);
    },
    set updated(updated) {
        localStorage.setItem('updated', updated.toISOString());
    },
    set environment(environment) {
        localStorage.setItem('environment', environment);
    },
};

export default ConfigManager;
import { Page, defaultViewFactory, createView } from '../../prototypes/Page.js';
import { PreferencesPage } from '../Preferences.js';
import { ComponentHost } from '../../prototypes/ComponentHost.js';
import { AppBarComponent } from '../../components/AppBar.js';
import { Events as FloatingActionEvents } from '../../managers/FloatingAction.js';
import { FloatingActionFeature } from '../../lib/FloatingActionFeature.js';
import TreatmentManager from '../../managers/Treatment.js';
import { TreatmentTrait } from 'zpp-mpr-lib/traits/models.js';
import {Trait} from 'zpp-mpr-lib/traits.js';
import { Treatment } from 'zpp-mpr-lib/models.js';
import { UserManager } from '../../managers/User.js';
import { DataStorage } from '../../lib/DataStorage.js';
import { StorageManager } from '../../managers/Storage.js';
import CareFacilityManager from '../../managers/CareFacility.js';
import {endOfDay, startOfDay} from 'date-fns';


export const ExportPreferencesPage = {
    name: 'ExportPreferencesPage',
    path: '/preferences/export',
    template: 'page-export-preferences',
    get parent() { return PreferencesPage; },

    icon: 'file_download',

    floatingActionFeature: null,

    strings: {
        title: 'page.export_preferences.title',
        description: 'page.export_preferences.description',
        carefacility: 'page.export_preferences.carefacility',
        user: 'page.export_preferences.user',
        date: 'page.export_preferences.date',
        exported: 'page.export_preferences.exported',
        csv: 'page.export_preferences.csv',
    },

    carefacilityOptions: null,
    carefacility: null,
    userOptions: null,
    user: null,
    date: null,
    notExported: null,

    init(template) {
        super.init(template);

        this.floatingActionFeature = FloatingActionFeature({
            label: 'Export',
            icon: 'file_download',
            visible: true
        }, this);
        this.userList = DataStorage.new();
        this.userList.when(this.scope.update);
        this.avatarCache = new WeakMap();

        this.components = ComponentHost.new({
            appBar: {
                prototype: AppBarComponent,
                config: {
                    title: this.strings.title,
                    host: this,
                }
            }
        });

        this.carefacilityOptions = DataStorage.new();
        this.carefacilityOptions.when(this.scope.update);

        this.carefacility = null;

        this.userOptions = DataStorage.new();
        this.userOptions.when(this.scope.update);
    },

    createCsv() {
        let index = 'care_facility+date_time+performer';
        const startKey = [];
        const endKey = [];

        if (this.carefacility) {
            startKey.push(`care_facility/${this.carefacility.id}`);
            endKey.push(`care_facility/${this.carefacility.id}`);
        }


        if (this.date) {
            const defaultDate = new Date(this.date);

            defaultDate.setUTCHours(0);
            defaultDate.setUTCMinutes(0);
            defaultDate.setUTCSeconds(0);
            defaultDate.setUTCMilliseconds(0);

            const start = startOfDay(defaultDate);
            const end = endOfDay(defaultDate);

            startKey.push(start);
            endKey.push(end);
        } else {
            startKey.push(null);
            endKey.push(null);
        }

        if (this.user) {
            startKey.push(`user/${this.user.id}`);
            endKey.push(`user/${this.user.id}`);
        } else {
            startKey.push(null);
            endKey.push(null);
        }

        if (this.notExported) {
            index += '+notExported';
            startKey.push('true');
            endKey.push('true');
        }

        return StorageManager.query(Treatment)
            .where(index)
            .from(startKey)
            .to(endKey)
            .sort('ASC')
            .get().then((deepTreatments) => {
                const treatments = deepTreatments.flat();

                return Promise.all(treatments.map((treatment) => {
                    return TreatmentManager.storeChanges(treatment.id, {notExported: false});
                })).then(() => {
                    const header = Object.keys(Trait.getOwnProperties(TreatmentTrait));

                    const replacer = (key, value) => value === null ? '' : value;

                    return [
                        header.join(','), // header row first
                        ...treatments.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
                    ].join('\r\n');
                });
            });
    },

    onRouteEnter() {
        super.onRouteEnter();
        this.floatingActionFeature.claim();

        this.user = null;

        this.updateOptions();
    },

    onRouteLeave() {
        super.onRouteLeave();
        this.floatingActionFeature.release();
    },

    [FloatingActionEvents.Action]() {
        this.createCsv().then((csv) => {
            const dateString = (new Date()).toISOString().split('T')[0];
            const a       = document.createElement('a');

            a.href        = `data:attachment/csv,${encodeURIComponent(csv)}`;
            a.target      = '_blank';
            a.download    = `export${dateString}.csv`;

            document.body.appendChild(a);
            a.click();
            a.remove();
        });
    },

    updateOptions() {
        CareFacilityManager.getEntities()
            .then(list => this.carefacilityOptions.fill(list));

        UserManager.getEntities()
            .then(list => this.userOptions.fill(list));
    },

    __proto__: Page,
};

createView((page) => {
    return {
        get components() {
            return page.components.viewAdapter;
        },

        get carefacilityOptions() {
            return page.carefacilityOptions?.value ?? [];
        },

        get userOptions() {
            return page.userOptions?.value ?? [];
        },

        get carefacilityValue() {
            return page.carefacility?.id;
        },

        get userValue() {
            return page.user?.id;
        },

        get dateValue() {
            return page.date;
        },

        get notExportedValue() {
            return page.notExported;
        },

        set carefacilityValue(value) {
            const option = this.carefacilityOptions.find(item => item.id === value);

            if (!option) {
                page.scope.update();

                return;
            }

            page.carefacility = option;
        },

        set userValue(value) {
            const option = this.userOptions.find(item => item.id === value);

            if (!option) {
                page.scope.update();

                return;
            }

            page.user = option;
        },

        set dateValue(value) {
            page.date = value;
        },

        set notExportedValue(value) {
            page.notExported = value;
        },

        isCarefacilitySelected(id) {
            return this.carefacility === id;
        },

        isUserSelected(id) {
            return this.user === id;
        },

        __proto__: defaultViewFactory(page),
    };
}, ExportPreferencesPage);

export default ExportPreferencesPage;

import { DataBinding } from '@af-modules/databinding';
import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView, pScope } from '../../../prototypes/CustomElementDataBinding.js';

import defineCustomElement from '../../../lib/defineCustomElement.js';
import template  from './template.js';


// table row element
export const AppMwcDataTableRowMeta = {
    name: 'app-mwc-data-table-row',
    template,
    attributes: {
        action: 'boolean',
    },

    get object() { return AppMwcDataTableRow; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = AppMwcDataTableRowMeta;

const createView = function(element) {
    return {
        get hasNoAction() {
            return !element.action;
        },
    };
};

export const AppMwcDataTableRow = {
    [pMeta]: meta,

    [meta.symbols.create]() {
        this[pView] = createView(this);

        super[meta.symbols.create]();

        DataBinding.attachBindings(this[pScope], this, [
            { selector: 'root', name: 'bind-ripple', value: '.ripple' }
        ]);
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(meta);

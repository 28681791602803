import { createView, Component, defaultViewFactory } from '../prototypes/Component.js';
import { DialogManager } from '../managers/Dialog.js';

const Private = {
    open: Symbol('AudioRecorderComponent.Private.open'),
};

export const Callbacks = {
    onRecordingAvailable: Symbol('AudioRecorderComponent.Callbacks.onRecordingAvailable'),
    onRecordingAborted: Symbol('AudioRecorderComponent.Callbacks.onRecordingAborted'),
};

export const AudioRecorderComponent = {
    name: 'AudioRecorderComponent',
    template: 'component-audio-recorder',
    host: null,

    [Private.open]: false,

    get open() {
        return this[Private.open];
    },

    set open(value) {
        this[Private.open] = value;
        this.scope.update();
    },

    init(config) {
        super.init(config);
        this.host = config.host;
    },

    __proto__: Component,
};

createView((component) => {
    return {

        get open() {
            return component.open;
        },

        set open(value) {
            component[Private.open] = value;
        },

        onDataAvailable(event) {
            component.host[Callbacks.onRecordingAvailable](event.target.value);
        },

        onRequestAbort(requestEvent) {
            DialogManager.show({
                actions: [
                    { label: this.l10n(this.strings.cancelDialog), name: 'cancel' },
                    { label: this.l10n(this.strings.confirmDialog), name: 'confirm' },
                ],
                title: this.l10n(this.strings.dialogTitle),
                template: 'fragment-dialog-text',
                data: { content: this.l10n(this.strings.dialogContent) },
            }).then(event => {
                if (event.action !== 'confirm') {
                    return;
                }

                requestEvent.target.abortRecording();
            });
        },

        onAbort() {
            component.host[Callbacks.onRecordingAborted]();
        },

        __proto__: defaultViewFactory(component)
    };
}, AudioRecorderComponent);

import { Uuid } from 'zpp-mpr-lib/general.js';
import { AudioRecorderComponent, Callbacks as AudioRecoderCallbacks } from '../components/AudioRecorder.js';

const audioContext = new AudioContext({ latencyHint: 'interactive' });
let lazyThread = null;

const transcodeBlob = function(blob) {
    if (!lazyThread) {
        lazyThread = new Worker('./js/mp3-transcoder-worker.js');
    }

    const jobId = Uuid.new();

    return blob.arrayBuffer()
        .then(data => audioContext.decodeAudioData(data))
        .then(audioBuffer => {
            return new Promise((resolve) => {
                const rawAudioData = audioBuffer.getChannelData(0).buffer;
                const sampleRate = audioBuffer.sampleRate;

                lazyThread.addEventListener('message', function callback(event) {
                    const { id, data } = event.data;

                    if (id !== jobId) {
                        return;
                    }

                    const blob = new Blob([data], { type: 'audio/mp3' });

                    lazyThread.removeEventListener('message', callback);
                    resolve(blob);
                });

                lazyThread.postMessage({
                    id: jobId,
                    data: rawAudioData,
                    type: 'float32buffer',
                    target: 'int8buffer',
                    sampleRate
                }, [rawAudioData]);
            });
        });
};

export const AudioRecorderManager = {
    component: null,
    pendingRequest: null,

    requestRecording() {
        return new Promise((resolve, reject) => {
            if (this.pendingRequest) {
                reject(new Error('audio recoder already in use'));
            }

            this.pendingRequest = { resolve, reject };
            this.component.open = true;
        });
    },

    [AudioRecoderCallbacks.onRecordingAborted]() {
        const request = this.pendingRequest;

        this.pendingRequest = null;
        request.reject(new Error('recording aborted by user'));
    },

    [AudioRecoderCallbacks.onRecordingAvailable](blob) {
        this.pendingRequest.resolve(transcodeBlob(blob));
        this.pendingRequest = null;
    },

    init() {
        this.component = AudioRecorderComponent.fromTemplate(AudioRecorderComponent.template);
        this.component.init({ host: this });
    }
};

export default AudioRecorderManager;

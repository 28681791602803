import EventTarget from 'application-frame/core/EventTarget.js';
import { NavDrawerComponent } from '../components/NavDrawer.js';

export const Events = {
    Open: Symbol('NavigationManager.Events.Open'),
    Close: Symbol('NavigationManager.Events.close'),
    Toggle: Symbol('NavigationManager.Events.Toggle'),
};

/**
 * @mixes EventTarget
 */
export const NavigationManager = {
    Events,

    component: null,

    get isDrawerOpen() {
        return this.component.open;
    },

    openDrawer() {
        this.component.open = true;

        this.emit(Events.Open);
        this.emit(Events.Toggle, true);
    },

    closeDrawer() {
        this.component.open = false;

        this.emit(Events.Close);
        this.emit(Events.Toggle, false);
    },

    toggleDrawer() {
        const newState = !this.isDrawerOpen;
        const event = newState ? Events.Open : Events.Close;

        this.component.open = newState;

        this.emit(Events.Toggle, newState);
        this.emit(event);
    },

    init({ application: { navigation } }) {
        this.constructor();
        this.entries = navigation;

        this.component = NavDrawerComponent.fromTemplate(NavDrawerComponent.template);
        this.component.init({ entries: navigation });
    },

    __proto__: EventTarget,
};

export default NavigationManager;

import { defaultViewFactory, Page } from './Page.js';
import {ComponentHost} from './ComponentHost.js';
import { AppBarComponent } from '../components/AppBar.js';
import { Router } from '@af-modules/router';

const buildTabConfig = function(tabs) {
    const tabViewConfig = [];
    const tabComponentConfig = [];

    tabs.forEach((tabConfig, index) => {
        const name = `tab${index}`;
        const template = `${tabConfig.page.template}-base`;

        tabViewConfig.push({ name, template });

        tabComponentConfig.push([name, { prototype: tabConfig.page, config: null }]);
    });

    return { tabViewConfig, tabComponentConfig: Object.fromEntries(tabComponentConfig) };
};

export const GenericTabsPage = {
    tabs: [],
    generatedTabs: null,
    tabViewConfig: null,
    currentPath: null,

    init(template, pageTranslationKey) {
        super.init(template, pageTranslationKey);

        const { tabViewConfig, tabComponentConfig } = buildTabConfig(this.tabs);

        this.tabViewConfig = tabViewConfig;
        this.generatedTabs = this.tabs.map(tab => {
            return { title: tab.title, link: tab.page.path.replace(this.path, '') };
        });

        this.components = ComponentHost.new({
            appBar: {
                prototype: AppBarComponent,
                config: {
                    title: this.title,
                    host: this,
                }
            },

            ...tabComponentConfig
        });
    },

    onRouteEnter(path, params) {
        this.currentPath = path;

        super.onRouteEnter(path, params);

        if (path === location.hash.substr(2)) {
            Router.switchTo(this.fullLink(this.generatedTabs[0]?.link));
        }
    },

    fullLink(link) {
        return `${this.currentPath}${link}`;
    },

    __proto__: Page,
};

export const genericTabsPageViewFactory = (page) => {
    return {
        createdTabs: -1,

        get components() {
            return page.components.viewAdapter;
        },

        get tabs() {
            return page.generatedTabs;
        },

        get tabContents() {
            return page.tabViewConfig;
        },

        get activeTab() {
            const tabIndex = this.tabs.findIndex(tab => this.isActiveTab(tab));

            if (tabIndex > this.createdTabs) {
                return -1;
            }

            return tabIndex;
        },

        isActiveTab(tab) {
            return `#!${this.fullLink(tab.link)}` === location.hash;
        },

        fullLink(link) {
            return page.fullLink(link);
        },

        newTab(index) {
            if (this.createdTabs >= index) {
                return false;
            }

            this.createdTabs = index;
            page.scope.update();

            return false;
        },

        onActivateTab(event, scope) {
            Router.switchTo(this.fullLink(scope.tab.link));
        },

        __proto__: defaultViewFactory(page),
    };
};

export default GenericTabsPage;

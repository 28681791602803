import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView, pScope } from '../../prototypes/CustomElementDataBinding.js';

import defineCustomElement from '../../lib/defineCustomElement.js';
import template from './template.js';

const AppLightboxMeta = {
    name: 'app-lightbox',
    template,
    attributes: {
        type: 'string',
        open: {
            type: 'boolean',
            reflectChanges: true,
        },
    },

    get object() { return AppLightbox; },

    __proto__: CustomElementDataBindingMeta,
};

const meta = AppLightboxMeta;

const createView = function(element) {
    return {
        get type() {
            return element.type;
        },

        get isOpen() {
            return element.open;
        },

        onClose() {
            element.open = false;
            element.dispatchEvent(new Event('change', { bubbles: true }));
        }
    };
};

const AppLightbox = {
    [pMeta]: meta,

    [meta.symbols.create]() {
        this[pView] = createView(this);
        super[meta.symbols.create]();
    },

    [meta.symbols.onPropertyChanged]() {
        this[pScope].update();
    },

    attributeChangedCallback(attribute, oldValue, newValue) {
        if (oldValue === newValue) {
            return;
        }

        return super.attributeChangedCallback(attribute, oldValue, newValue);
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(meta);

export { AppLightbox, AppLightboxMeta };

import {createView} from '../prototypes/Page.js';
import { GenericListingPage, genericListingPageViewFactory } from '../prototypes/GenericListingPage.js';
import MedicalPracticeManager from '../managers/MedicalPractice.js';

export const MedicalPracticePage = {
    name: 'MedicalPracticePage',
    path: '/medicalpractice',
    template: 'page-medical-practice',
    entityManager: MedicalPracticeManager,
    icon: 'medical_services',
    title: 'page.medical_practice_page.title',

    columns: [
        'name',
        'street',
        'house_number',
        'zip',
        'city',
    ],

    init(template) {
        super.init(template, 'medical_practice_detail_page');
    },

    __proto__: GenericListingPage,
};

createView(genericListingPageViewFactory, MedicalPracticePage);

export default MedicalPracticePage;

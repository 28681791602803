import { DataBinding } from '@af-modules/databinding';
import { CustomElementDataBinding, CustomElementDataBindingMeta, pMeta, pView, pScope } from '../../prototypes/CustomElementDataBinding.js';
import { MDCDataTableFoundation } from '@material/data-table';

import defineCustomElement from '../../lib/defineCustomElement.js';
import { MDCDataTableAdapter } from './adapter.js';
import template from './template.js';

// table container element
export const AppMwcDataTableMeta = {
    name: 'app-mwc-data-table',
    template,
    attributes: {
        label: 'string',
    },

    get object() { return AppMwcDataTable; },

    __proto__: CustomElementDataBindingMeta,
};

const Private = {
    foundation: Symbol('AppMwcDataTable.Private.foundation'),
};

const createView = function(element) {
    return {
        get label() {
            return element.label;
        },

        onSort(event) {
            event.stopPropagation();

            element[Private.foundation].handleSortAction(event.detail);
        },
    };
};

export const AppMwcDataTable = {
    [pMeta]: AppMwcDataTableMeta,
    [Private.foundation]: null,

    [AppMwcDataTableMeta.symbols.create]() {
        this[pView] = createView(this);

        super[AppMwcDataTableMeta.symbols.create]();

        this[Private.foundation] = new MDCDataTableFoundation(MDCDataTableAdapter.new(this));

        DataBinding.attachBindings(this[pScope], this, [
            { selector: 'root', name: 'bind-event', parameter: 'sort-toggle', value: 'view.onSort' }
        ]);
    },

    connectedCallback() {
        super.connectedCallback();

        this[Private.foundation].layout();
    },

    __proto__: CustomElementDataBinding,
};

defineCustomElement(AppMwcDataTableMeta);

export * from './cell/cell.js';
export * from './header/header.js';
export * from './row/row.js';
export * from './pagination/pagination.js';

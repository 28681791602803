import {createView} from '../../prototypes/Page.js';
import GenericDetailPage, {genericPageViewFactory} from '../../prototypes/GenericDetail.js';
import {UserPreferencesPage} from './UserPreferences.js';
import UserManager from '../../managers/User.js';

export const UserDetailPreferencesPage = {
    name: 'UserDetailPreferencesPage',
    path: '/preferences/users/{entityId}',
    template: 'page-user-detail-preferences',
    get parent() { return UserPreferencesPage; },

    fields: [
        'first_name',
        'last_name',
        'username',
        'street',
        'house_number',
        'zip',
        'city',
        'country',
        'phone_number',
        'medical_practice',
        'usergroup',
        'lat',
        'longitude',
    ],

    manager: UserManager,

    __proto__: GenericDetailPage,
};

createView(genericPageViewFactory, UserDetailPreferencesPage);

export default UserDetailPreferencesPage;

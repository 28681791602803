
const thread = new Worker('./js/avatar-worker.js');

const bufferToBlob = function(buffer, mimeType) {
    return new Blob([buffer], { type: mimeType });
};

export const Avatar = {
    blob: null,
    url: null,

    new(blob) {
        const url = URL.createObjectURL(blob);

        return { blob, url, __proto__: this };
    },

    asyncForId(entityId) {
        return (new Promise((resolve) => {
            const callback = (event) => {
                if (event.data.id !== entityId) {
                    return;
                }

                thread.removeEventListener('message', callback);
                resolve(bufferToBlob(event.data.buffer, event.data.mimeType));
            };

            thread.addEventListener('message', callback);
            thread.postMessage(entityId);
        })).then(blob => {
            return this.new(blob);
        });
    }
};

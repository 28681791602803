import {createView} from '../../prototypes/Page.js';
import {GenericDetailPage, genericPageViewFactory} from '../../prototypes/GenericDetail.js';
import InsuranceScanManager from '../../managers/InsuranceScan.js';
import PatientInsuranceScanPage from './PatientInsuranceScan.js';

export const InsuranceScanDetailPage = {
    name: 'InsuranceScanDetailPage',
    path: '/carefacility/{careFacilityId}/patients/{patientsId}/insurancescan/{entityId}',
    template: 'page-insurancescan-detail',
    parent: PatientInsuranceScanPage,
    sharedAmount: null,

    fields: [
        'date_time',
    ],

    manager: InsuranceScanManager,

    __proto__: GenericDetailPage,
};

createView(genericPageViewFactory, InsuranceScanDetailPage);

export default InsuranceScanDetailPage;

import { ChainAccountingNumberToAccountingNumber } from 'zpp-mpr-lib/models.js';
import EntityManager from './Entity.js';

export const ChainAccountingNumberToAccountingNumberManager = {
    Entity: ChainAccountingNumberToAccountingNumber,
    Sorter: 'chain_accounting_number',

    __proto__: EntityManager
};

export default ChainAccountingNumberToAccountingNumberManager;

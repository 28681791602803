import { LanguageManager } from '../managers/Language.js';
import { DialogManager } from '../managers/Dialog.js';
import { searchNominatim } from './Nomatim.js';


const mapNominatimResults = function(results) {
    if (!results) {
        return [{ icon: 'wifi_off', title: LanguageManager.translate('feature.nomatim_offline.title'), subtitle: LanguageManager.translate('feature.nomatim_offline.subtitle') }];
    }

    if (results.length === 0) {
        return [{ icon: 'location_off', title: LanguageManager.translate('feature.nomatim_no_results.title'), subtitle: LanguageManager.translate('feature.nomatim_no_results.subtitle') }];
    }

    return results.map(location => {
        return {
            icon: (location.class === 'shop' && 'store' ) || (location.class === 'amenity' && 'location_city') || (location.type === 'house' && 'house') || 'place',
            title: [location.address[location.class], location.address.road, location.address.house_number].filter(part => !!part).join(' '),
            subtitle: [location.address.postcode, location.address.city ?? location.address.suburb].filter(part => !!part).join(' '),
            lat: location.lat,
            longitude: location.lon,
        };
    });
};

const selectionDialog = function(options) {
    return DialogManager.show({
        template: 'fragment-selection-list',
        title: LanguageManager.translate('feature.nomatim_dialog.title'),
        scrimAction: '',
        data: {
            selectedOption: null,
            options,

            onSelect(event, scope) {
                this.selectedOption = scope.option;

                scope.__parentScope__.dialog.close();
            }
        }
    });
};

export const AddressLocationFeature = function(config = {}, host)  {
    return {
        attachLatLong() {
            const entity = config.entity ?? host.entityStore?.value;

            if (!entity) {
                throw new ReferenceError('no target entity available');
            }

            return searchNominatim({
                street: `${entity.house_number} ${entity.street}`,
                postalcode: entity.zip,
                city: entity.city,
                country: entity.country,
                acceptLanguage: 'de',
                addressdetails: 1,
            }).catch(error => {
                if (!navigator.onLine) {
                    return null;
                }

                throw error;
            }).then(results => {
                const options = mapNominatimResults(results);

                return selectionDialog(options);
            }).then(event => {
                const { selectedOption: location } = event.data;

                if (location.lat && location.lat !== entity.lat) {
                    entity.lat = location.lat;
                }

                if (location.longitude && location.longitude !== entity.longitude) {
                    entity.longitude = location.longitude;
                }
            });
        }
    };
};

import {createView} from '../prototypes/Page.js';
import { GenericListingPage, genericListingPageViewFactory } from '../prototypes/GenericListingPage.js';
import { AccountingNumberManager } from '../managers/AccountingNumber.js';

export const AccountingNumberPage = {
    name: 'AccountingNumberPage',
    path: '/accountingnumber',
    template: 'page-accounting-number',
    entityManager: AccountingNumberManager,
    icon: 'receipt_long',
    title: 'page.accounting_number_page.title',

    columns: [
        'name',
        'BEMA',
        'BEMAextra',
        'GOZ',
        'GOZextra',
        'needs_followup',
    ],

    init(template) {
        super.init(template, 'accounting_number_detail_page');
    },

    __proto__: GenericListingPage,
};

createView(genericListingPageViewFactory, AccountingNumberPage);

export default AccountingNumberPage;

import { createView } from '../prototypes/Component.js';
import { FieldComponent, fieldViewFactory } from './Field.js';

export const SwitchFieldComponent = {
    name: 'SwitchFieldComponent',
    template: 'component-switch-field',

    init(config) {
        super.init(config);

        if (config.data === null) {
            config.data = false;
        }
    },

    __proto__: FieldComponent,
};

createView(fieldViewFactory, SwitchFieldComponent);

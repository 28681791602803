import {createView} from '../../prototypes/Page.js';
import { GenericRelationListingPage, genericRelationListingPageViewFactory } from '../../prototypes/GenericRelationListingPage.js';
import { Treatment } from 'zpp-mpr-lib/models.js';
import TreatmentManager from '../../managers/Treatment.js';
import { PatientTrait } from 'zpp-mpr-lib/traits/models.js';
import { Events as FloatingActionEvents } from '../../managers/FloatingAction.js';
import {Router} from '@af-modules/router';
import {DialogManager} from '../../managers/Dialog.js';
import {LanguageManager} from '../../managers/Language.js';

export const PatientTreatmentPage = {
    name: 'PatientTreatmentPage',
    path: '/carefacility/{careFacilityId}/patients/{entityId}/treatment',
    template: 'page-patient-treatment',
    entity: Treatment,
    entityManager: TreatmentManager,
    parentEntityTrait: PatientTrait,
    useAppBar: false,
    entityId: null,

    columns: [
        'text',
        'accounting_number',
        'date_time',
    ],

    get floatingActionConfig() {
        return {
            label: this.strings.addNewEntity,
            icon: 'add',
            actions: [{
                icon: 'add',
                label: this.strings.addNewCustom,
                name: 'custom',
            }, {
                icon: 'directions',
                label: this.strings.addNewTravelcost,
                name: 'travelcost'
            }, {
                icon: 'insert_link',
                label: this.strings.addNewChained,
                name: 'chained',
            }, {
                icon: 'mic',
                label: this.strings.addVoiceMemo,
                name: 'voice_memo',
            }]
        };
    },

    [FloatingActionEvents.SubAction](requestEvent) {
        const currentPath = Router.getCurrentPath().slice(1).join('/');

        return DialogManager.show({
            template: 'fragment-dialog-text',
            title: LanguageManager.translate('page.patient_detail_page.directions_dialog'),
            scrimAction: '',
            data: {
                onCreateDirection(event, scope) {
                    scope.dialog.close();

                    this.switch = true;
                },

                onContinue(event, scope) {
                    scope.dialog.close();

                    this.switch = false;
                }
            },
            actions: [{label: LanguageManager.translate(this.strings.continue), name: 'continue'}, {label: LanguageManager.translate(this.strings.createDirection), name: 'createDirection'}]
        }).then((event) => {
            if (event.action === 'createDirection') {
                Router.switchTo(`/${currentPath}/new/edit-travelcost`);
            } else {
                Router.switchTo(`/${currentPath}/new/edit-${requestEvent.actionName}`);
            }
        });
    },

    init(template) {
        super.init(template, 'patient_detail_page');
    },

    __proto__: GenericRelationListingPage,
};

createView(genericRelationListingPageViewFactory, PatientTreatmentPage);

export default PatientTreatmentPage;

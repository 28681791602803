import { Iterator } from '@prograp/iterator';
import { Trait } from 'zpp-mpr-lib/traits.js';
import { EntityTraitToNameMap } from '../lib/NameMaps.js';

import { StorageManager } from './Storage.js';
import { App } from '../main.js';

const toPascalCase = (string) => string.split('_')
    .map(item => item.charAt(0).toUpperCase() + item.substr(1)).join('');

export const EntityManager = {
    Entity: null,
    Sorter: null,

    init() {
        if (typeof this.Entity === 'undefined' || typeof this.Sorter === 'undefined') {
            throw 'Dont use the EntityManager directly';
        }
    },

    getEntities(start=0, limit=null, sortKey=this.Sorter, sortDirection='ASC') {
        const end = limit ? (start + limit) : undefined;

        return StorageManager.query(this.Entity)
            .where(sortKey)
            .from('')
            .sort(sortDirection)
            .get(start, end);
    },

    getEntity(id) {
        return StorageManager.queryEntityById(this.Entity, id);
    },

    getTotalEntityCount() {
        return StorageManager.queryEntityCount(this.Entity)
            .where('id')
            .from('')
            .get();
    },

    resolveRelation(entityTrait, relatedEntityId) {
        const ownTrait = Trait.for(this.Entity);
        const entityTraitName = EntityTraitToNameMap.get(entityTrait);

        const [foreignKeyName] = Iterator.fromObject(ownTrait).find(([, value]) => value === entityTrait) ?? [];
        const relation = `${entityTraitName}/${relatedEntityId}`;

        if (!foreignKeyName) {
            throw new Error(`unable to locate foreign key in ${EntityTraitToNameMap.get(ownTrait)} for ${entityTraitName}`);
        }

        return { relation, foreignKeyName };
    },

    getEntitiesForRelatedEntity(entityTrait, relatedEntityId, start=0, limit=null, sortKey=this.Sorter, sortDirection='ASC') {
        const end = limit ? (start + limit) : undefined;
        const { relation, foreignKeyName } = this.resolveRelation(entityTrait, relatedEntityId);

        let indexName = foreignKeyName;
        let fromTo = relation;

        if (sortKey !== null && sortKey !== foreignKeyName) {
            indexName += `+${sortKey}`;
            fromTo = [relation];
        }

        return StorageManager.query(this.Entity)
            .where(indexName)
            .from(fromTo)
            .to(fromTo)
            .sort(sortDirection)
            .get(start, end);
    },

    getEntityCountForRelatedEntity(entityTrait, relatedEntityId) {
        const { relation, foreignKeyName } = this.resolveRelation(entityTrait, relatedEntityId);

        return StorageManager.queryEntityCount(this.Entity)
            .where(foreignKeyName)
            .equals(relation)
            .get();
    },

    storeChanges(id, entity) {
        return StorageManager.storeChanges(id, entity, Trait.for(this.Entity));
    },
    
    storeDelete(id) {
        const trait = Trait.for(this.Entity);

        return this.getAllRelated(id).then((related) => {
            const found = related.filter((item) => item.length !== 0);

            if (found.length !== 0) {
                throw {relations: found};
            }

            return StorageManager.storeDelete(id, trait);
        });
    },


    getAllRelated(entityId) {
        const trait = Trait.for(this.Entity);

        return Promise.all(Trait.getRelatedTraitsWithPropertyName(trait).map((related) => {
            return this.forTrait(related.trait).getEntitiesForRelatedEntity(trait, entityId, 0, null, null);
        }));
    },

    forTrait(trait) {
        const traitName = EntityTraitToNameMap.get(trait);
        const managerName = `${toPascalCase(traitName)}Manager`;

        const manager = App.managers[managerName];

        if (!manager) {
            throw new Error(`no manager ${managerName} registered`);
        }

        return App.managers[managerName];
    }
};

export default EntityManager;
